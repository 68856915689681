<template>
    <div class="content">
        <div class="bgColor"></div>
        <HeaderTop :parentData="dataNum" :background="background" :color="color" :activeColor="activeColor"
            :position="position"></HeaderTop>
            <div class="shopBigBox">
            <div class="shopMsg">
                <div class="shopLeft">
                    <img :src="dataMsg.factoryImg" alt="">
                </div>
                <div class="shopCont">
                    <div class="shopName" style="display: flex;align-items: center;justify-content: space-between;">
                        <div class="shopNameA" style="flex: 1;">
                            {{dataMsg.title}}
                        </div>
                        <div class="aixin" v-if="dataMsg.isCollect==0" @click="isCollectSet">
                <img src="../assets/aixinNo.png" alt="">
            </div>
            <div class="aixin aixinA" v-if="dataMsg.isCollect==1" @click="isCollectSet">
                <img src="../assets/aixin.png" alt="">
            </div>
                        
                        
                    </div>
                    <div class="shopPrice">
                        {{dataMsg.aboutUs}}
                    </div>
                    <div class="shopPrice">
                        主营：{{dataMsg.majorBusiness||''}}
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="box">
            <div style="display: flex;align-items: center;justify-content: space-between;">
                <div>
                <div class="boxTop">
                    <div class="boxTag">
                        {{ dataMsg.briefName }}
                    </div>
                    <div class="boxName">
                        {{ dataMsg.title }}
                    </div>
                </div>
                <div class="boxMsg">
                    <div class="boxLi">
                        联系人：{{ dataMsg.legalName }}
                    </div>
                    <div class="boxLi" style="margin-left: 0.74rem;">
                        联系电话：{{ dataMsg.mobile }}
                    </div>
                </div>

            </div>
            <div class="aixin" v-if="dataMsg.isCollect==0" @click="isCollectSet">
                <img src="../assets/aixinNo.png" alt="">
            </div>
            <div class="aixin aixinA" v-if="dataMsg.isCollect==1" @click="isCollectSet">
                <img src="../assets/aixin.png" alt="">
            </div>
            </div>
            <div class="boxCont">
                {{ dataMsg.aboutUs }}
            </div>
            <div class="boxZy">
                主营：{{ dataMsg.majorBusiness }}
            </div>
        </div> -->
        <div class="shopBig">

            <div class="shopTit">
                <div class="shopDiv"></div>
                <div class="shopName">需求订单列表</div>
            </div>
            <div class="orderBox">
                <el-empty description="暂无订单" v-if="orderList.length<=0"></el-empty>
                <div class="orderLi" v-for="(item, index) in orderList" :key="index"
                    @click="toUrlOrder(item)">
                    <div class="orderNo">
                        订单号：{{item.orderCode}}
                    </div>
                    <div class="orderDisplay">
                        <div class="orderName">
                            {{item.productName}}
                        </div>
                        <div class="orderStatus" v-if="item.status==0||item.status==1">
                            可申请
                        </div>
                    </div>
                    <div class="orderDisplay">
                        <div class="orderNum">
                            采购数量：<span>{{item.productName}}吨</span>
                        </div>
                        <div class="orderPrice">
                            ¥<span>{{item.price}}/斤</span>
                        </div>
                    </div>
                    <div class="orderDisplay">
                        <div class="orderNum">
                            交货地：<span>{{item.deliveryPlace}}</span>
                        </div>
                    </div>
                    <div class="orderDisplay">
                        <div class="orderNum">
                            发布时间：<span>{{item.releaseTime}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="display: flex;align-items: center;justify-content: flex-end;width: 16.27rem;margin: 0 auto">
            <el-pagination :current-page="listQueryOrder.page" :page-sizes="[10]" :page-size="listQueryOrder.limit" :total="listQueryOrder.total" layout="total, sizes, prev, pager, next, jumper" @size-change="pageSizeChangeHandleOrder" @current-change="pageCurrentChangeHandleOrder"> </el-pagination>
        </div>
        <div class="shopBig">
            
            <div class="shopTit">
                <div class="shopDiv"></div>
                <div class="shopName">供应订单列表</div>
            </div>
            <el-empty description="暂无订单"></el-empty>
        </div>
        <div class="shopBig">
            <div class="shopTit">
                <div class="shopDiv"></div>
                <div class="shopName">产品列表</div>
            </div>
            <el-empty description="暂无产品" v-if="shopList.length<=0"></el-empty>

            <div class="shopList">
                <div class="shopLi" v-for="(item, index) in shopList" :key="index"
                    @click="toUrl('/shopXq', item.id)">
                    <div class="shopImg">
                        <img :src="item.pic" alt="">
                    </div>
                    <div class="shopTitName">
                        {{ item.name }}
                    </div>
                    <div class="shopNum">
                        数量： {{ item.num }}
                    </div>
                    <div class="shopPrice">
                        ¥<span>{{ item.price }}</span>/元
                    </div>
                    <div style="display: flex;">
                        <div class="shopAdd">{{ item.source }}</div>

                    </div>
                </div>
            </div>
        </div>
        <div style="display: flex;align-items: center;justify-content: flex-end;width: 16.27rem;margin: 0 auto">
            <el-pagination :current-page="listQuery.page" :page-sizes="[10]" :page-size="listQuery.limit" :total="listQuery.total" layout="total, sizes, prev, pager, next, jumper" @size-change="pageSizeChangeHandle" @current-change="pageCurrentChangeHandle"> </el-pagination>
        </div>
    </div>
</template>

<script>
import { getCompanyById,collect,getCompanyProduct,getCompanyOrder } from '@/api/user/index.js'

export default {
    data() {
        return {
            dataNum: localStorage.getItem('indexNum')?localStorage.getItem('indexNum'):0,
            background: "#ffffff",
            color: "#000000",
            activeColor: "#00943F",
            position: "fixed",
            id: "",
            dataMsg: {},
            listQuery: {
                page: 1,
                limit: 10,
                total:""
            },
            listQueryOrder: {
                page: 1,
                limit: 10,
                total:""
            },
            shopList:[],
            orderList:[]
        }
    },
    created() {
        this.id = this.$route.query.id
        this.init()
        this.listQuery.page = 1
        this.shopList = []
        this.getInit()
        
        this.listQueryOrder.page = 1
        this.orderList = []
        this.getInitOrder()
    },
    methods: {
        toUrlOrder(obj){
            localStorage.setItem('orderMsgXq',JSON.stringify(obj))
            this.$router.push('/xqXq')
        },
        getInitOrder(){
            getCompanyOrder({companyId:this.id,page:this.listQueryOrder.page,limit:this.listQueryOrder.limit}).then(res=>{
        if (res.code == 0) {
            this.listQueryOrder.total = Number(res.data.total)
            this.orderList = res.data.records
        }
        })
        },
        getInit(){
            getCompanyProduct({companyId:this.id,page:this.listQuery.page,limit:this.listQuery.limit}).then(res=>{
        if (res.code == 0) {
            this.listQuery.total = Number(res.data.total)
            this.shopList = res.data.records
        }
        })
    },
        pageSizeChangeHandle(val){
            this.listQuery.page=1
            this.listQuery.limit = Number(val);
            this.getInit()
        },
        pageCurrentChangeHandle(val){
            this.listQuery.page=Number(val)
            this.getInit()
        },
        pageSizeChangeHandleOrder(val){
            this.listQueryOrder.page=1
            this.listQueryOrder.limit = Number(val);
            this.getInitOrder()
        },
        pageCurrentChangeHandleOrder(val){
            this.listQueryOrder.page=Number(val)
            this.getInitOrder()
        },
        isCollectSet(){
            if(this.dataMsg.isCollect==0){
					this.dataMsg.isCollect=1
				}else{
					this.dataMsg.isCollect=0
				}
            collect({type:2,id:this.dataMsg.id}).then(res=>{
                console.log(res)
                if (res.code == 0) {
                    this.$message({
                    message: res.msg,
                    type: 'success',
                    customClass:'messageBoxTip'

                })
					} else {
                        this.$message({
                    message: res.msg,
                    type: 'warning',
                    customClass:'messageBoxTip'

                })
					}
            })

        },
        init() {
            getCompanyById({ companyId: this.id }).then(res => {
                console.log(res)
                if (res.code == 0) {
                    // 将你要渲染的数据进行字符串正则匹配
                    this.dataMsg = res.data

                }
            })
        },
        toUrl(urlSrc, id) {
            this.$router.push({ path: urlSrc, query: { id: id } })

        }
    }

}
</script>

<style lang="scss" scoped>

.shopBigBox {
    width: 16.27rem;
    padding: 0.42rem;
    box-sizing: border-box;
    border-radius: 0.31rem;
    opacity: 1;
    border: 0.01rem solid #eaeaea;
    background: #ffffff;
    margin: 1.2rem auto 0;
}

.shopMsg {
    display: flex;

    .shopLeft {
        width: 6.75rem;
        height: 6.75rem;

        img {
            width: 100%;
            height: 100%;
            // border-radius: 0.31rem;
            object-fit: cover;
            border-radius: 0.31rem;
        }
    }

    .shopCont {
        flex: 1;
        margin-left: 0.54rem;

        .shopName {
            color: #343231;
            font-size: 0.36rem;
            font-weight: 700;
            font-family: "SourceHanSansCN";
            .aixin{
    width: 0.41rem;
height: 0.42rem;
opacity: 1;
display: flex;
align-items: center;
background: #00000066;
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
    img{
        width: 0.26rem;
        height: 0.25rem;
    }
}
.aixinA{
background: #ffbe0033;
}
        }

        .shopPrice {
            color: #868588;
            font-size: 0.26rem;
            font-weight: 400;
            font-family: "SourceHanSansCN";
            margin-top: 0.36rem;

            span {
                color: #ff0000;
                text-align: left;
                font-weight: 700;

                font-family: "SourceHanSansCN";
            }

            .shopPriceNum {
                font-size: 0.36rem;


            }
        }
    }
}
.bgColor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fafafa;
    z-index: -1;
}
.aixin{
    width: 0.41rem;
height: 0.42rem;
opacity: 1;
display: flex;
align-items: center;
background: #00000066;
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
    img{
        width: 0.26rem;
        height: 0.25rem;
    }
}
.aixinA{
background: #ffbe0033;
}
.box {
    width: 16.27rem;
    // height: 7.42rem;
    border-radius: 0.31rem;
    opacity: 1;
    border: 0.01rem solid #eaeaea;
    background: #ffffff;
    margin: 1.2rem auto 0;
    padding: 0.8rem 0.9rem;
    box-sizing: border-box;

    .boxTop {
        display: flex;
        align-items: center;

        .boxTag {
            width: 0.39rem;
            height: 0.42rem;
            border-radius: 0.32rem;
            opacity: 1;
            border: 0.01rem solid #ffbe00;
            background: #ffbe00;
            color: #ffffff;
            font-size: 0.14rem;
            font-weight: 400;
            font-family: "SourceHanSansCN";
            line-height: 0.16rem;
            display: flex;
            align-items: center;
            text-align: center;
        }

        .boxName {
            color: #333333;
            font-size: 0.3rem;
            font-weight: 500;
            font-family: "SourceHanSansCN";
            margin-left: 0.14rem;
        }
    }

    .boxMsg {
        display: flex;
        align-items: center;
        margin-top: 0.19rem;

        .boxLi {
            color: #555555;
            font-size: 0.26rem;
            font-weight: 400;
            font-family: "SourceHanSansCN";
        }
    }

    .boxCont {
        color: #33333380;
        font-size: 0.28rem;
        font-weight: 400;
        font-family: "SourceHanSansCN";
        margin-top: 0.42rem;
        line-height: 0.6rem;
    }

    .boxZy {
        color: #333333cc;
        font-size: 0.28rem;
        font-weight: 400;
        font-family: "SourceHanSansCN";
        margin-top: 0.35rem;
    }
}

.shopBig {
    width: 16.27rem;
    margin: 0.4rem auto 0;
}

.shopTit {
    display: flex;
    align-items: center;
    margin-bottom: 0.42rem;

    .shopDiv {
        width: 0.08rem;
        height: 0.35rem;
        border-radius: 0 1rem 1rem 0;
        opacity: 1;
        border: 0.01rem solid #979797;
        background: #00903d;
    }

    .shopName {
        color: #00903d;
        font-size: 0.24rem;
        font-weight: 700;
        font-family: "SourceHanSansCN";
        margin-left: 0.16rem;
    }
}

.shopList {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    flex-wrap: wrap;
    margin-right: -0.3rem;

    .shopLi {
        margin-bottom: 0.69rem;
        width: 3.84rem;
        height: 5.28rem;
        border-radius: 0.32rem;
        opacity: 1;
        border: 0.04rem solid #ffffff80;
        background: #ffffff;
        box-shadow: 0 0 0.3rem 0 #0000001a;
        padding: 0.3rem 0.2rem;
        box-sizing: border-box;
        margin-right: 0.3rem;

        .shopImg {
            width: 3.21rem;
            height: 2.15rem;
            border-radius: 0.16rem;
            margin: 0 auto;

            img {
                width: 100%;
                height: 100%;
                border-radius: 0.16rem;
                object-fit: cover;
            }
        }

        .shopTitName {
            color: #333333;
            font-size: 0.28rem;
            font-weight: 400;
            font-family: "SourceHanSansCN";
            margin-top: 0.19rem;
        }

        .shopNum {
            color: #7f7f7f;
            font-size: 0.18rem;
            font-weight: 400;
            font-family: "SourceHanSansCN";
            margin-top: 0.17rem;
        }

        .shopPrice {
            color: #ff0000;
            font-weight: 500;
            font-size: 0.24rem;
            font-family: "SourceHanSansCN";
            margin-top: 0.16rem;

            span {
                font-weight: 700;
                font-size: 0.36rem;
            }
        }

        .shopAdd {
            min-width: 0.96rem;
            height: 0.28rem;
            border-radius: 0.08rem;
            opacity: 1;
            background: #339cff33;
            margin-top: 0.1rem;
            color: #339cff;
            font-size: 0.18rem;
            font-weight: 400;
            font-family: "SourceHanSansCN";
            text-align: center;
        }
    }

}
.orderBox{
    .orderLi{
        border: 0.01rem solid #eaeaea;
        background: #ffffff;
        border-radius: 0.31rem;
        padding: 0.3rem;
        box-sizing: border-box;
        margin-bottom: 0.2rem;
        .orderNo{
            color: #333333;
            font-size: 0.28rem;
            font-weight: 700;
            font-family: "SourceHanSansCN";
        }
        .orderDisplay{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .orderName{
                color: #333333;
            font-size: 0.28rem;
            font-weight: 400;
            font-family: "SourceHanSansCN";
            margin-top: 0.17rem;
            }
            .orderStatus{
                color: #00903d;
            font-size: 0.28rem;
            font-weight: 700;
            font-family: "SourceHanSansCN";
            }
            .orderNum{
                color: #7f7f7f;
            font-size: 0.18rem;
            font-weight: 400;
            font-family: "SourceHanSansCN";
            margin-top: 0.17rem;
                span{
                    
                }
            }
            .orderPrice{
                color: #ff0000;
            font-weight: 500;
            font-size: 0.24rem;
            font-family: "SourceHanSansCN";
            margin-top: 0.16rem;

            span {
                font-weight: 700;
                font-size: 0.36rem;
            }
            }
        }
    }
}
/* 和列表一样的宽度和margin值 */
// .shopList > i {
//     width: 3.84rem;
//     margin-right: 0.3rem;
// }
// .shopList::after{
//     content:'';
//     width: 3.84rem;
// }</style>