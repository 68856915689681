// 设置 rem 函数
function setRem() {  
    // 获取视口宽度  
    const width = document.documentElement.clientWidth || document.body.clientWidth;  
    // 假设设计稿宽度为1920px，基准rem为100px  
    const rem = width / (1920 / 100);  
    // 设置根元素字体大小  
    document.documentElement.style.fontSize = `${rem}px`;  
  }  
// 初始化
setRem()
// 改变窗口大小时重新设置 rem
window.onresize = function () {
  setRem()
}