<template>
    <div class="content">
        <div class="bgColor"></div>
        <HeaderTop :parentData="dataNum" :background="background" :color="color" :activeColor="activeColor"
            :position="position"></HeaderTop>
        <div class="box">
            <div class="boxNameTit">
                {{ name }}
            </div>
            <div class="boxCont" v-if="type == 1">
                <div class="boxTit">
                    质量标准
                </div>
                <div class="boxContBox">
                    <div class="boxLi">
                        <div class="boxName">水分</div>
                        <!-- <div class="boxName"><span>*</span>水分</div> -->
                        <el-input type="number" v-model="supplierSelfInspection.waterRequire" placeholder="请输入水分"
                            suffix-icon="el-icon-arrow-right" style="width: 7.63rem;"></el-input>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">杂质</div>
                        <el-input type="number" v-model="supplierSelfInspection.impurityRequire" placeholder="请输入杂质"
                            suffix-icon="el-icon-arrow-right" style="width: 7.63rem;"></el-input>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">生霉粒</div>
                        <el-input type="number" v-model="supplierSelfInspection.moldyRequire" placeholder="请输入生霉粒"
                            suffix-icon="el-icon-arrow-right" style="width: 7.63rem;"></el-input>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">蛋白质</div>
                        <el-input type="number" v-model="supplierSelfInspection.proteinRequire" placeholder="请输入蛋白质"
                            suffix-icon="el-icon-arrow-right" style="width: 7.63rem;"></el-input>
                    </div>
                    
                    <div class="boxLi">
                        <div class="boxName">容重</div>
                        <el-input type="number" v-model="supplierSelfInspection.weightRequire" placeholder="请输入容重"
                            suffix-icon="el-icon-arrow-right" style="width: 7.63rem;"></el-input>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">赤霉病率</div>
                        <el-input type="number" v-model="supplierSelfInspection.wornRequire" placeholder="请输入赤霉病率"
                            suffix-icon="el-icon-arrow-right" style="width: 7.63rem;"></el-input>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">霉菌</div>
                        <el-input type="number" v-model="supplierSelfInspection.mildewRequire" placeholder="请输入霉菌"
                            suffix-icon="el-icon-arrow-right" style="width: 7.63rem;"></el-input>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">呕吐毒素</div>
                        <el-input type="number" v-model="supplierSelfInspection.vomitoxinRequire" placeholder="请输入呕吐毒素"
                            suffix-icon="el-icon-arrow-right" style="width: 7.63rem;"></el-input>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">不完善率</div>
                        <el-input type="number" v-model="supplierSelfInspection.unsoundKernel" placeholder="请输入不完善率"
                            suffix-icon="el-icon-arrow-right" style="width: 7.63rem;"></el-input>
                    </div>
                </div>
            </div>
            <div class="boxCont" v-if="type == 2">
                <div class="boxTit">
                    装车
                </div>
                <div class="boxContBox">
                    <div class="boxImage" style="flex-wrap: nowrap;">
                        <div class="boxName"><span>*</span>装车照片</div>
                        <div class="imagePerBoox">
                            <div class="imagePer" v-for="(item, index) in driverMsg.loadImg" :key="index">
                                <img :src="item" alt="">
                                <div class="boxClose" @click.stop="deleteImg(1, index)">
                                    <img src="@/assets/delete.png" alt="">
                                </div>
                            </div>
                            <div class="imagePer" @click="triggerFileInput('fileInput1')">
                                <input type="file" @change="handleFileChange" ref="fileInput1" accept="image/*"
                                    style="display: none;">
                                <div class="el-icon-plus"></div>
                            </div>
                        </div>

                    </div>
                    <div class="boxImage" style="flex-wrap: nowrap;">
                        <div class="boxName"><span>*</span>装车视频</div>
                        <div class="imagePerBoox">
                            <div class="imagePer" v-for="(item, index) in driverMsg.loadVideo" :key="index">
                                <video :src="item" controls></video>
                                <div class="boxClose" @click.stop="deleteImg(2, index)">
                                    <img src="@/assets/delete.png" alt="">
                                </div>
                            </div>
                            <div class="imagePer" @click="triggerFileInput('fileInput2')">
                                <input type="file" @change="handleFileChange" ref="fileInput2" accept="video/*"
                                    style="display: none;">
                                <div class="el-icon-plus"></div>
                            </div>
                        </div>

                    </div>
                    <div class="boxImage" style="flex-wrap: nowrap;">
                        <div class="boxName"><span>*</span>抽检照片</div>
                        <div class="imagePerBoox">
                            <div class="imagePer" v-for="(item, index) in driverMsg.samplingImg" :key="index">
                                <img :src="item" alt="">
                                <div class="boxClose" @click.stop="deleteImg(3, index)">
                                    <img src="@/assets/delete.png" alt="">
                                </div>
                            </div>
                            <div class="imagePer" @click="triggerFileInput('fileInput3')">
                                <input type="file" @change="handleFileChange" ref="fileInput3" accept="image/*"
                                    style="display: none;">
                                <div class="el-icon-plus"></div>
                            </div>
                        </div>

                    </div>
                    <div class="boxImage" style="flex-wrap: nowrap;">
                        <div class="boxName"><span>*</span>抽检视频</div>
                        <div class="imagePerBoox">
                            <div class="imagePer" v-for="(item, index) in driverMsg.sampling" :key="index">
                                <video :src="item" controls></video>
                                <div class="boxClose" @click.stop="deleteImg(4, index)">
                                    <img src="@/assets/delete.png" alt="">
                                </div>
                            </div>
                            <div class="imagePer" @click="triggerFileInput('fileInput4')">
                                <input type="file" @change="handleFileChange" ref="fileInput4" accept="video/*"
                                    style="display: none;">
                                <div class="el-icon-plus"></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="boxCont" v-if="type == 3 || type == 4">
                <div class="boxTit">
                    {{ (type == 3 && '过磅') || (type == 4 && '卸车过磅') }}
                </div>
                <div class="boxContBox">
                    <div class="boxLi">
                        <div class="boxName"><span>*</span>毛重(kg)</div>
                        <el-input type="number" @input="inptA" v-model="driverMsg.loadGrossWeight" placeholder="请输入毛重"
                            suffix-icon="el-icon-arrow-right" style="width: 7.63rem;"></el-input>
                    </div>
                    <div class="boxLi">
                        <div class="boxName"><span>*</span>皮重(kg)</div>
                        <el-input type="number" @input="inptB" v-model="driverMsg.loadTareWeight" placeholder="请输入皮重"
                            suffix-icon="el-icon-arrow-right" style="width: 7.63rem;"></el-input>
                    </div>
                    <div class="boxLi">
                        <div class="boxName"><span>*</span>净重(kg)</div>
                        <el-input type="number" @input="inptC" v-model="driverMsg.loadNetWeight" placeholder="请输入净重"
                            suffix-icon="el-icon-arrow-right" style="width: 7.63rem;"></el-input>
                    </div>
                    <div class="boxLi" v-if="userInfo.userType != 4">
                        <div class="boxName"><span>*</span>粮食单价(元/kg)</div>
                        <el-input type="number" @input="inptD" v-model="driverMsg.price" placeholder="请输入粮食单价"
                            suffix-icon="el-icon-arrow-right" style="width: 7.63rem;"></el-input>
                    </div>
                    <div class="boxLi" v-if="userInfo.userType != 4">
                        <div class="boxName"><span>*</span>粮食总价(元)</div>
                        <el-input type="number" @input="inptE" v-model="driverMsg.totalPrice" placeholder="请输入粮食总价"
                            suffix-icon="el-icon-arrow-right" style="width: 7.63rem;"></el-input>
                    </div>
                    <div class="boxImage">
                        <div class="boxName"><span>*</span>空车过磅单</div>
                        <div class="imagePer" @click="triggerFileInput('fileInput5')">
                            <input type="file" @change="handleFileChange" ref="fileInput5" accept="image/*"
                                style="display: none;">
                            <img :src="driverMsg.loadEmpty" alt="" v-if="driverMsg.loadEmpty">
                            <div class="el-icon-plus" v-if="!driverMsg.loadEmpty"></div>
                        </div>
                    </div>
                    <div class="boxImage">
                        <div class="boxName"><span>*</span>{{ (type == 3 && '装车过磅单') || (type == 4 && '卸车前过磅单') }}</div>
                        <div class="imagePer" @click="triggerFileInput('fileInput6')">
                            <input type="file" @change="handleFileChange" ref="fileInput6" accept="image/*"
                                style="display: none;">
                            <img :src="driverMsg.loadWeightForm" alt="" v-if="driverMsg.loadWeightForm">
                            <div class="el-icon-plus" v-if="!driverMsg.loadWeightForm"></div>
                        </div>
                    </div>
                    <div class="boxImage">
                        <div class="boxName"><span>*</span>车头</div>
                        <div class="imagePer" @click="triggerFileInput('fileInput7')">
                            <input type="file" @change="handleFileChange" ref="fileInput7" accept="image/*"
                                style="display: none;">
                            <img :src="driverMsg.loadFront" alt="" v-if="driverMsg.loadFront">
                            <div class="el-icon-plus" v-if="!driverMsg.loadFront"></div>
                        </div>
                    </div>
                    <div class="boxImage">
                        <div class="boxName"><span>*</span>车尾</div>
                        <div class="imagePer" @click="triggerFileInput('fileInput8')">
                            <input type="file" @change="handleFileChange" ref="fileInput8" accept="image/*"
                                style="display: none;">
                            <img :src="driverMsg.loadBack" alt="" v-if="driverMsg.loadBack">
                            <div class="el-icon-plus" v-if="!driverMsg.loadBack"></div>
                        </div>
                    </div>
                    <div class="boxImage" v-if="type == 3">
                        <div class="boxName"><span>*</span>出库单据</div>
                        <div class="imagePer" @click="triggerFileInput('fileInput9')">
                            <input type="file" @change="handleFileChange" ref="fileInput9" accept="image/*"
                                style="display: none;">
                            <img :src="driverMsg.outScrip" alt="" v-if="driverMsg.outScrip">
                            <div class="el-icon-plus" v-if="!driverMsg.outScrip"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btnBoxBig">
                <button class="btnBox" @click="submitForm">确定提交</button>

            </div>
        </div>
    </div>
</template>

<script>
import { upload, orderDetails,loading,loadingCheck,unloading } from '@/api/user/index.js'
export default {
    data() {
        return {
            userInfo: JSON.parse(localStorage.getItem('userInfo')),
            chooseNum: "",
            name: "",
            dataNum: 0,
            background: "#ffffff",
            color: "#000000",
            activeColor: "#00943F",
            position: "fixed",
            type: "",
            id: "",
            //粮仓(供应商)自检
            supplierSelfInspection: {
                id: "",
                waterRequire:"",//水分要求
					impurityRequire:"",//杂质要求
					moldyRequire:"",//霉变粒要求
					unsoundKernel:"",//不完善粒
					proteinRequire:"",//蛋白质
					weightRequire:"",//容重要求
					wornRequire:"",//赤毒病率
					mildewRequire:"",//霉菌
					vomitoxinRequire:"",//呕吐毒素
            },
            //装车过磅粮运(司机)
            driverMsg: {
                loadVideo: [],//装车视频
                loadImg: [],//装车照片
                sampling: [],//抽检视频
                samplingImg: [],//抽检照片
                loadGrossWeight: "",//毛重
                loadTareWeight: "",//皮重
                loadNetWeight: "",//净重
                price: "",//粮食单价
                totalPrice: "",//粮食总价
                // loadWeightForm:"",//过磅单
                loadEmpty: "",//空车过磅单
                loadWeightForm: "",//装车过磅单
                loadFront: "",//车头
                loadBack: "",//车尾
                outScrip: ""//出库单据
            },
            // from: {
            //     id:"",
            //     reachPlace: "",//验收地点
            //     checkStatus:'1',//合格不合格
            //     unloadWorker:"",//验收人员
            // 		unloadDoc:"",//验收单据
            //         checkResults:"",//不合格原因
            // 		deduct:""//扣款
            // },
        }
    },
    computed: {
        // 计算属性，用于提供自动计算的结果  
        autoResult: function () {
            return (this.driverMsg.loadGrossWeight - this.driverMsg.loadTareWeight).toFixed(3);
        }
    },
    created() {
        this.id = this.$route.query.id
        this.name = this.$route.query.name
        this.type = this.$route.query.type
        this.init()
    },
    methods: {
        // 删除图片./
			deleteImg(num,index){
				// num1是装车2是抽检
				// nums1是照片2是视频
				// index是数组下标
				if(num==1){
					this.driverMsg.loadImg.splice(index,1)
				}else if(num==2){
					this.driverMsg.loadVideo.splice(index,1)
				}else if(num==3){
					this.driverMsg.samplingImg.splice(index,1)
				}else if(num==4){
					this.driverMsg.sampling.splice(index,1)
				}
			},
        inptA() {
            if (this.driverMsg.loadGrossWeight && this.driverMsg.loadTareWeight) {
                this.driverMsg.loadNetWeight =this.autoResult
                // this.driverMsg.loadNetWeight = Math.round(Number(this.driverMsg.loadGrossWeight) * 100) / 100 - Math.round(Number(this.driverMsg.loadTareWeight) * 100) / 100
            }
            
            this.$nextTick(() => {
                this.driverMsg.loadGrossWeight = this.driverMsg.loadGrossWeight.replace(/[^\d.]/g, '')
            });
         
        },

        inptB() {
            if (this.driverMsg.loadGrossWeight && this.driverMsg.loadTareWeight) {
                this.driverMsg.loadNetWeight = this.autoResult
                // this.driverMsg.loadNetWeight = Math.round(Number(this.driverMsg.loadGrossWeight) * 100) / 100 - Math.round(Number(this.driverMsg.loadTareWeight) * 100) / 100
            }
            console.log(this.driverMsg.loadTareWeight)
            this.$nextTick(() => {
                this.driverMsg.loadTareWeight = this.driverMsg.loadTareWeight.replace(/[^\d.]/g, '')
            });
            console.log(this.driverMsg.loadTareWeight)

        },
        inptC() {
            this.$nextTick(() => {
                this.driverMsg.loadNetWeight = this.driverMsg.loadNetWeight.replace(/[^\d.]/g, '')
            });
        },
        inptD() {
            this.$nextTick(() => {
                this.driverMsg.price = this.driverMsg.price.replace(/[^\d.]/g, '')
            })
        },
        inptE() {
            this.$nextTick(() => {
                this.driverMsg.totalPrice = this.driverMsg.totalPrice.replace(/[^\d.]/g, '')
            });
        },
        // 详情数据
        init() {
            orderDetails({ orderId: this.id }).then(res => {
                if (res.code == 0) {
                    // 粮运(司机)
						if(this.userInfo.userType==4&&(this.type==2||this.type==3)){
							if(res.data.loadVideo){
								this.driverMsg.loadVideo = res.data.loadVideo.split(',')
							}
							if(res.data.loadImg){
								this.driverMsg.loadImg = res.data.loadImg.split(',')
							}
							if(res.data.sampling){
								this.driverMsg.sampling = res.data.sampling.split(',')
							}
							if(res.data.samplingImg){
								this.driverMsg.samplingImg = res.data.samplingImg.split(',')
							}
							this.driverMsg.loadGrossWeight = res.data.loadGrossWeight
							this.driverMsg.loadTareWeight = res.data.loadTareWeight
							this.driverMsg.loadNetWeight = res.data.loadNetWeight
							this.driverMsg.loadEmpty = res.data.loadEmpty
							this.driverMsg.loadWeightForm = res.data.loadWeightForm
							this.driverMsg.loadFront = res.data.loadFront
							this.driverMsg.loadBack = res.data.loadBack
							this.driverMsg.outScrip = res.data.outScrip
						}else if(this.userInfo.userType==3&&(this.type==2||this.type==3)){
							if(res.data.loadVideoCheck){
								this.driverMsg.loadVideo = res.data.loadVideoCheck.split(',')
							}
							if(res.data.loadImgCheck){
								this.driverMsg.loadImg = res.data.loadImgCheck.split(',')
							}
							if(res.data.samplingCheck){
								this.driverMsg.sampling = res.data.samplingCheck.split(',')
							}
							if(res.data.samplingImgCheck){
								this.driverMsg.samplingImg = res.data.samplingImgCheck.split(',')
							}
							// 粮仓(供应商)
							this.driverMsg.loadGrossWeight = res.data.loadGrossWeightCheck
							this.driverMsg.loadTareWeight = res.data.loadTareWeightCheck
							this.driverMsg.loadNetWeight = res.data.loadNetWeightCheck
							this.driverMsg.price = res.data.price
							this.driverMsg.totalPrice = res.data.totalPrice
							// this.driverMsg.loadWeightForm = res.data.loadWeightFormCheck
							this.driverMsg.loadEmpty = res.data.loadEmptyCheck
							this.driverMsg.loadWeightForm = res.data.loadWeightFormCheck
							this.driverMsg.loadFront = res.data.loadFrontCheck
							this.driverMsg.loadBack = res.data.loadBackCheck
							this.driverMsg.outScrip = res.data.outScripCheck
						}else if(this.userInfo.userType==3&&this.type==1){
							this.supplierSelfInspection.waterRequire = res.data.waterRequire
							this.supplierSelfInspection.impurityRequire = res.data.impurityRequire
							this.supplierSelfInspection.moldyRequire = res.data.moldyRequire
							this.supplierSelfInspection.weightRequire = res.data.weightRequire
							this.supplierSelfInspection.unsoundKernel = res.data.unsoundKernel
							this.supplierSelfInspection.proteinRequire = res.data.proteinRequire
							this.supplierSelfInspection.wornRequire = res.data.wornRequire
							this.supplierSelfInspection.mildewRequire = res.data.mildewRequire
							this.supplierSelfInspection.vomitoxinRequire = res.data.vomitoxinRequire
						}
						else if(this.userInfo.userType==4&&this.type==4){
							this.driverMsg.loadGrossWeight = res.data.unloadGrossWeight
							this.driverMsg.loadTareWeight = res.data.unloadTareWeight
							this.driverMsg.loadNetWeight = res.data.unloadNetWeight
							
							this.driverMsg.loadBack = res.data.unloadBack
							this.driverMsg.loadEmpty= res.data.unloadEmpty
							this.driverMsg.loadFront = res.data.unloadFront
							this.driverMsg.loadWeightForm = res.data.unloadlmg
						}

                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning',
                        customClass: 'messageBoxTip'
                    })
                }
            })


        },
        submitForm() {
            // this.userInfo.userType4是粮运(司机)3粮仓(供应商)2粮坊(采购商)1成品0游客
				// 装车过磅传的数据
				let dataSubmit = {
					id:this.id
				}
                if(this.type==2||this.type==3){
                    if(this.type==2){
                        if(this.driverMsg.loadImg.length<=0&&this.driverMsg.loadVideo.length<=0){
                            this.$message({
                                message: '请上传装车照片、视频',
                                type: 'warning'
                            });
							return
						}
						if(this.driverMsg.samplingImg.length<=0&&this.driverMsg.sampling.length<=0){
                            this.$message({
                                message: '请上传抽检照片、视频',
                                type: 'warning'
                            });
							return
						}
                    }
                    if(this.type==3){
                        if(!this.driverMsg.loadGrossWeight){
                            this.$message({
                                message: '请输入毛重',
                                type: 'warning'
                            });
							return
						}
                        if(!this.driverMsg.loadTareWeight){
                            this.$message({
                                message: '请输入皮重',
                                type: 'warning'
                            });
							return
						}
						if(!this.driverMsg.loadNetWeight){
                            this.$message({
                                message: '请输入净重',
                                type: 'warning'
                            });
							return
						}
                        if(this.userInfo.userType!=4){
							if(!this.driverMsg.price){
                                this.$message({
                                    message: '请输入单价',
                                    type: 'warning'
                                });
								return
							}
							if(!this.driverMsg.loadNetWeight){
                                this.$message({
                                    message: '请输入总价',
                                    type: 'warning'
                                });
								return
							}
						}
                        if(!this.driverMsg.loadEmpty){
                            this.$message({
                                    message: '请上传空车过磅单',
                                    type: 'warning'
                                });
							return
						}
						if(!this.driverMsg.loadWeightForm){
                            this.$message({
                                    message: '请上传装车过磅单',
                                    type: 'warning'
                                });
							return
						}
						if(!this.driverMsg.loadFront){
                            this.$message({
                                    message: '请上传车头照片',
                                    type: 'warning'
                                });
							return
						}
                        if(!this.driverMsg.loadBack){
                            this.$message({
                                    message: '请上传车尾照片',
                                    type: 'warning'
                                });
							return
						}
						if(!this.driverMsg.outScrip){
                            this.$message({
                                    message: '请上传出库单据',
                                    type: 'warning'
                                });
							return
						}
                    }
                    if(this.userInfo.userType==4){
						dataSubmit.loadVideo = this.driverMsg.loadVideo.join(',')
						dataSubmit.loadImg = this.driverMsg.loadImg.join(',')
						dataSubmit.sampling = this.driverMsg.sampling.join(',')
						dataSubmit.samplingImg = this.driverMsg.samplingImg.join(',')
						dataSubmit.loadGrossWeight = this.driverMsg.loadGrossWeight
						dataSubmit.loadTareWeight = this.driverMsg.loadTareWeight
						dataSubmit.loadNetWeight = this.driverMsg.loadNetWeight
						// dataSubmit.price = this.driverMsg.price
						// dataSubmit.totalPrice = this.driverMsg.totalPrice
						dataSubmit.loadEmpty = this.driverMsg.loadEmpty
						dataSubmit.loadWeightForm = this.driverMsg.loadWeightForm
						dataSubmit.loadFront = this.driverMsg.loadFront
						dataSubmit.loadBack = this.driverMsg.loadBack
						dataSubmit.outScrip = this.driverMsg.outScrip
					}else if(this.userInfo.userType==3){
						// 粮仓(供应商)
						dataSubmit.loadVideoCheck = this.driverMsg.loadVideo.join(',')
						dataSubmit.loadImgCheck = this.driverMsg.loadImg.join(',')
						dataSubmit.samplingCheck = this.driverMsg.sampling.join(',')
						dataSubmit.samplingImgCheck = this.driverMsg.samplingImg.join(',')
						dataSubmit.loadGrossWeightCheck = this.driverMsg.loadGrossWeight
						dataSubmit.loadTareWeightCheck = this.driverMsg.loadTareWeight
						dataSubmit.loadNetWeightCheck = this.driverMsg.loadNetWeight
						dataSubmit.price = this.driverMsg.price
						dataSubmit.totalPrice = this.driverMsg.totalPrice
						dataSubmit.loadWeightFormCheck = this.driverMsg.loadWeightForm
						dataSubmit.loadEmptyCheck = this.driverMsg.loadEmpty
						dataSubmit.loadFrontCheck = this.driverMsg.loadFront
						dataSubmit.loadBackCheck = this.driverMsg.loadBack
						dataSubmit.outScripCheck = this.driverMsg.outScrip
					}
                    loading(dataSubmit).then(res=>{
                        if (res.code == 0) {
                            this.$message({
                                    message: "提交成功",
                                    type: 'success'
                                });
							setTimeout(()=>{
								this.$router.go(-1)
							},1500)
						} else {
                            this.$message({
                                    message: res.msg,
                                    type: 'warning'
                                });
						}
                       
                    })
                }else if(this.type==1){
                    // if(!this.supplierSelfInspection.waterRequire){
                    //     this.$message({
                    //                 message: "请输入水分",
                    //                 type: 'warning'
                    //             });
					// 	return
					// }
					// if(!this.supplierSelfInspection.impurityRequire){
                    //     this.$message({
                    //                 message: "请输入杂质",
                    //                 type: 'warning'
                    //             });
					// 	return
					// }
					// if(!this.supplierSelfInspection.mildewRequire){
                    //     this.$message({
                    //                 message: "请输入霉变粒",
                    //                 type: 'warning'
                    //             });
					// 	return
					// }
					// if(!this.supplierSelfInspection.weightRequire){
                    //     this.$message({
                    //                 message: "请输入容重",
                    //                 type: 'warning'
                    //             });
					// 	return
					// }
					// if(!this.supplierSelfInspection.unsoundKernel){
                    //     this.$message({
                    //                 message: "请输入不完善粒",
                    //                 type: 'warning'
                    //             });
					// 	return
					// }
                    this.supplierSelfInspection.id = this.id
                    loadingCheck(this.supplierSelfInspection).then(res=>{
                        if (res.code == 0) {
                            this.$message({
                                    message: "提交成功",
                                    type: 'success'
                                });
							setTimeout(()=>{
								this.$router.go(-1)
							},1500)
						} else {
                            this.$message({
                                    message: res.msg,
                                    type: 'warning'
                                });
						}
                       
                    })
                }else if(this.type==4){
                    if(!this.driverMsg.loadGrossWeight){
                        this.$message({
                                    message: "请输入毛重",
                                    type: 'warning'
                                });
						return
					}
					if(!this.driverMsg.loadTareWeight){
                        this.$message({
                                    message: "请输入皮重",
                                    type: 'warning'
                                });
						return
					}
					if(!this.driverMsg.loadNetWeight){
                        this.$message({
                                    message: "请输入净重",
                                    type: 'warning'
                                });
						
						return
					}
					if(!this.driverMsg.loadWeightForm){
						
                        this.$message({
                                    message: "请上传过磅单",
                                    type: 'warning'
                                });
						return
					}
                    let dataSubmit = {
						id:this.id,
						unloadGrossWeight:this.driverMsg.loadGrossWeight,
						unloadTareWeight:this.driverMsg.loadTareWeight,
						unloadNetWeight:this.driverMsg.loadNetWeight,
						unloadlmg:this.driverMsg.loadWeightForm,
						unloadFront:this.driverMsg.loadFront,
						unloadEmpty:this.driverMsg.loadEmpty,
						unloadBack:this.driverMsg.loadBack,
					}
                    unloading(dataSubmit).then(res=>{
                        if (res.code == 0) {
                            this.$message({
                                    message: "提交成功",
                                    type: 'success'
                                });
							setTimeout(()=>{
								this.$router.go(-1)
							},1500)
						} else {
                            this.$message({
                                    message: res.msg,
                                    type: 'warning'
                                });
						}
                       
                    })
                }
           
        },
        triggerFileInput(refName) {
            this.chooseNum = refName
            this.$refs[refName].click();
        },
        handleFileChange(e) {
            this.selectedFile = e.target.files[0];
            if (!this.selectedFile) {
                return;
            }
            const formData = new FormData();
            formData.append('file', this.selectedFile);
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            upload(formData).then(res => {
                loading.close();
                if (this.chooseNum == 'fileInput1') {
                    this.driverMsg.loadImg.push(res.data.src)
                } else if (this.chooseNum == 'fileInput2') {
                    this.driverMsg.loadVideo.push(res.data.src)
                } else if (this.chooseNum == 'fileInput3') {
                    this.driverMsg.samplingImg.push(res.data.src)
                } else if (this.chooseNum == 'fileInput4') {
                    this.driverMsg.sampling.push(res.data.src)
                } else if (this.chooseNum == 'fileInput5') {
                    this.driverMsg.loadEmpty = res.data.src
                } else if (this.chooseNum == 'fileInput6') {
                    this.driverMsg.loadWeightForm = res.data.src
                } else if (this.chooseNum == 'fileInput7') {
                    this.driverMsg.loadFront = res.data.src
                } else if (this.chooseNum == 'fileInput8') {
                    this.driverMsg.loadBack = res.data.src
                } else if (this.chooseNum == 'fileInput9') {
                    this.driverMsg.outScrip = res.data.src
                }
            }).catch(error => {
                console.error('Error uploading image:', error);
                // 处理上传失败后的逻辑  
            });

        }

    }
}
</script>

<style lang="scss" scoped>
.content {
    padding: 0.8rem 0;
}

.bgColor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fafafa;
    z-index: -1;
}

.box {
    width: 16.27rem;
    margin: 0 auto;

    .boxNameTit {
        color: #000000;
        font-size: 0.36rem;
        font-weight: 700;
        font-family: "SourceHanSansCN";
        padding-left: 0.5rem;
    }

    .boxCont {
        width: 100%;
        // height: 10.64rem;
        border-radius: 0.32rem;
        opacity: 1;
        border: 0.04rem solid #ffffff;
        background: #ffffff;
        box-shadow: 0 0.2rem 0.4rem 0 #19c2d11a;
        margin-top: 0.2rem;
        padding-bottom: 0.5rem;

        .boxTit {
            width: 16.12rem;
            height: 0.7rem;
            opacity: 1;
            background: linear-gradient(90deg, #00903d 0%, #00903d00 100%);
            color: #ffffff;
            font-size: 0.32rem;
            font-weight: 700;
            font-family: "SourceHanSansCN";
            border-radius: 0.32rem 0 0 0;
            display: flex;
            align-items: center;
            padding-left: 0.26rem;

        }

        .boxContBox {
            .boxLi {
                display: flex;
                align-items: center;
                margin-top: 0.3rem;
                padding-left: 0.35rem;

                .boxName {
                    color: #253a70;
                    font-size: 0.3rem;
                    font-weight: 400;
                    font-family: "SourceHanSansCN";
                    width: 4rem;
                    text-align: right;
                    margin-right: 0.3rem;

                    span {
                        color: #f92800;
                    }
                }

                .inpt {
                    width: 7.63rem;
                    height: 0.64rem;
                    border-radius: 0.16rem;
                    opacity: 1;
                    border: 0.01rem solid #ced9f2;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 0.22rem;
                    box-sizing: border-box;
                }

                input {
                    color: #253a70;
                    font-size: 0.3rem;
                    font-weight: 400;
                    font-family: "SourceHanSansCN";
                }

                input::placeholder {
                    color: #B8C2D9;
                }

                .el-icon-arrow-right {
                    font-size: 0.3rem;
                }


            }

            .boxImage {
                display: flex;
                margin-top: 0.3rem;
                padding-left: 0.35rem;
                flex-wrap: wrap;

                .imagePerBoox {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    flex: 1;
                    justify-content: space-between;
                }

                .imagePerBoox::after {
                    content: '';
                    width: 3rem;
                }

                .imagePer {
                    width: 3rem;
                    height: 3rem;
                    border-radius: 0.1rem;
                    border: 0.01rem solid #dedede;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 0.2rem;
                    position: relative !important;

                    .boxClose {
                        width: 0.5rem;
                        height: 0.5rem;
                        position: absolute;
                        top: -0.1rem;
                        right: -0.1rem;

                        img {
                            width: 100%;
                            height: 100%;
                            display: block;
                        }
                    }

                    .el-icon-plus {
                        font-size: 1rem;
                        color: #dedede;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 0.1rem;

                    }

                    video {
                        width: 100%;
                        height: 100%;
                        border-radius: 0.1rem;
                    }
                }


                .boxName {
                    color: #253a70;
                    font-size: 0.3rem;
                    font-weight: 400;
                    font-family: "SourceHanSansCN";
                    width: 4rem;
                    text-align: right;
                    margin-right: 0.3rem;

                    span {
                        color: #f92800;

                    }
                }
            }
        }
    }
}

.btnBoxBig {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.88rem;
}

.btnBox {
    width: 6.62rem;
    height: 0.98rem;
    border-radius: 1rem;
    background: linear-gradient(-41.4deg, #00903d 0%, #00b34a 100%);
    color: #ffffff;
    font-size: 0.32rem;
    font-weight: 500;
    font-family: "SourceHanSansCN";
    text-align: center;
}
</style>