<template>
    <div class="content">
        <div class="bgColor"></div>
        <HeaderTop :parentData="dataNum" :background="background" :color="color" :activeColor="activeColor"
            :position="position"></HeaderTop>
        <div class="shopBig">
            <div class="shopMsg">
                <div class="shopLeft">
                    <img :src="dataMsg.pic" alt="">
                </div>
                <div class="shopCont">
                    <div class="shopName">
                        {{dataMsg.title}}
                    </div>
                    <div class="shopPrice">
                        {{dataMsg.blurb}}
                    </div>

                </div>
            </div>
        </div>
        <div class="js">
            <!-- <div class="shopTit">
                  <div class="shopDiv"></div>
                  <div class="shopName">产品列表</div>
              </div> -->
            <div class="fwb">
                <div v-html="dataMsg.content"></div>
            </div>
            <div class="shenq" @click="open" v-if="userInfo.userType==1||userInfo.userType==2||userInfo.userType==3">申请</div>
            <div class="shenq" v-if="userInfo.userType==4||userInfo.userType==0" style="background: #999;">申请</div>
        </div>
        <div class="messageBig" v-if="popFlag">
            <div class="messageBox">
                <div class="messageTop">
                    <div class="messageTit">申请</div>
                    <div class="el-icon-close" @click="popFlag=false"></div>
                </div>
                <div class="messageFrom">
                    <div class="messageLi">
                        <div class="messageName"><span>*</span>申请人姓名</div>
                        <el-input placeholder="请输入申请人姓名" v-model="from.userName" clearable>
                        </el-input>
                    </div>
                    <div class="messageLi">
                        <div class="messageName"><span>*</span>申请人联系电话</div>
                        <el-input placeholder="请输入申请人联系电话" v-model="from.mobile" clearable>
                        </el-input>
                    </div>
                    <div class="messageLi">
                        <div class="messageName"><span>*</span>地址</div>
                        <el-input placeholder="请输入地址" v-model="from.address" clearable>
                        </el-input>
                    </div>
                    <div class="messageLi">
                        <div class="messageName"><span>*</span>土地面积</div>
                        <el-input placeholder="请输入土地面积" v-model="from.landArea" clearable>
                        </el-input>
                    </div>
                    <div class="btnBottom">
                        <div class="btnBottomLi" @click="popFlag=false">
                            取消
                        </div>
                        <div @click="submit" class="btnBottomLi" style="border: none;background: linear-gradient(90deg, #00b34a 0%, #00953f 100%);color: #ffffff;">
                            提交
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <FooterBottom></FooterBottom> -->
    </div>
</template>

<script>

import {goodsInfo,askFor} from '@/api/user/index.js'

export default {
    data() {
        return {
            userInfo: JSON.parse(localStorage.getItem('userInfo')),
            dataNum: 0,
            background: "#ffffff",
            color: "#000000",
            activeColor: "#00943F",
            position: "fixed",
            popFlag:false,
            id:"",
            name:"",
            dataMsg:{},
            from: {
					userName: "", //申请人姓名
					mobile: "", //电话
					address:"",//地址
					landArea: "", //土地面积
					goodsId:""//商品ID
				},
        }
    },
    created(){
    this.id = this.$route.query.id
    this.name = this.$route.query.name
    this.init()
  },
    methods: {
        submit(){
            this.from.goodsId = this.id
            // askFor
            if(!this.from.userName){
                this.$message({
                    message: '请输入申请人姓名',
                    type: 'warning',
                    customClass:'messageBoxTip'

                })
                return
            }
            if(!this.from.mobile){
                this.$message({
                    message: '请输入电话',
                    type: 'warning',
                    customClass:'messageBoxTip'

                })
                return

            }
            if(!this.from.address){
                this.$message({
                    message: '请输入地址',
                    type: 'warning',
                    customClass:'messageBoxTip'
                })
                return

            }
            if(!this.from.landArea){
                this.$message({
                    message: '请输入土地面积',
                    type: 'warning',
                    customClass:'messageBoxTip'
                })
                return

            }
            askFor(this.from).then(res=>{
                console.log(res)
                if (res.code == 0) {
                            this.$message({
                            message: '提交成功',
                            type: 'success',
                            customClass:'messageBoxTip'
                        })
								setTimeout(() => {
									this.popFlag = false
								}, 1000)
							} else {
                                this.$message({
                            message: res.msg,
                            type: 'warning',
                            customClass:'messageBoxTip'
                        })
								
							}
            })
        },
        init(){
            goodsInfo({goodsId:this.id}).then(res=>{
                console.log(res)
                if (res.code == 0) {
                    // 将你要渲染的数据进行字符串正则匹配
                res.data.content = res.data.content.replace(/<img/g,"<img style='max-width:100%;height:auto;'");
                    this.dataMsg = res.data
                }
            })
        },
        open() {
            // this.popFlag=true
            this.$router.push({path:'/applyFor',query:{id:this.dataMsg.id,type:1}})
        }
    }
}
</script>

<style lang="scss" scoped>

.messageBig {
    width: 100%;
    height: 100vh;
    background: rgba($color: #000000, $alpha: 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;

    .messageBox {
        width: 6rem;
        background: #ffffff;
        border-radius: 0.2rem;
        margin: 0.88rem auto;

        .messageTop {
            width: 6rem;
            height: 0.52rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 0.2rem 0.2rem 0 0;
            background: url('@/assets/bgbg.png') no-repeat center;
            background-size: 100% 100%;
            padding: 0 0.16rem;
            box-sizing: border-box;

            .messageTit {
                color: #253a70;
                font-size: 0.18rem;
                font-weight: 500;
                font-family: "SourceHanSansCN";
            }

            .el-icon-close {
                color: #253a70;
                font-size: 0.25rem;
                font-weight: 500;
                font-family: "SourceHanSansCN";
            }
        }
        .messageFrom{
            padding: 0.18rem;
            box-sizing: border-box;
            .messageLi{
                display: flex;
                align-items: center;
                margin-bottom: 0.25rem;
                .messageName{
                    color: #253a70;
 font-size: 0.16rem;
 font-weight: 400;
 font-family: "SourceHanSansCN";
 width: 1.8rem;
 text-align: right;
 margin-right: 0.1rem;
                    span{
color: #F92800;
                    }
                }
            }
            .btnBottom{
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-top: 0.7rem;
                .btnBottomLi{
                    width: 0.84rem;
height: 0.32rem;
border-radius: 0.08rem;
opacity: 1;
border: 0.01rem solid #b8c2d9;
color: #798cb5;
 font-size: 0.14rem;
 font-weight: 500;
 font-family: "SourceHanSansCN";
 line-height: 0.32rem;
 text-align: center;
 margin-left: 0.16rem;
}
            }
            
        }
    }
}

.content {
    padding-top: 0.5rem;
}

.bgColor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fafafa;
    z-index: -1;
}

.shopBig {
    width: 16.27rem;
    padding: 0.42rem;
    box-sizing: border-box;
    border-radius: 0.31rem;
    opacity: 1;
    border: 0.01rem solid #eaeaea;
    background: #ffffff;
    margin: 0.4rem auto 0;
}

.shopMsg {
    display: flex;

    .shopLeft {
        width: 6.75rem;
        height: 6.75rem;

        img {
            width: 100%;
            height: 100%;
            // border-radius: 0.31rem;
            object-fit: cover;
            border-radius: 0.31rem;
        }
    }

    .shopCont {
        flex: 1;
        margin-left: 0.54rem;

        .shopName {
            color: #343231;
            font-size: 0.36rem;
            font-weight: 700;
            font-family: "SourceHanSansCN";
        }

        .shopPrice {
            color: #868588;
            font-size: 0.26rem;
            font-weight: 400;
            font-family: "SourceHanSansCN";
            margin-top: 0.36rem;

            span {
                color: #ff0000;
                text-align: left;
                font-weight: 700;

                font-family: "SourceHanSansCN";
            }

            .shopPriceNum {
                font-size: 0.36rem;


            }
        }
    }
}

.js {
    width: 16.27rem;
    border-radius: 0.31rem;
    opacity: 1;
    border: 0.01rem solid #eaeaea;
    background: #ffffff;
    margin: 0.4rem auto;
    padding: 0.62rem;
    box-sizing: border-box;

    .shopTit {
        display: flex;
        align-items: center;
        margin-bottom: 0.42rem;

        .shopDiv {
            width: 0.08rem;
            height: 0.35rem;
            border-radius: 0 1rem 1rem 0;
            opacity: 1;
            border: 0.01rem solid #979797;
            background: #00903d;
        }

        .shopName {
            color: #00903d;
            font-size: 0.24rem;
            font-weight: 700;
            font-family: "SourceHanSansCN";
            margin-left: 0.16rem;
        }
    }

    .fwb {
        color: #343231;
        font-size: 0.36rem;
        font-weight: 700;
        font-family: "SourceHanSansCN";
    }

    .shenq {
        width: 6.71rem;
        height: 0.98rem;
        border-radius: 1rem;
        opacity: 1;
        background: linear-gradient(-41.4deg, #00903d 0%, #00b34a 100%);
        color: #ffffff;
        font-size: 0.32rem;
        font-weight: 500;
        font-family: "SourceHanSansCN";
        line-height: 0.98rem;
        text-align: center;
        margin: 0.5rem auto 0;


    }
}
</style>