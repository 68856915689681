<template>
    <div class="content">
        <div class="bgColor"></div>
        <HeaderTop :parentData="dataNum" :background="background" :color="color" :activeColor="activeColor"
            :position="position"></HeaderTop>
        <div class="box">
            <div class="boxTit">
                收款信息
            </div>
            <div class="boxLi">
                <div class="boxName"><span>*</span>账户名</div>
                <el-input class="input" v-model="userInfo.payName" size="medium" placeholder="请输入账户名"
                    suffix-icon="el-icon-arrow-right" style="width: 7.63rem;"></el-input>

            </div>
            <div class="boxLi">
                <div class="boxName"><span>*</span>银行卡号</div>
                <el-input class="input" v-model="userInfo.payAccount" size="medium" placeholder="请输入银行卡号"
                    suffix-icon="el-icon-arrow-right" style="width: 7.63rem;"></el-input>

            </div>
            <div class="boxLi">
                <div class="boxName"><span>*</span>开户行</div>
                <el-input class="input" v-model="userInfo.payBank" size="medium" placeholder="请输入开户行"
                    suffix-icon="el-icon-arrow-right" style="width: 7.63rem;"></el-input>

            </div>
        </div>
        <div class="btnBoxBig">
            <button class="btnBox" @click="submitForm">确定提交</button>

        </div>

    </div>
</template>

<script>
import { userInfo, setUser } from '@/api/user/index.js'

export default {
    data() {
        return {
            dataNum: 0,
            background: "#ffffff",
            color: "#000000",
            activeColor: "#00943F",
            position: "fixed",
            userInfo: {

            }
        }
    },
    created() {
        this.getUser()
    },
    methods: {
        getUser() {
            userInfo().then(res => {
                console.log(res)
                if (res.code == 0) {
                    this.userInfo = res.data
                    localStorage.setItem('userInfo', res.data)
                }
            })
        },
        submitForm() {
            if (!this.userInfo.payName) {
                this.$message({
          message: '请输入账户名',
          type: 'warning'
        });
                return
            }
            if (!this.userInfo.payAccount) {
                this.$message({
          message: '请输入银行卡号',
          type: 'warning'
        });
               
                return
            }
            if (!this.userInfo.payBank) {
                this.$message({
          message: '请输入开户行',
          type: 'warning'
        });
               
                return
            }
            setUser({
                payName: this.userInfo.payName,
                payAccount: this.userInfo.payAccount,
                payBank: this.userInfo.payBank,
            }).then(res => {
                if (res.code == 0) {
                    this.$message({
          message: res.msg,
          type: 'success'
        });
                } else {
                    this.$message({
          message: res.msg,
          type: 'warning'
        });
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    // padding-top: 0.5rem;
    padding-top: 50px;

}

.bgColor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fafafa;
    z-index: -1;
}

.box {
    width: 16.27rem;
    border-radius: 0.43rem;
    opacity: 1;
    border: 0.04rem solid #ffffff;
    background: #ffffff;
    box-shadow: 0 0.2rem 0.4rem 0 #19c2d11a;
    margin: 0.52rem auto 0;
    padding-bottom: 0.3rem;

    .boxTit {
        width: 16.12rem;
        height: 0.7rem;
        border-radius: 0.32rem 0.32rem 0 0;
        opacity: 1;
        background: linear-gradient(90deg, #00903d 0%, #00903d00 100%);
        color: #ffffff;
        font-size: 0.32rem;
        font-weight: 700;
        font-family: "SourceHanSansCN";
        display: flex;
        align-items: center;
        padding-left: 0.26rem;
        margin-bottom: 0.2rem;
    }

    .boxLi {
        display: flex;
        align-items: center;
        margin-top: 0.3rem;
        padding-left: 0.35rem;

        .boxName {
            color: #253a70;
            font-size: 0.3rem;
            font-weight: 400;
            font-family: "SourceHanSansCN";
            width: 4rem;
            text-align: right;
            margin-right: 0.3rem;

            span {
                color: #f92800;
            }
        }

        .inpt {
            width: 7.63rem;
            height: 0.64rem;
            border-radius: 0.16rem;
            opacity: 1;
            border: 0.01rem solid #ced9f2;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 0.22rem;
            box-sizing: border-box;
        }

        input {
            color: #253a70;
            font-size: 0.3rem;
            font-weight: 400;
            font-family: "SourceHanSansCN";
        }

        input::placeholder {
            color: #B8C2D9;
        }

        .el-icon-arrow-right {
            font-size: 0.3rem;
        }


    }
}

.btnBoxBig {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.88rem;
}

.btnBox {
    width: 6.62rem;
    height: 0.98rem;
    border-radius: 1rem;
    background: linear-gradient(-41.4deg, #00903d 0%, #00b34a 100%);
    color: #ffffff;
    font-size: 0.32rem;
    font-weight: 500;
    font-family: "SourceHanSansCN";
    text-align: center;
}
</style>