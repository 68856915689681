<template>
    <div class="content">
        <div class="bgColor"></div>
        <HeaderTop :parentData="dataNum" :background="background" :color="color" :activeColor="activeColor"
            :position="position"></HeaderTop>
        <el-form @submit.native.prevent :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <div class="box">
                <div class="boxTit">
                    粮运(司机)入驻申请
                </div>
                <el-form-item prop="title">
                    <div class="boxLi">
                        <div class="boxName"><span>*</span>粮运(司机)姓名</div>
                        <el-input class="input" v-model="ruleForm.title" size="medium" placeholder="请输入粮运(司机)姓名"
                            suffix-icon="el-icon-arrow-right" style="width: 7.63rem;"></el-input>
                    </div>
                </el-form-item>
                <el-form-item prop="mobile">
                    <div class="boxLi">
                        <div class="boxName"><span>*</span>电话</div>
                        <el-input v-model="ruleForm.mobile" placeholder="请输入电话" suffix-icon="el-icon-arrow-right"
                            style="width: 7.63rem;"></el-input>
                    </div>
                </el-form-item>
                <el-form-item prop="insure">
                    <div class="boxLi">
                        <div class="boxName"><span>*</span>商业险（{{ check?'有':'无' }}）</div>
                        
                        <el-switch
                            v-model="check"
                            active-color="#00903d" inactive-color="#999999">
                            </el-switch>
                        <!-- <el-input disabled v-model="ruleForm.insure" placeholder="请输入商业险" suffix-icon="el-icon-arrow-right"
                            style="width: 7.63rem;"></el-input> -->
                    </div>
                </el-form-item>
                <el-form-item prop="carNumber">
                    <div class="boxLi">
                        <div class="boxName"><span>*</span>车牌号</div>
                        <el-input v-model="ruleForm.carNumber" placeholder="请输入车牌号" suffix-icon="el-icon-arrow-right"
                            style="width: 7.63rem;"></el-input>
                    </div>
                </el-form-item>
                <el-form-item prop="trailerNumber">
                    <div class="boxLi">
                        <div class="boxName">车挂号</div>
                        <el-input v-model="ruleForm.trailerNumber" placeholder="请输入车挂号" suffix-icon="el-icon-arrow-right"
                            style="width: 7.63rem;"></el-input>
                    </div>
                </el-form-item>
                
               
                <el-form-item prop="idCardFront">
                    <div class="boxImage">
                        <div class="boxName"><span>*</span>身份证正面</div>
                        <div class="imagePer" @click="triggerFileInput(1)">
                            <input type="file" @change="handleFileChange" ref="fileInputs" accept="image/*"
                                style="display: none;">
                            <img :src="ruleForm.idCardFront" alt="" v-if="ruleForm.idCardFront">
                            <div class="el-icon-plus" v-if="!ruleForm.idCardFront"></div>
                        </div>
                        <!-- <image-upload  
                            :upload-url="action"  
                            :file-list="ruleForm.idCardFront"  
                            @update:file-list="handleFileListUpdate"
                            limit="1"
                            />   -->
                    </div>
                </el-form-item>
                <el-form-item prop="idCardBack">
                    <div class="boxImage">
                        <div class="boxName"><span>*</span>身份证反面</div>
                        <div class="imagePer" @click="triggerFileInput(2)">
                            <input type="file" @change="handleFileChange" ref="fileInputss" accept="image/*"
                                style="display: none;">
                            <img :src="ruleForm.idCardBack" alt="" v-if="ruleForm.idCardBack">
                            <div class="el-icon-plus" v-if="!ruleForm.idCardBack"></div>
                        </div>
                        <!-- <image-upload :upload-url="action" :file-list="ruleForm.idCardBack"
                            @update:file-list="handleFileListUpdate" limit="1" /> -->
                    </div>
                </el-form-item>
                <el-form-item prop="drivingCard">
                    <div class="boxImage">
                        <div class="boxName"><span>*</span>行驶证</div>
                        <div class="imagePer" @click="triggerFileInput(0)">
                            <input type="file" @change="handleFileChange" ref="fileInput" name="avatar" id="avatar"
                                accept="image/*" style="display: none;">
                            <img :src="ruleForm.drivingCard" alt="" v-if="ruleForm.drivingCard">
                            <div class="el-icon-plus" v-if="!ruleForm.drivingCard"></div>
                        </div>
                      
                    </div>
                </el-form-item>
                <el-form-item prop="drivingLicense">
                    <div class="boxImage">
                        <div class="boxName"><span>*</span>驾驶证</div>
                        <div class="imagePer" @click="triggerFileInput(3)">
                            <input type="file" @change="handleFileChange" ref="fileInputsss" name="avatar" id="avatar"
                                accept="image/*" style="display: none;">
                            <img :src="ruleForm.drivingLicense" alt="" v-if="ruleForm.drivingLicense">
                            <div class="el-icon-plus" v-if="!ruleForm.drivingLicense"></div>
                        </div>
                      
                    </div>
                </el-form-item>
  
  
            </div>
            <div class="agreeBox" @click="toUrl('/xieyiCont','粮运(司机)入驻协议')">
                <div class="agreeQuan" @click.stop="agreeFlag=!agreeFlag" :class="agreeFlag?'agreeQuanA':''">
                    <div class="el-icon-check" v-if="agreeFlag"></div>
                </div>
                我已阅读并同意《粮运(司机)入驻协议》
            </div>
            <el-form-item>
                <div class="btnBoxBig">
                    <button class="btnBox" @click="submitForm('ruleForm')">确定提交</button>
  
                </div>
                <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
            </el-form-item>
  
        </el-form>
    </div>
  </template>
  
  <script>
import { upload, joinIn } from '@/api/user/index.js';
  // import ImageUpload from '../../components/upload/singleImage.vue'
  export default {
    // components: {
    //     ImageUpload
    // },
    data() {
        return {
        selectedFile: null,

            check:false,
            agreeFlag:false,
            chooseNum: 0,
            imageList: [], // 初始图片列表
            // headers:{
            //     token:localStorage.getItem('token')
            // },
            action: "/api/upload",
            dialogImageUrl: '',
            dialogVisible: false,
            disabled: false,
  
            checked: true,
            dataNum: 6,
            background: "#ffffff",
            color: "#000000",
            activeColor: "#00943F",
            position: "fixed",
            ruleForm: {
                title: "", //粮运(司机)姓名
					mobile: "", //粮运(司机)人电话(登录账号)
					insure:"无",//商业险
					idCardFront: "", //身份证正面
					idCardBack: "", //身份证反面
					drivingLicense:"",//驾驶证
					drivingCard:"",//行驶证
					userType: 4,//粮运(司机)
					carNumber:"",//车牌号
					trailerNumber:"",//车挂号
            },
            rules: {
                title: [{
						required: true,
						message: '请输入粮运(司机)姓名',
						// 可以单个或者同时写两个触发验证方式 
						trigger: ['change', 'blur'],
					}],
					mobile: [{
							required: true,
							message: '请输粮运(司机)电话(登录账号)',
							trigger: ['change', 'blur']
						},
						{
							pattern: /^1[3456789]\d{9}$/,
							message: '请输入正确的手机号',
							trigger: ['change', 'blur']
						}
					],
					insure: [{
						required: true,
						message: '请输入商业险',
						// 可以单个或者同时写两个触发验证方式 
						trigger: ['change', 'blur'],
					}],
				carNumber: [{
						required: true,
						message: '请输入车牌号',
						// 可以单个或者同时写两个触发验证方式 
						trigger: ['change', 'blur'],
					}],
          idCardFront: [{
            required: true,
            message: '请上传身份证正面',
            trigger: ['change', 'blur']
          }],
                    idCardBack: [{
            required: true,
            message: '请上传身份证反面',
            trigger: ['change', 'blur']
          }],
          drivingCard: [{
						required: true,
						message: '请上传行驶证',
						trigger: ['change', 'blur']
					}],
					drivingLicense: [{
						required: true,
						message: '请上传驾驶证',
						trigger: ['change', 'blur']
					}]
            }
        }
    },
    methods: {
        toUrl(urlSrc,name){
            this.$router.push({path:urlSrc,query:{name:name}})
        },
        triggerFileInput(num) {
            console.log(num)
            this.chooseNum = num
            if (num == 0) {
                this.$refs.fileInput.click();
  
            } else if (num == 1) {
                this.$refs.fileInputs.click();
  
            }else if (num == 2) {
                this.$refs.fileInputss.click();
  
            }else if (num ==3) {
                this.$refs.fileInputsss.click();
  
            }
            // 触发隐藏 input 的点击事件  
  
        },
        handleFileChange(e) {
            this.selectedFile = e.target.files[0];
            if (!this.selectedFile) {
                return;
            }
            const formData = new FormData();
            console.log(formData, 'formData')
            console.log(this.selectedFile, 'this.selectedFile')
            formData.append('file', this.selectedFile);
            console.log(formData.length, 'formData')
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            upload(formData).then(res => {
                loading.close();
                if (this.chooseNum == 0) {
                    this.ruleForm.drivingCard = res.data.src
                } else if (this.chooseNum == 1) {
                    this.ruleForm.idCardFront = res.data.src
  
                }else if (this.chooseNum == 2) {
                    this.ruleForm.idCardBack= res.data.src
  
                }else if (this.chooseNum == 3) {
                    this.ruleForm.drivingLicense= res.data.src
  
                }
            }).catch(error => {
                console.error('Error uploading image:', error);
                // 处理上传失败后的逻辑  
            });


        },
       
        submitForm(formName) {
        if(!this.agreeFlag){
                    this.$message({
          message: '请阅读并同意协议',
          type: 'warning'
        });
          return
        }
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    joinIn(this.ruleForm).then(res => {
                        console.log(res)
                        if (res.code == 0) {
                            this.$message({
                                message: '提交成功，等待审核!',
                                type: 'success'
                            })
                            this.$router.replace('/joinOus')
                        }

                    })
                    // alert('submit!');
                    // console.log(this.ruleForm, '数据');
                } else {
                    console.log('error submit!!');
                    // return false;
                }
            });
        },
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .el-form-item__error{
    margin-left: 4rem!important;
  }
  .content {
    padding-top: 0.5rem;
  }
  
  .bgColor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fafafa;
    z-index: -1;
  }
  
  .box {
    width: 16.27rem;
    border-radius: 0.43rem;
    opacity: 1;
    border: 0.04rem solid #ffffff;
    background: #ffffff;
    box-shadow: 0 0.2rem 0.4rem 0 #19c2d11a;
    margin: 0.52rem auto 0;
  
    .boxTit {
        width: 16.12rem;
        height: 0.7rem;
        border-radius: 0.32rem 0.32rem 0 0;
        opacity: 1;
        background: linear-gradient(90deg, #00903d 0%, #00903d00 100%);
        color: #ffffff;
        font-size: 0.32rem;
        font-weight: 700;
        font-family: "SourceHanSansCN";
        display: flex;
        align-items: center;
        padding-left: 0.26rem;
        margin-bottom: 0.2rem;
    }
  
    .boxLi {
        display: flex;
        align-items: center;
        margin-top: 0.3rem;
        padding-left: 0.35rem;
  
        .boxName {
            color: #253a70;
            font-size: 0.3rem;
            font-weight: 400;
            font-family: "SourceHanSansCN";
            width: 4rem;
            text-align: right;
            margin-right: 0.3rem;
  
            span {
                color: #f92800;
            }
        }
  
        .inpt {
            width: 7.63rem;
            height: 0.64rem;
            border-radius: 0.16rem;
            opacity: 1;
            border: 0.01rem solid #ced9f2;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 0.22rem;
            box-sizing: border-box;
        }
  
        input {
            color: #253a70;
            font-size: 0.3rem;
            font-weight: 400;
            font-family: "SourceHanSansCN";
        }
  
        input::placeholder {
            color: #B8C2D9;
        }
  
        .el-icon-arrow-right {
            font-size: 0.3rem;
        }
  
  
    }
  }
  
  .btnBoxBig {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.88rem;
  }
  
  .btnBox {
    width: 6.62rem;
    height: 0.98rem;
    border-radius: 1rem;
    background: linear-gradient(-41.4deg, #00903d 0%, #00b34a 100%);
    color: #ffffff;
    font-size: 0.32rem;
    font-weight: 500;
    font-family: "SourceHanSansCN";
    text-align: center;
  }
  
  .boxImage {
    display: flex;
    margin-top: 0.3rem;
    padding-left: 0.35rem;
  
    .imagePer {
        width: 3rem;
        height: 3rem;
        border-radius: 0.1rem;
        border: 0.01rem solid #dedede;
        display: flex;
        align-items: center;
        justify-content: center;
  
        .el-icon-plus {
            font-size: 1rem;
            color: #dedede;
        }
  
        img {
            width: 100%;
            height: 100%;
            border-radius: 0.1rem;
  
        }
    }
   
  
    .boxName {
        color: #253a70;
        font-size: 0.3rem;
        font-weight: 400;
        font-family: "SourceHanSansCN";
        width: 4rem;
        text-align: right;
        margin-right: 0.3rem;
  
        span {
            color: #f92800;
  
        }
    }
  }
  .agreeBox{
        font-size: 0.3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0.5rem;
        .agreeQuan{
            width: 0.3rem;
        height: 0.3rem;
        border-radius: 50%;
        border: 0.02rem solid #253a70;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0.1rem;
        .el-icon-check{
            font-size: 0.3rem;
            font-weight: 400;
            font-family: "SourceHanSansCN";
            color: #ffffff;
        }
        }
        .agreeQuanA{
            background: #009A41;
        }
    }
  </style>