import request from "@/utils/request"
// 账号密码登录
export function login(data){
    return request({
        method:'post',
        url:'login',
        data:data
    })
}
// 图形验证码
export function captcha(uuid){
    return request({
        method:'get',
        url:'captcha',
        params:{
            uuid:uuid
        },
        responseType: 'arraybuffer', // 将响响应的数据类型修改成 arraybuffer
    })
}
// 发送验证码
export function sendSMS(data){
    return request({
        method:'get',
        url:'sendSMS',
        params:data,
    })
}

// 验证码登录
export function codeLogin(data){
    return request({
        method:'post',
        url:'codeLogin',
        data:data,
    })
}
// 注册
export function register(data){
    return request({
        method:'post',
        url:'register',
        data:data,
    })
}

// 用户信息
export function userInfo(data){
    return request({
        method:'get',
        url:'userInfo',
        params:data,
    })
}
// 网站信息
export function getWeb(data){
    return request({
        method:'get',
        url:'getWeb',
        params:data,
    })
}

// 首页信息
export function index(data){
    return request({
        method:'get',
        url:'index',
        params:data,
    })
}

// 种子、化肥、农药
export function categoryList(data){
    return request({
        method:'get',
        url:'categoryList',
        params:data,
    })
}

// 商品详情
export function getProductById(data){
    return request({
        method:'get',
        url:'getProductById',
        params:data,
    })
}

// 公司详情
export function getCompanyById(data){
    return request({
        method:'get',
        url:'getCompanyById',
        params:data,
    })
}
// 新闻详情
export function getNewsById(data){
    return request({
        method:'get',
        url:'getNewsById',
        params:data,
    })
}

// 种子、农药列表
export function goodsList(data){
    return request({
        method:'get',
        url:'goodsList',
        params:data,
    })
}
// 种子、农药详情
export function goodsInfo(data){
    return request({
        method:'get',
        url:'goodsInfo',
        params:data,
    })
}

// 种子、农药申请
export function askFor(data){
    return request({
        method:'post',
        url:'askFor',
        data:data,
    })
}

// 商品列表
export function moreProduct(data){
    return request({
        method:'get',
        url:'moreProduct',
        params:data,
    })
}

// 企业收藏
export function collect(data){
    return request({
        method:'get',
        url:'collect',
        params:data,
    })
}

// 我的收藏
export function myCollect(data){
    return request({
        method:'get',
        url:'myCollect',
        params:data,
    })
}

// 收款信息
export function setUser(data){
    return request({
        method:'post',
        url:'setUser',
        data:data,
    })
}

// 上传图片
export function upload(data){
    return request({
        method:'post',
        url:'upload',
        data:data,
        headers: {  
            'Content-Type': 'multipart/form-data'  
          }  
    })
}
// 粮市(成品制造商)入驻
export function joinIn(data){
    return request({
        method:'post',
        url:'joinIn',
        data:data,
    })
}
// 获取协议
export function getValue(data){
    return request({
        method:'get',
        url:'getValue',
        params:data,
    })
}
// 获取粮票记录
export function billList(data){
    return request({
        method:'get',
        url:'billList',
        params:data,
    })
}
// 获取粮票提交的信息
export function creditInfo(data){
    return request({
        method:'get',
        url:'creditInfo',
        params:data,
    })
}

// 待办订单
export function toDoList(data){
    return request({
        method:'get',
        url:'toDoList',
        params:data,
    })
}

// 取消订单
export function cancelOrder(data){
    return request({
        method:'get',
        url:'cancelOrder',
        params:data,
    })
}

// 订单详情
export function orderInfo(data){
    return request({
        method:'get',
        url:'orderInfo',
        params:data,
    })
}

// 订单详情
export function orderDetails(data){
    return request({
        method:'get',
        url:'orderDetails',
        params:data,
    })
}

// 发布订单
export function publishOrder(data){
    return request({
        method:'post',
        url:'publishOrder',
        data:data,
    })
}

// 全部订单列表
export function orderList(data){
    return request({
        method:'get',
        url:'orderList',
        params:data,
    })
}

// 合同详情

export function orderContract(data){
    return request({
        method:'get',
        url:'orderContract',
        params:data,
    })
}

// 核验收货
export function lastCheck(data){
    return request({
        method:'post',
        url:'lastCheck',
        data:data,
    })
}

// 上传付款凭证
export function payment(data){
    return request({
        method:'post',
        url:'payment',
        data:data,
    })
}

// 粮运(司机)列表
export function driverList(data){
    return request({
        method:'get',
        url:'driverList',
        params:data,
    })
}

// 核检过磅装车
export function loading(data){
    return request({
        method:'post',
        url:'loading',
        data:data,
    })
}
export function loadingCheck(data){
    return request({
        method:'post',
        url:'loadingCheck',
        data:data,
    })
}
export function unloading(data){
    return request({
        method:'post',
        url:'unloading',
        data:data,
    })
}

export function delivery(data){
    return request({
        method:'post',
        url:'delivery',
        data:data,
    })
}


// 公司的商品
export function getCompanyProduct(data){
    return request({
        method:'get',
        url:'getCompanyProduct',
        params:data,
    })
}
export function getCompanyOrder(data){
    return request({
        method:'get',
        url:'getCompanyOrder',
        params:data,
    })
}

export function subscribe(data){
    return request({
        method:'get',
        url:'subscribe',
        params:data,
    })
}

// 退出
export function logout(data){
    return request({
        method:'post',
        url:'logout',
        data:data,
    })
}
export function carouselInfo(data){
    return request({
        method:'get',
        url:'carouselInfo',
        params:data,
    })
}




































