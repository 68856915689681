<template>
    <div class="content">
      <HeaderTop :parentData="dataNum" :background="background" :color="color" :activeColor="activeColor"
      :position="position"></HeaderTop>
      <div class="cont">
          <div class="contTit">{{title}}</div>
          <div v-html="dataMsg" style="font-size: 0.3rem;"></div>
      </div>
    </div>
  </template>
  
  <script>
  
import {getValue} from '@/api/user/index.js'

  export default {
  data(){
      return{
          dataNum: 0,
              background: "#ffffff",
              color: "#000000",
              activeColor: "#00943F",
              position: "static",
              dataMsg:"",
              title:""
      }
  },
  created(){
    this.title = this.$route.query.name
	this.init()
    // this.dataMsg = JSON.parse(localStorage.getItem('textCont'))
    // this.dataMsg.content1 = this.dataMsg.content1.replace(/<img/g,"<img style='max-width:100%;height:auto;'");
    // this.dataMsg.content2 = this.dataMsg.content2.replace(/<img/g,"<img style='max-width:100%;height:auto;'");
    // this.dataMsg.content3 = this.dataMsg.content3.replace(/<img/g,"<img style='max-width:100%;height:auto;'");
    // this.index = this.$route.query.type
  },
  methods:{
    init(){
        getValue({key:this.title}).then(res=>{
            if (res.code == 0) {
                res.data = res.data.replace(/<img/g,"<img style='max-width:100%;height:auto;'");
						this.dataMsg = res.data
					} else {
                        this.$message({
          message: res.msg,
          type: 'warning'
        });
						
					}
        })
    }
  }
  }
  </script>
  
  <style lang="scss" scoped>
  .header{
      width: 100%;
      height: 5rem;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
  }
  .cont{
      text-align: center;
      width: 14.49rem;
      margin: 0.92rem auto;
      .contTit{
          color: #000000;
   font-size: 0.48rem;
   font-weight: 500;
   font-family: "SourceHanSansCN";
      }
      .contTip{
          color: #54545480;
   font-size: 0.16rem;
   font-weight: 500;
   font-family: "SourceHanSansCN";
   text-align: center;
      }
  }
  </style>