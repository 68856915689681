<template>
    <div class="content">
        <div class="bgColor"></div>
        <HeaderTop :parentData="dataNum" :background="background" :color="color" :activeColor="activeColor"
            :position="position"></HeaderTop>
        <div class="box">
            <div class="boxTit">
                订单详情
            </div>
            <div class="boxMin">
                <div class="boxTop">
                    基本信息
                </div>
                <div class="boxCont">
                    <div class="boxLi">
                        <div class="boxName">
                            订单编号
                        </div>
                        <div class="boxNum">
                            {{ dataMsg.orderCode || '' }}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            交易品种
                        </div>
                        <div class="boxNum">
                            {{ dataMsg.orderResult.productName || '' }}
                        </div>
                    </div>

                    <div class="boxLi">
                        <div class="boxName">
                            指派时间
                        </div>
                        <div class="boxNum">
                            {{ dataMsg.createDate || '' }}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            供应人
                        </div>
                        <div class="boxNum">
                            {{ dataMsg.orderResult.supply || '' }}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            负责人电话
                        </div>
                        <div class="boxNum">
                            {{ dataMsg.orderResult.supplyPhone || '' }}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            采购人
                        </div>
                        <div class="boxNum">
                            {{ dataMsg.orderResult.promoter || '' }}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            采购人电话
                        </div>
                        <div class="boxNum">
                            {{ dataMsg.orderResult.buyerPhone || '' }}
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="gysBox">
                    <div class="gysBoxTit">
                        粮仓(供应商)({{ dataMsg.orderResult.companyB }})
                    </div>
                    <div class="gysLi">
                        <div class="gysTop">
                            <div class="gysName">{{ dataMsg.driverName }}</div>
                            <div class="gysStatus" v-if="dataMsg.status >= 0 && dataMsg.status <= 3">待运输</div>
                            <div class="gysStatus" v-if="dataMsg.status >= 4 && dataMsg.status <= 6"
                                style="background: #2dc9a11a;color: #2DC9A1;">运输中</div>
                            <div class="gysStatus" v-if="dataMsg.status == 7"
                                style="background: #339dff1a;color: #339DFF;">待结算</div>
                            <div class="gysStatus" v-if="dataMsg.status == 8"
                                style="background: #ff8d1a1a;color: #FF8D1A;">已完成</div>
                            <div class="gysStatus" v-if="dataMsg.status == 10"
                                style="background: #ff57331a;color: #FF5733;">售后</div>

                        </div>
                       
                        <div class="gysDisplay">
                            
                            <div class="gysDisplayBottom">
                                <div class="gysDisplayLi" v-if="dataMsg.loadNetWeight">
                                    运输：<span>{{ dataMsg.loadNetWeight }}kg</span>
                                </div>
                                <div class="gysDisplayLi">
                                    联系方式：<span>{{ dataMsg.telphone }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="gysDisplay" v-if="dataMsg.freight||dataMsg.clearingFee">
                            <div class="gysDisplayBottom">
                                <div class="gysDisplayLi" v-if="dataMsg.freight">
                                    运费单价：<span>{{ dataMsg.freight }}/kg</span>
                                </div>
                                <div class="gysDisplayLi" v-if="dataMsg.clearingFee">
                                    运费总价：<span>{{ dataMsg.clearingFee }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="gysDisplay" v-if="dataMsg.startTime">
                            <div class="gysDisplayBottom">
                                <div class="gysDisplayLi">
                                    发车时间：<span>{{ dataMsg.startTime }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="gysDisplay">
                            <div class="gysDisplayBottom">
                                <div class="gysDisplayLi">
                                    装车地点：<span>{{ dataMsg.startPoint }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="gysDisplay">
                            <div class="gysDisplayBottom">
                                <div class="gysDisplayLi">
                                    运输目的地：<span>{{ dataMsg.endPoint }}</span>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="gysDisplay">
                            <div class="gysDisplayBottom">
                                <div class="gysDisplayLi" style="color: #339cff;">
                                    运费结算单
                                </div>
                            </div>
                        </div> -->
                        
                        <div class="gysDisplay">
                            <div style="color: #339cff;font-size: 0.3rem;" @click.stop="person" v-if="dataMsg.status==8">
                                运费结算单
                            </div>
                            <div v-if="dataMsg.status!=8"></div>
                            <div class="gysBtn" v-if="dataMsg.status >= 0 && dataMsg.status <= 3">
                                <div class="gysBtnLi"
                                    @click="toUrl('/hyzcgbInpt', dataMsg.id, dataMsg.orderResult.productName, 2)">
                                    装车
                                </div>
                                <div class="gysBtnLi"
                                    @click="toUrl('/hyzcgbInpt', dataMsg.id, dataMsg.orderResult.productName, 3)">
                                    过磅
                                </div>
                            </div>
                            <div class="gysBtn" v-if="dataMsg.status >= 4 && dataMsg.status <= 6">
                                <div class="gysBtnLi"
                                    @click="toUrl('/hyzcgbInpt', dataMsg.id, dataMsg.orderResult.productName, 4)">
                                    卸车过磅
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="showImage" class="image-preview">  
            <img :src="dataMsg.payScreenshotThree" alt="预览图片">  
            <!-- 可以添加一个关闭按钮，点击后隐藏图片 -->  
            <el-button type="primary" style="margin-top: 0.3rem;width: 100%;" @click="showImage = false">关闭</el-button>
            <!-- <button @click="showImage = false" type="primary">关闭</button>   -->
        </div>  
    </div>
</template>

<script>

import { orderInfo } from '@/api/user/index.js'
export default {
    data() {
        return {
            showImage: false,  
            dataNum: 0,
            background: "#ffffff",
            color: "#000000",
            activeColor: "#00943F",
            position: "fixed",
            userInfo: JSON.parse(localStorage.getItem('userInfo')),
            dataMsg: {
                orderResult:{}
            },
            id: ""
        }
    },
    created() {
        this.id = this.$route.query.id
        this.init()
    },
    methods: {
        person(){
            this.showImage=true
            // window.location.replace = (this.dataMsg.payScreenshotThree)
        },
        toUrl(urlSrc,id,name,type){
            this.$router.push({path:urlSrc,query:{id:id,name:name,type:type}})
        },
        init() {
            orderInfo({ orderId: this.id }).then(res => {
                if (res.code == 0) {
                    this.dataMsg = res.data
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning',
                        customClass: 'messageBoxTip'
                    })
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    padding-top: 0.5rem;
}

.bgColor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fafafa;
    z-index: -1;
}

.box {
    width: 15.68rem;
    border-radius: 0.32rem;
    opacity: 1;
    background: #ffffff;
    margin: 0 auto;
    padding: 0.43rem 0.59rem;
    box-sizing: border-box;

    .boxTit {
        color: #000000;
        font-size: 0.5rem;
        font-weight: 500;
        font-family: "SourceHanSansCN";
    }

    .boxMin {
        width: 14.48rem;
        border-radius: 0.32rem;
        opacity: 1;
        border: 0.04rem solid #ffffff;
        background: #ffffff;
        box-shadow: 0 0.2rem 0.4rem 0 #2fa5631a;
        margin-top: 0.4rem;

        .boxTop {
            width: 10.6rem;
            height: 0.7rem;
            border-radius: 0.3rem 0 0 0;
            opacity: 1;
            background: linear-gradient(90deg, rgba(47, 165, 99, 0.5) 0%, #2fa56300 100%);
            color: #2fa563;
            font-size: 0.32rem;
            font-weight: 500;
            font-family: "SourceHanSansCN";
            display: flex;
            align-items: center;
            padding-left: 0.65rem;
        }

        .boxCont {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            padding: 0.5rem 0.65rem;
            box-sizing: border-box;

            .boxLi {
                width: 50%;
                margin-bottom: 0.5rem;

                .boxName {
                    color: #34323166;
                    font-size: 0.26rem;
                    font-weight: 400;
                    font-family: "SourceHanSansCN";
                }

                .boxNum {
                    color: #343231;
                    font-size: 0.28rem;
                    font-weight: 500;
                    font-family: "SourceHanSansCN";
                }
            }
        }
    }

    .gysBox {
        width: 100%;
        border-radius: 0.32rem;
        opacity: 1;
        border: 0.04rem solid #ffffff;
        background: #ffffff;
        box-shadow: 0 0.2rem 0.4rem 0 #2fa5631a;
        margin-top: 0.36rem;
        padding-bottom: 0.3rem;

        .gysBoxTit {
            width: 8.06rem;
            height: 0.7rem;
            border-radius: 0.3rem 0 0 0;
            opacity: 1;
            background: linear-gradient(90deg, rgba(0, 128, 255, 0.3) 0%, #0080ff00 100%);
            color: #339cff;
            font-size: 0.32rem;
            font-weight: 500;
            font-family: "SourceHanSansCN";
            display: flex;
            align-items: center;
            padding-left: 0.5rem;
        }

        .gysNum {
            color: #343231;
            font-size: 0.3rem;
            font-weight: 700;
            font-family: "SourceHanSansCN";
            padding: 0.3rem 0.5rem 0;
            box-sizing: border-box;
        }

        .ddfp {
            padding: 0.3rem 0.5rem 0;
            box-sizing: border-box;
            color: #343231;
            font-size: 0.3rem;
            font-weight: 700;
            font-family: "SourceHanSansCN";
        }

        .gysLi {
            padding: 0.3rem 0.5rem 0.5rem;
            box-sizing: border-box;
            border-bottom: 0.01rem solid #F0F4F6;

            .gysTop {
                display: flex;
                align-items: center;

                .gysName {
                    color: #343231;
                    font-size: 0.3rem;
                    font-weight: 700;
                    font-family: "SourceHanSansCN";
                }

                .gysStatus {
                    color: #339cff;
                    width: 1.14rem;
                    height: 0.38rem;
                    border-radius: 0.19rem;
                    background: #0080ff1a;
                    font-size: 0.22rem;
                    font-weight: 400;
                    font-family: "SourceHanSansCN";
                    margin-left: 0.2rem;
                    text-align: center;
                    line-height: 0.38rem;
                }
            }

            .gysDisplay {
                margin-top: 0.2rem;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .gysDisplayBottom {
                    display: flex;
                    align-items: center;

                    .gysDisplayLi {
                        color: #868588;
                        margin-right: 1rem;
                        font-size: 0.26rem;
                        font-weight: 400;
                        font-family: "SourceHanSansCN";

                        span {
                            color: #555555;
                            font-size: 0.28rem;
                            font-weight: 500;
                            font-family: "SourceHanSansCN";
                        }
                    }
                }

                .gysBtn {
                    display: flex;
                    align-items: center;

                    .gysBtnLi {
                        margin-left: 0.5rem;
                        color: #339cff;
                        font-size: 0.26rem;
                        font-weight: 400;
                        font-family: "SourceHanSansCN";
                    }
                }
            }
        }
    }
}
.image-preview {  
  /* 图片预览的样式，比如居中显示、固定大小等 */  
  display: flex;  
  justify-content: center;  
  align-items: center;  
  flex-direction: column;
  position: fixed; /* 可以根据需求选择是否固定位置 */  
  top: 50%;  
  left: 50%;  
  transform: translate(-50%, -50%);  
  z-index: 1000; /* 确保图片预览在顶层显示 */  
  background-color: rgba(0, 0, 0, 0.5); /* 遮罩层，可选 */  
  padding: 20px;  
  border-radius: 10px;  
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  
}  
  
.image-preview img {  
  max-width: 100%; /* 或者设置具体的宽度 */  
  max-height: 90vh; /* 保持图片高度在合理范围内 */  
  display: block; /* 移除图片下方的间隙 */  
}  
.image-preview button{
    
}
</style>