<template>
    <div class="content">
        <div class="bgColor"></div>
        <HeaderTop :parentData="dataNum" :background="background" :color="color" :activeColor="activeColor"
            :position="position"></HeaderTop>
        <el-form @submit.native.prevent :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px"
            class="demo-ruleForm">
            <div class="box">
                <div class="boxTit">
                    粮票申请
                </div>
                <div class="contBbox">
                    
                    <div class="contBboxLi" v-if="isCredit==1">
                        申请状态：{{(ruleForm.status==0&&'待审核')||(ruleForm.status==2&&'已驳回')}}
                    </div>
                    <div class="contBboxLi" style="color: red;" v-if="ruleForm.status==2&&ruleForm.reason">
                        驳回原因：{{ruleForm.reason}}
                    </div>
                </div>
                <el-form-item prop="businessLicense">
                    <div class="boxImage">
                        <div class="boxName"><span>*</span>营业执照</div>
                        <div class="imagePer" @click="triggerFileInput('fileInput1')">
                            <input type="file" @change="handleFileChange" ref="fileInput1" accept="image/*"
                                style="display: none;">
                            <img :src="ruleForm.businessLicense" alt="" v-if="ruleForm.businessLicense">
                            <div class="el-icon-plus" v-if="!ruleForm.businessLicense"></div>
                            <div class="boxClose" @click.stop="ruleForm.businessLicense=''" v-if="ruleForm.businessLicense">
                                    <img src="@/assets/delete.png" alt="">
                                </div>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item prop="idCardFront">
                    <div class="boxImage">
                        <div class="boxName"><span>*</span>法人身份证正面</div>
                        <div class="imagePer" @click="triggerFileInput('fileInput2')">
                            <input type="file" @change="handleFileChange" ref="fileInput2" accept="image/*"
                                style="display: none;">
                            <img :src="ruleForm.idCardFront" alt="" v-if="ruleForm.idCardFront">
                            <div class="el-icon-plus" v-if="!ruleForm.idCardFront"></div>
                            <div class="boxClose" @click.stop="ruleForm.idCardFront=''" v-if="ruleForm.idCardFront">
                                    <img src="@/assets/delete.png" alt="">
                                </div>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item prop="idCardBack">
                    <div class="boxImage">
                        <div class="boxName"><span>*</span>法人身份证反面</div>
                        <div class="imagePer" @click="triggerFileInput('fileInput3')">
                            <input type="file" @change="handleFileChange" ref="fileInput3" accept="image/*"
                                style="display: none;">
                            <img :src="ruleForm.idCardBack" alt="" v-if="ruleForm.idCardBack">
                            <div class="el-icon-plus" v-if="!ruleForm.idCardBack"></div>
                            <div class="boxClose" @click.stop="ruleForm.idCardBack=''" v-if="ruleForm.idCardBack">
                                    <img src="@/assets/delete.png" alt="">
                                </div>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item prop="businessRules">
                    <div class="boxImage" style="flex-wrap: nowrap;">
                        <div class="boxName"><span>*</span>公司章程</div>
                        <div class="imagePerBoox">
                            <div class="imagePer" v-for="(item, index) in businessRules" :key="index">
                                <img :src="item" alt="">
                                <div class="boxClose" @click.stop="deleteImg(index, 4)">
                                    <img src="@/assets/delete.png" alt="">
                                </div>
                            </div>
                            <div class="imagePer" @click="triggerFileInput('fileInput4')">
                                <input type="file" @change="handleFileChange" ref="fileInput4" accept="image/*"
                                    style="display: none;">
                                <div class="el-icon-plus"></div>
                            </div>
                        </div>

                    </div>
                </el-form-item>
                <el-form-item prop="companyBankStatement">
                    <div class="boxImage" style="flex-wrap: nowrap;">
                        <div class="boxName"><span>*</span>公司银行流水</div>
                        <div class="imagePerBoox">
                            <div class="imagePer" v-for="(item, index) in companyBankStatement" :key="index">
                                <img :src="item" alt="">
                                <div class="boxClose" @click.stop="deleteImg(index, 5)">
                                    <img src="@/assets/delete.png" alt="">

                                </div>
                            </div>
                            <div class="imagePer" @click="triggerFileInput('fileInput5')">
                                <input type="file" @change="handleFileChange" ref="fileInput5" accept="image/*"
                                    style="display: none;">
                                <div class="el-icon-plus"></div>
                            </div>
                        </div>

                    </div>
                </el-form-item>
                <el-form-item prop="bankStatement">
                    <div class="boxImage" style="flex-wrap: nowrap;">
                        <div class="boxName"><span>*</span>法人银行流水</div>
                        <div class="imagePerBoox">
                            <div class="imagePer" v-for="(item, index) in bankStatement" :key="index">
                                <img :src="item" alt="">
                                <div class="boxClose" @click.stop="deleteImg(index, 6)">
                                    <img src="@/assets/delete.png" alt="">

                                </div>
                            </div>
                            <div class="imagePer" @click="triggerFileInput('fileInput6')">
                                <input type="file" @change="handleFileChange" ref="fileInput6" accept="image/*"
                                    style="display: none;">
                                <div class="el-icon-plus"></div>
                            </div>
                        </div>

                    </div>
                </el-form-item>
                <el-form-item prop="residenceBooklet">
                    <div class="boxImage" style="flex-wrap: nowrap;">
                        <div class="boxName"><span>*</span>法人户口本</div>
                        <div class="imagePerBoox">
                            <div class="imagePer" v-for="(item, index) in residenceBooklet" :key="index">
                                <img :src="item" alt="">
                                <div class="boxClose" @click.stop="deleteImg(index, 7)">
                                    <img src="@/assets/delete.png" alt="">

                                </div>
                            </div>
                            <div class="imagePer" @click="triggerFileInput('fileInput7')">
                                <input type="file" @change="handleFileChange" ref="fileInput7" accept="image/*"
                                    style="display: none;">
                                <div class="el-icon-plus"></div>
                            </div>
                        </div>

                    </div>
                </el-form-item>
                <el-form-item prop="marriageLicense">
                    <div class="boxImage">
                        <div class="boxName"><span>*</span>结婚证明（结婚证）</div>
                        <div class="imagePer" @click="triggerFileInput('fileInput8')">
                            <input type="file" @change="handleFileChange" ref="fileInput8" accept="image/*"
                                style="display: none;">
                            <img :src="ruleForm.marriageLicense" alt="" v-if="ruleForm.marriageLicense">
                            <div class="el-icon-plus" v-if="!ruleForm.marriageLicense"></div>
                            <div class="boxClose" @click.stop="ruleForm.marriageLicense=''" v-if="ruleForm.marriageLicense">
                                    <img src="@/assets/delete.png" alt="">
                                </div>
                        </div>
                    </div>
                </el-form-item>

                <el-form-item prop="titleDeed">
                    <div class="boxImage" style="flex-wrap: nowrap;">
                        <div class="boxName"><span>*</span>场地租赁合同或场地产权证明</div>
                        <div class="imagePerBoox">
                            <div class="imagePer" v-for="(item, index) in titleDeed" :key="index">
                                <img :src="item" alt="">
                                <div class="boxClose" @click.stop="deleteImg(index, 9)">
                                    <img src="@/assets/delete.png" alt="">

                                </div>
                            </div>
                            <div class="imagePer" @click="triggerFileInput('fileInput9')">
                                <input type="file" @change="handleFileChange" ref="fileInput9" accept="image/*"
                                    style="display: none;">
                                <div class="el-icon-plus"></div>
                            </div>
                        </div>

                    </div>
                </el-form-item>
                <el-form-item prop="financialStatement">
                    <div class="boxImage" style="flex-wrap: nowrap;">
                        <div class="boxName"><span>*</span>公司财务报表</div>
                        <div class="imagePerBoox">
                            <div class="imagePer" v-for="(item, index) in financialStatement" :key="index">
                                <img :src="item" alt="">
                                <div class="boxClose" @click.stop="deleteImg(index, 10)">
                                    <img src="@/assets/delete.png" alt="">

                                </div>
                            </div>
                            <div class="imagePer" @click="triggerFileInput('fileInput10')">
                                <input type="file" @change="handleFileChange" ref="fileInput10" accept="image/*"
                                    style="display: none;">
                                <div class="el-icon-plus"></div>
                            </div>
                        </div>

                    </div>
                </el-form-item>
                
                <el-form-item prop="proofTax">
                    <div class="boxImage" style="flex-wrap: nowrap;">
                        <div class="boxName"><span>*</span>纳税证明</div>
                        <div class="imagePerBoox">
                            <div class="imagePer" v-for="(item, index) in proofTax" :key="index">
                                <img :src="item" alt="">
                                <div class="boxClose" @click.stop="deleteImg(index, 11)">
                                    <img src="@/assets/delete.png" alt="">

                                </div>
                            </div>
                            <div class="imagePer" @click="triggerFileInput('fileInput11')">
                                <input type="file" @change="handleFileChange" ref="fileInput11" accept="image/*"
                                    style="display: none;">
                                <div class="el-icon-plus"></div>
                            </div>
                        </div>

                    </div>
                </el-form-item>

                <el-form-item prop="previousContract">
                    <div class="boxImage" style="flex-wrap: nowrap;">
                        <div class="boxName"><span>*</span>之前采购合同</div>
                        <div class="imagePerBoox">
                            <div class="imagePer" v-for="(item, index) in previousContract" :key="index">
                                <img :src="item" alt="">
                                <div class="boxClose" @click.stop="deleteImg(index, 12)">
                                    <img src="@/assets/delete.png" alt="">

                                </div>
                            </div>
                            <div class="imagePer" @click="triggerFileInput('fileInput12')">
                                <input type="file" @change="handleFileChange" ref="fileInput12" accept="image/*"
                                    style="display: none;">
                                <div class="el-icon-plus"></div>
                            </div>
                        </div>

                    </div>
                </el-form-item>
                <el-form-item prop="receipt">
                    <div class="boxImage" style="flex-wrap: nowrap;">
                        <div class="boxName"><span>*</span>办公场地近几个月水电小票</div>
                        <div class="imagePerBoox">
                            <div class="imagePer" v-for="(item, index) in receipt" :key="index">
                                <img :src="item" alt="">
                                <div class="boxClose" @click.stop="deleteImg(index, 13)">
                                    <img src="@/assets/delete.png" alt="">

                                </div>
                            </div>
                            <div class="imagePer" @click="triggerFileInput('fileInput13')">
                                <input type="file" @change="handleFileChange" ref="fileInput13" accept="image/*"
                                    style="display: none;">
                                <div class="el-icon-plus"></div>
                            </div>
                        </div>

                    </div>
                </el-form-item>
                <el-form-item prop="proofAddress">
                    <div class="boxImage">
                        <div class="boxName"><span>*</span>借款人住址证明</div>
                        <div class="imagePer" @click="triggerFileInput('fileInput14')">
                            <input type="file" @change="handleFileChange" ref="fileInput14" accept="image/*"
                                style="display: none;">
                            <img :src="ruleForm.proofAddress" alt="" v-if="ruleForm.proofAddress">
                            <div class="el-icon-plus" v-if="!ruleForm.proofAddress"></div>
                            <div class="boxClose" @click.stop="ruleForm.proofAddress=''" v-if="ruleForm.proofAddress">
                                    <img src="@/assets/delete.png" alt="">
                                </div>
                        </div>
                    </div>
                </el-form-item>

                <el-form-item prop="proofIncome">
                    <div class="boxImage" style="flex-wrap: nowrap;">
                        <div class="boxName"><span>*</span>收入证明</div>
                        <div class="imagePerBoox">
                            <div class="imagePer" v-for="(item, index) in proofIncome" :key="index">
                                <img :src="item" alt="">
                                <div class="boxClose" @click.stop="deleteImg(index, 15)">
                                    <img src="@/assets/delete.png" alt="">

                                </div>
                            </div>
                            <div class="imagePer" @click="triggerFileInput('fileInput15')">
                                <input type="file" @change="handleFileChange" ref="fileInput15" accept="image/*"
                                    style="display: none;">
                                <div class="el-icon-plus"></div>
                            </div>
                        </div>

                    </div>
                </el-form-item>
                <el-form-item prop="proofMoney">
                    <div class="boxImage" style="flex-wrap: nowrap;">
                        <div class="boxName"><span>*</span>财产证明</div>
                        <div class="imagePerBoox">
                            <div class="imagePer" v-for="(item, index) in proofMoney" :key="index">
                                <img :src="item" alt="">
                                <div class="boxClose" @click.stop="deleteImg(index, 16)">
                                    <img src="@/assets/delete.png" alt="">

                                </div>
                            </div>
                            <div class="imagePer" @click="triggerFileInput('fileInput16')">
                                <input type="file" @change="handleFileChange" ref="fileInput16" accept="image/*"
                                    style="display: none;">
                                <div class="el-icon-plus"></div>
                            </div>
                        </div>

                    </div>
                </el-form-item>
                <el-form-item prop="commitBook">
                    <div class="boxImage" style="flex-wrap: nowrap;">
                        <div class="boxName"><span>*</span>承诺书</div>
                        <div class="imagePerBoox">
                            <div class="imagePer" v-for="(item, index) in commitBook" :key="index">
                                <img :src="item" alt="">
                                <div class="boxClose" @click.stop="deleteImg(index, 17)">
                                    <img src="@/assets/delete.png" alt="">

                                </div>
                            </div>
                            <div class="imagePer" @click="triggerFileInput('fileInput17')">
                                <input type="file" @change="handleFileChange" ref="fileInput17" accept="image/*"
                                    style="display: none;">
                                <div class="el-icon-plus"></div>
                            </div>
                        </div>

                    </div>
                </el-form-item>

            </div>
            <el-form-item>
                <div class="btnBoxBig">
                    <button class="btnBox" @click="submitForm('ruleForm')">确定提交</button>

                </div>
                <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
            </el-form-item>

        </el-form>
    </div>
</template>

<script>
import { upload, joinIn,creditInfo } from '@/api/user/index.js';
// import ImageUpload from '../../components/upload/singleImage.vue'
export default {
    // components: {
    //     ImageUpload
    // },
    data() {
        return {
            isCredit:0,
            selectedFile: null,
            check: false,
            agreeFlag: false,
            chooseNum: '',
            dataNum: 0,
            background: "#ffffff",
            color: "#000000",
            activeColor: "#00943F",
            position: "fixed",
            businessRules: [],//公司章程
            companyBankStatement: [],//公司银行流水
            bankStatement: [],//法人银行流水
            residenceBooklet: [],//法人户口本
            titleDeed: [],//场地租赁合同或场地产权证明
            financialStatement: [],//公司财务报表
            proofTax: [],//纳税证明
            previousContract: [],//之前采购合同
            receipt: [],//办公场地近几个月水电小票
            proofIncome: [],//收入证明
            proofMoney: [],//财产证明
            commitBook: [],//承诺书
            ruleForm: {
                businessLicense: "",//营业执照
                idCardFront: "",//法人身份证正面
                idCardBack: "",//法人身份证反面
                businessRules: "",//公司章程
                companyBankStatement: "",//公司银行流水
                bankStatement: "",//法人银行流水
                residenceBooklet: "",//法人户口本
                marriageLicense: "",//结婚证明
                titleDeed: "",//场地租赁合同或场地产权证明
                financialStatement: "",//公司财务报表
                proofTax: "",//纳税证明
                previousContract: "",//之前采购合同
                receipt: "",//办公场地近几个月水电小票
                proofAddress: "",//借款人住址证明
                proofIncome: "",//收入证明
                proofMoney: "",//财产证明
                commitBook: "",//承诺书
            },
            rules: {
                businessLicense: [{
                    required: true,
                    message: '请上传营业执照',
                    trigger: ['change', 'blur']
                }],
                idCardFront: [{
                    required: true,
                    message: '请上传法人身份证正面',
                    trigger: ['change', 'blur']
                }],
                idCardBack: [{
                    required: true,
                    message: '请上传法人身份证反面',
                    trigger: ['change', 'blur']
                }],
                businessRules: [{
                    required: true,
                    message: '请上传公司章程',
                    trigger: ['change', 'blur']
                }],
                companyBankStatement: [{
                    required: true,
                    message: '请上传公司银行流水',
                    trigger: ['change', 'blur']
                }],
                bankStatement: [{
                    required: true,
                    message: '请上传法人银行流水',
                    trigger: ['change', 'blur']
                }],
                residenceBooklet: [{
                    required: true,
                    message: '请上传法人户口本',
                    trigger: ['change', 'blur']
                }],
                marriageLicense: [{
                    required: true,
                    message: '请上传结婚证明（结婚证）',
                    trigger: ['change', 'blur']
                }],
                titleDeed: [{
                    required: true,
                    message: '请上传场地租赁合同或场地产权证明',
                    trigger: ['change', 'blur']
                }],
                financialStatement: [{
                    required: true,
                    message: '请上传公司财务报表',
                    trigger: ['change', 'blur']
                }],
                proofTax: [{
                    required: true,
                    message: '请上传纳税证明',
                    trigger: ['change', 'blur']
                }],
                previousContract: [{
                    required: true,
                    message: '请上传之前采购合同',
                    trigger: ['change', 'blur']
                }],
                receipt: [{
                    required: true,
                    message: '请上传办公场地近几个月水电小票',
                    trigger: ['change', 'blur']
                }],
                proofAddress: [{
                    required: true,
                    message: '请上传借款人住址证明',
                    trigger: ['change', 'blur']
                }],
                proofIncome: [{
                    required: true,
                    message: '请上传收入证明',
                    trigger: ['change', 'blur']
                }],
                proofMoney: [{
                    required: true,
                    message: '请上传财产证明',
                    trigger: ['change', 'blur']
                }],
                commitBook: [{
                    required: true,
                    message: '请上传承诺书',
                    trigger: ['change', 'blur']
                }]
            }
        }
    },
    created(){
        this.isCredit = this.$route.query.index
        this.lpxx()
    },
    methods: {
        lpxx(){
            creditInfo().then(res=>{
                if (res.code == 0) {
						this.from = res.data
						this.businessRules = this.from.businessRules.split(',')
						this.companyBankStatement = this.from.companyBankStatement.split(',')
						this.bankStatement = this.from.bankStatement.split(',')
						this.residenceBooklet = this.from.residenceBooklet.split(',')
						this.titleDeed = this.from.titleDeed.split(',')
						this.financialStatement = this.from.financialStatement.split(',')
						this.proofTax = this.from.proofTax.split(',')
						this.previousContract = this.from.previousContract.split(',')
						this.receipt = this.from.receipt.split(',')
						this.proofIncome = this.from.proofIncome.split(',')
						this.proofMoney = this.from.proofMoney.split(',')
						this.commitBook = this.from.commitBook.split(',')
					}
            })

        },
        deleteImg(index,num){
				if(num==4){
					this.businessRules.splice(index,1)
					this.from.businessRules = this.businessRules.join(',')
				}else if(num==5){
					this.companyBankStatement.splice(index,1)
					this.from.companyBankStatement = this.companyBankStatement.join(',')
				}else if(num==6){
					this.bankStatement.splice(index,1)
					this.from.bankStatement = this.bankStatement.join(',')
				}else if(num==7){
					this.residenceBooklet.splice(index,1)
					this.from.residenceBooklet = this.residenceBooklet.join(',')
				}else if(num==9){
					this.titleDeed.splice(index,1)
					this.from.titleDeed = this.titleDeed.join(',')
				}else if(num==10){
					this.financialStatement.splice(index,1)
					this.from.financialStatement = this.financialStatement.join(',')
				}else if(num==11){
					this.proofTax.splice(index,1)
					this.from.proofTax = this.proofTax.join(',')
				}else if(num==12){
					this.previousContract.splice(index,1)
					this.from.previousContract = this.previousContract.join(',')
				}else if(num==13){
					this.receipt.splice(index,1)
					this.from.receipt = this.receipt.join(',')
				}else if(num==15){
					this.proofIncome.splice(index,1)
					this.from.proofIncome = this.proofIncome.join(',')
				}else if(num==16){
					this.proofMoney.splice(index,1)
					this.from.proofMoney = this.proofMoney.join(',')
				}else if(num==17){
					this.commitBook.splice(index,1)
					this.from.commitBook = this.commitBook.join(',')
				}
			},
        toUrl(urlSrc, name) {
            this.$router.push({ path: urlSrc, query: { name: name } })
        },
        triggerFileInput(refName) {
            this.chooseNum = refName
            this.$refs[refName].click();
            // if (num == 0) {
            //     this.$refs.fileInput.click();

            // } else if (num == 1) {
            //     this.$refs.fileInputs.click();

            // }else if (num == 2) {
            //     this.$refs.fileInputss.click();

            // }else if (num ==3) {
            //     this.$refs.fileInputsss.click();

            // }
            // 触发隐藏 input 的点击事件  

        },
        handleFileChange(event) {
            console.log(event)
            this.selectedFile = event.target.files[0];
            if (!this.selectedFile) {
                return;
            }
            const formData = new FormData();
            console.log(formData, 'formData')
            console.log(this.selectedFile, 'this.selectedFile')
            formData.append('file', this.selectedFile);
            console.log(formData.length, 'formData')
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            upload(formData).then(res => {
                loading.close();
                console.log(this.chooseNum)
                if (this.chooseNum == 'fileInput1') {
                    this.ruleForm.businessLicense = res.data.src
                } else if (this.chooseNum == 'fileInput2') {
                    this.ruleForm.idCardFront = res.data.src
                } else if (this.chooseNum == 'fileInput3') {
                    this.ruleForm.idCardBack = res.data.src
                } else if (this.chooseNum == 'fileInput4') {
                    this.businessRules.push(res.data.src)
                    this.ruleForm.businessRules = this.businessRules.join(',')
                } else if (this.chooseNum == 'fileInput5') {
                    this.companyBankStatement.push(res.data.src)
                    this.ruleForm.companyBankStatement = this.companyBankStatement.join(',')
                } else if (this.chooseNum == 'fileInput6') {
                    this.bankStatement.push(res.data.src)
                    this.ruleForm.bankStatement = this.bankStatement.join(',')
                } else if (this.chooseNum == 'fileInput7') {
                    this.residenceBooklet.push(res.data.src)
                    this.ruleForm.residenceBooklet = this.residenceBooklet.join(',')
                } else if (this.chooseNum == 'fileInput8') {
                    this.ruleForm.marriageLicense = res.data.src
                } else if (this.chooseNum == 'fileInput9') {
                    this.titleDeed.push(res.data.src)
                    this.ruleForm.titleDeed = this.titleDeed.join(',')
                } else if (this.chooseNum == 'fileInput10') {
                    this.financialStatement.push(res.data.src)
                    this.ruleForm.financialStatement = this.financialStatement.join(',')
                } else if (this.chooseNum == 'fileInput11') {
                    this.proofTax.push(res.data.src)
                    this.ruleForm.proofTax = this.proofTax.join(',')
                } else if (this.chooseNum == 'fileInput12') {
                    this.previousContract.push(res.data.src)
                    this.ruleForm.previousContract = this.previousContract.join(',')
                } else if (this.chooseNum == 'fileInput13') {
                    this.receipt.push(res.data.src)
                    this.ruleForm.receipt = this.receipt.join(',')
                } else if (this.chooseNum == 'fileInput14') {
                    this.ruleForm.proofAddress = res.data.src
                } else if (this.chooseNum == 'fileInput15') {
                    this.proofIncome.push(res.data.src)
                    this.ruleForm.proofIncome = this.proofIncome.join(',')
                } else if (this.chooseNum == 'fileInput16') {
                    this.proofMoney.push(res.data.src)
                    this.ruleForm.proofMoney = this.proofMoney.join(',')
                } else if (this.chooseNum == 'fileInput17') {
                    this.commitBook.push(res.data.src)
                    this.ruleForm.commitBook = this.commitBook.join(',')
                }

            }).catch(error => {
                console.error('Error uploading image:', error);
                // 处理上传失败后的逻辑  
            });


        },

        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    joinIn(this.ruleForm).then(res => {
                        console.log(res)
                        if (res.code == 0) {
                            this.$message({
                                message: '提交成功，等待审核!',
                                type: 'success'
                            })
                            this.$router.replace('/joinOus')
                        }

                    })
                    // alert('submit!');
                    // console.log(this.ruleForm, '数据');
                } else {
                    console.log('error submit!!');
                    // return false;
                }
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.el-form-item__error {
    margin-left: 4rem !important;
}

.content {
    padding-top: 0.5rem;
}

.bgColor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fafafa;
    z-index: -1;
}

.box {
    width: 16.27rem;
    border-radius: 0.43rem;
    opacity: 1;
    border: 0.04rem solid #ffffff;
    background: #ffffff;
    box-shadow: 0 0.2rem 0.4rem 0 #19c2d11a;
    margin: 0.52rem auto 0;

    .boxTit {
        width: 16.12rem;
        height: 0.7rem;
        border-radius: 0.32rem 0.32rem 0 0;
        opacity: 1;
        background: linear-gradient(90deg, #00903d 0%, #00903d00 100%);
        color: #ffffff;
        font-size: 0.32rem;
        font-weight: 700;
        font-family: "SourceHanSansCN";
        display: flex;
        align-items: center;
        padding-left: 0.26rem;
        margin-bottom: 0.2rem;
    }
    .contBbox{
        text-align: center;
        .contBboxLi{
            color: #009840;
			font-size: 0.3rem;
			font-weight: 400;
			font-family: "SourceHanSansCN";
			margin-top: 0.3rem;
        }
    }

    .boxLi {
        display: flex;
        align-items: center;
        margin-top: 0.3rem;
        padding-left: 0.35rem;

        .boxName {
            color: #253a70;
            font-size: 0.3rem;
            font-weight: 400;
            font-family: "SourceHanSansCN";
            width: 4rem;
            text-align: right;
            margin-right: 0.3rem;

            span {
                color: #f92800;
            }
        }

        .inpt {
            width: 7.63rem;
            height: 0.64rem;
            border-radius: 0.16rem;
            opacity: 1;
            border: 0.01rem solid #ced9f2;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 0.22rem;
            box-sizing: border-box;
        }

        input {
            color: #253a70;
            font-size: 0.3rem;
            font-weight: 400;
            font-family: "SourceHanSansCN";
        }

        input::placeholder {
            color: #B8C2D9;
        }

        .el-icon-arrow-right {
            font-size: 0.3rem;
        }


    }
}

.btnBoxBig {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.88rem;
}

.btnBox {
    width: 6.62rem;
    height: 0.98rem;
    border-radius: 1rem;
    background: linear-gradient(-41.4deg, #00903d 0%, #00b34a 100%);
    color: #ffffff;
    font-size: 0.32rem;
    font-weight: 500;
    font-family: "SourceHanSansCN";
    text-align: center;
}

.boxImage {
    display: flex;
    margin-top: 0.3rem;
    padding-left: 0.35rem;
    flex-wrap: wrap;

    .imagePerBoox {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        flex: 1;
        justify-content: space-between;
    }

    .imagePerBoox::after {
        content: '';
        width: 3rem;
    }

    .imagePer {
        width: 3rem;
        height: 3rem;
        border-radius: 0.1rem;
        border: 0.01rem solid #dedede;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0.2rem;
        position: relative !important;
        .boxClose{
            width: 0.5rem;
            height: 0.5rem;
            position: absolute;
            top: -0.1rem;
            right: -0.1rem;
            img{
                width: 100%;
                height: 100%;
            }
        }

        .el-icon-plus {
            font-size: 1rem;
            color: #dedede;
        }

        img {
            width: 100%;
            height: 100%;
            border-radius: 0.1rem;

        }
    }


    .boxName {
        color: #253a70;
        font-size: 0.3rem;
        font-weight: 400;
        font-family: "SourceHanSansCN";
        width: 4rem;
        text-align: right;
        margin-right: 0.3rem;

        span {
            color: #f92800;

        }
    }
}

.agreeBox {
    font-size: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.5rem;

    .agreeQuan {
        width: 0.3rem;
        height: 0.3rem;
        border-radius: 50%;
        border: 0.02rem solid #253a70;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0.1rem;

        .el-icon-check {
            font-size: 0.3rem;
            font-weight: 400;
            font-family: "SourceHanSansCN";
            color: #ffffff;
        }
    }

    .agreeQuanA {
        background: #009A41;
    }
}
</style>