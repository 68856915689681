<template>
    <div class="content">
        <div class="bgColor"></div>
        <HeaderTop :parentData="dataNum" :background="background" :color="color" :activeColor="activeColor"
            :position="position"></HeaderTop>
        <div class="contBox">
                <div class="contLi" @click="toUrl('/seedList',item.id,item.title)" v-for="(item,index) in list" :key="index">
                <img :src="item.pic"
                    alt="aspectFill">
                <div class="contName">
                    <div class="contNameText">
                        {{item.title}}
                    </div>
                </div>
            </div>
           
            <!-- <div class="contLi">
                <img src="https://lzdbuck.oss-cn-beijing.aliyuncs.com/20240822/3870ce7d71b7415f8bf0e322efd53267.jpg"
                    alt="">

                <div class="contName">
                    <div class="contNameText">
                        农药
                    </div>
                </div>
            </div>
            <div class="contLi">
                <img src="https://lzdbuck.oss-cn-beijing.aliyuncs.com/20240822/3870ce7d71b7415f8bf0e322efd53267.jpg"
                    alt="">

                <div class="contName">
                    <div class="contNameText">
                        化肥
                    </div>
                </div>
            </div>
            <div class="contLi">
                <img src="https://lzdbuck.oss-cn-beijing.aliyuncs.com/20240822/3870ce7d71b7415f8bf0e322efd53267.jpg"
                    alt="">

                <div class="contName">
                    <div class="contNameText">
                        农机
                    </div>
                </div>
            </div>
            <div class="contLi">
                <img src="https://lzdbuck.oss-cn-beijing.aliyuncs.com/20240822/3870ce7d71b7415f8bf0e322efd53267.jpg"
                    alt="">

                <div class="contName">
                    <div class="contNameText">
                        租地
                    </div>
                </div>
            </div>
            <div class="contLi">
                <img src="https://lzdbuck.oss-cn-beijing.aliyuncs.com/20240822/3870ce7d71b7415f8bf0e322efd53267.jpg"
                    alt="">

                <div class="contName">
                    <div class="contNameText">
                        收购
                    </div>
                </div>
            </div> -->
        </div>
        <!-- <FooterBottom></FooterBottom> -->

    </div>
</template>

<script>
import {categoryList} from '@/api/user/index.js'

export default {
    data() {
        return {
            dataNum: 4,
            background: "#ffffff",
            color: "#000000",
            activeColor: "#00943F",
            position: "fixed",
            list:[]
        }
    },
    created(){
        this.getInit()

    },
    methods:{
        toUrl(urlSrc,id,name){
            this.$router.push({path:urlSrc,query:{id:id,name:name}})
        },
        getInit(){
            categoryList().then(res=>{
            if (res.code == 0) {
                this.list = res.data
            }
            })
        }
       

    }
}
</script>

<style lang="scss" scoped>
.bgColor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fafafa;
    z-index: -1;
}

.contBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 16.27rem;
    border-radius: 0.31rem;
    opacity: 1;
    border: 0.01rem solid #eaeaea;
    background: #ffffff;
    padding: 0.63rem 0.49rem 0;
    box-sizing: border-box;
    margin: 1.2rem auto 0.5rem;

    .contLi {
        width: 7.33rem;
        height: 8.49rem;
        border-radius: 0.28rem;
        opacity: 1;
        position: relative;
        margin-bottom: 1.28rem;

        img {
            width: 7.33rem;
            height: 8.49rem;
            border-radius: 0.28rem;
            object-fit: cover;
	//若图片不能全部显示,加上下面这条调节两个值可以设置要显示图片的哪个部分
	// object-position: 5px 10%;
        }

        .contName {
            width: 100%;
            height: 100%;
            border-radius: 0.28rem;

            background: linear-gradient(180deg, #0d253c00 52%, #0d253c 100%);
            position: absolute;
            top: 0;
            left: 0;

            .contNameText {
                color: #ffffff;
                font-size: 0.6rem;
                font-weight: 400;
                font-family: "SourceHanSansCN";
                position: absolute;
                bottom: 0.71rem;
                left: 0.54rem;
                
            }
        }
    }
}
</style>