<template>
    <div class="content">
        <div class="bgColor"></div>
        <HeaderTop :parentData="dataNum" :background="background" :color="color" :activeColor="activeColor"
            :position="position"></HeaderTop>
        <div class="box">
            <div class="boxNameTit">
                {{name}}
            </div>
            <div class="boxCont">
                <div class="boxTit">
                    核验收货
                </div>
                <div class="boxContBox">
                    <div class="boxLi">
                        <div class="boxName"><span>*</span>验收地点</div>
                        <el-input v-model="from.reachPlace" placeholder="请输入验收地点" suffix-icon="el-icon-arrow-right"
                            style="width: 7.63rem;"></el-input>
                    </div>
                    <div class="boxLi">
                        <div class="boxName"><span>*</span>验收人员</div>
                        <el-input v-model="from.unloadWorker" placeholder="请输入验收人员" suffix-icon="el-icon-arrow-right"
                            style="width: 7.63rem;"></el-input>
                    </div>
                    <div class="boxLi">
                        <div class="boxName"><span>*</span>是否符合要求</div>
                        <el-radio v-model="from.checkStatus" label="1">符合</el-radio>
                        <el-radio v-model="from.checkStatus" label="2">不符合</el-radio>
                    </div>
                    <div class="boxLi" v-if="from.checkStatus=='2'">
                        <div class="boxName"><span>*</span>不符合原因</div>
                        <el-input v-model="from.checkResults" placeholder="请输入不符合原因" suffix-icon="el-icon-arrow-right"
                            style="width: 7.63rem;"></el-input>
                    </div>
                    <div class="boxLi" v-if="from.checkStatus=='2'">
                        <div class="boxName"><span>*</span>扣款</div>
                        <el-input v-model="from.deduct" placeholder="请输入扣款" suffix-icon="el-icon-arrow-right"
                            style="width: 7.63rem;"></el-input>
                    </div>
                    <div class="boxImage">
                        <div class="boxName"><span>*</span>验收单据</div>
                        <div class="imagePer" @click="triggerFileInput">
                            <input type="file" @change="handleFileChange" ref="fileInput" accept="image/*"
                                style="display: none;">
                            <img :src="from.unloadDoc" alt="" v-if="from.unloadDoc">
                            <div class="el-icon-plus" v-if="!from.unloadDoc"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btnBoxBig">
                    <button class="btnBox" @click="submitForm">确定提交</button>

                </div>
        </div>
    </div>
</template>

<script>
import {upload,lastCheck,orderDetails} from '@/api/user/index.js'
export default {
    data() {
        return {
            name:"",
            dataNum: 0,
            background: "#ffffff",
            color: "#000000",
            activeColor: "#00943F",
            position: "fixed",
            from: {
                id:"",
                reachPlace: "",//验收地点
                checkStatus:'1',//合格不合格
                unloadWorker:"",//验收人员
					unloadDoc:"",//验收单据
                    checkResults:"",//不合格原因
					deduct:""//扣款
            },
        }
    },
    created(){
        this.from.id = this.$route.query.id
        this.name = this.$route.query.name
        this.init()
    },
    methods: {
         // 详情数据
         init() {
            orderDetails({ orderId: this.from.id }).then(res => {
                if (res.code == 0) {
                    this.from.reachPlace = res.data.reachPlace
						this.from.unloadWorker = res.data.unloadWorker
						this.from.checkStatus = res.data.checkStatus==0?'1':String(res.data.checkStatus)
						this.from.checkResults = res.data.checkResults
						this.from.deduct = res.data.deduct
						this.from.unloadDoc = res.data.unloadDoc

                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning',
                        customClass: 'messageBoxTip'
                    })
                }
            })


        },
        submitForm(){
            if(!this.from.reachPlace){
					
                    this.$message({
          message: '请输入验收地点',
          type: 'warning'
        });
					return
				}
				if(!this.from.unloadWorker){
					
                    this.$message({
          message: '请输入验收人员',
          type: 'warning'
        });
					return
				}
				if(!this.from.checkStatus==2){
					if(!this.from.checkResults){
                        this.$message({
          message: '请输入不合格原因',
          type: 'warning'
        });
						
						return
					}
					if(!this.from.deduct){
						
                        this.$message({
          message: '请输入不合格扣款',
          type: 'warning'
        });
						return
					}
				}
				if(!this.from.unloadDoc){
                    this.$message({
          message: '请上传验收单据',
          type: 'warning'
        });
					
					return
				}
                lastCheck(this.from).then(res=>{
                    if (res.code == 0) {
						
                        this.$message({
          message: '提交成功',
          type: 'success'
        });
						setTimeout(()=>{
							this.$router.go(-1)
						},1500)
					} else {
						

                        this.$message({
          message: res.msg,
          type: 'warning'
        });
					}
                })
        },
        triggerFileInput() {
            this.$refs.fileInput.click();
            // 触发隐藏 input 的点击事件  

        },
        handleFileChange(e) {
            this.selectedFile = e.target.files[0];
            if (!this.selectedFile) {
                return;
            }
            const formData = new FormData();
            formData.append('file', this.selectedFile);
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            upload(formData).then(res => {
                loading.close();
                this.from.unloadDoc = res.data.src
            }).catch(error => {
                console.error('Error uploading image:', error);
                // 处理上传失败后的逻辑  
            });
           
        }

    }
}
</script>

<style lang="scss" scoped>
.content {
    padding-top: 0.8rem;
}

.bgColor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fafafa;
    z-index: -1;
}

.box {
    width: 16.27rem;
    margin: 0 auto;

    .boxNameTit {
        color: #000000;
        font-size: 0.36rem;
        font-weight: 700;
        font-family: "SourceHanSansCN";
        padding-left: 0.5rem;
    }

    .boxCont {
        width: 100%;
        // height: 10.64rem;
        border-radius: 0.32rem;
        opacity: 1;
        border: 0.04rem solid #ffffff;
        background: #ffffff;
        box-shadow: 0 0.2rem 0.4rem 0 #19c2d11a;
        margin-top: 0.2rem;
        padding-bottom: 0.5rem;
        .boxTit {
            width: 16.12rem;
            height: 0.7rem;
            opacity: 1;
            background: linear-gradient(90deg, #00903d 0%, #00903d00 100%);
            color: #ffffff;
            font-size: 0.32rem;
            font-weight: 700;
            font-family: "SourceHanSansCN";
            border-radius: 0.32rem 0 0 0;
            display: flex;
            align-items: center;
            padding-left: 0.26rem;

        }

        .boxContBox {
            .boxLi {
        display: flex;
        align-items: center;
        margin-top: 0.3rem;
        padding-left: 0.35rem;

        .boxName {
            color: #253a70;
            font-size: 0.3rem;
            font-weight: 400;
            font-family: "SourceHanSansCN";
            width: 4rem;
            text-align: right;
            margin-right: 0.3rem;

            span {
                color: #f92800;
            }
        }

        .inpt {
            width: 7.63rem;
            height: 0.64rem;
            border-radius: 0.16rem;
            opacity: 1;
            border: 0.01rem solid #ced9f2;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 0.22rem;
            box-sizing: border-box;
        }

        input {
            color: #253a70;
            font-size: 0.3rem;
            font-weight: 400;
            font-family: "SourceHanSansCN";
        }

        input::placeholder {
            color: #B8C2D9;
        }

        .el-icon-arrow-right {
            font-size: 0.3rem;
        }


    }
    .boxImage {
    display: flex;
    margin-top: 0.3rem;
    padding-left: 0.35rem;

    .imagePer {
        width: 3rem;
        height: 3rem;
        border-radius: 0.1rem;
        border: 0.01rem solid #dedede;
        display: flex;
        align-items: center;
        justify-content: center;

        .el-icon-plus {
            font-size: 1rem;
            color: #dedede;
        }

        img {
            width: 100%;
            height: 100%;
            border-radius: 0.1rem;

        }
    }


    .boxName {
        color: #253a70;
        font-size: 0.3rem;
        font-weight: 400;
        font-family: "SourceHanSansCN";
        width: 4rem;
        text-align: right;
        margin-right: 0.3rem;

        span {
            color: #f92800;

        }
    }
}
        }
    }
}
.btnBoxBig {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.88rem;
}

.btnBox {
    width: 6.62rem;
    height: 0.98rem;
    border-radius: 1rem;
    background: linear-gradient(-41.4deg, #00903d 0%, #00b34a 100%);
    color: #ffffff;
    font-size: 0.32rem;
    font-weight: 500;
    font-family: "SourceHanSansCN";
    text-align: center;
}
</style>