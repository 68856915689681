<template>
    <div class="content">
        <div class="bgColor"></div>
        <HeaderTop :parentData="dataNum" :background="background" :color="color" :activeColor="activeColor"
            :position="position"></HeaderTop>
        <div class="chooseBig">
            <div class="chooseTab">
            <div class="chooseTabLi" style=" margin-left: -1.2rem;" :class="tabNum == 1 ? 'chooseTabLiA' : ''" @click="shoose(1)">商品收藏</div>
            <div class="chooseTabLi" :class="tabNum == 2 ? 'chooseTabLiA' : ''" @click="shoose(2)">企业收藏</div>
        </div>
        </div>
        
        <div class="shopBox" v-if="tabNum == 1">
            <div class="shopLi" v-for="(item,index) in dataList" :key="index" @click="toUrl('/shopXq', item.product.id)">
            <div class="shopImg">
                <img :src="item.product.pic" alt="">
            </div>
            <div class="shopCont">
                <div class="shopName">
                    {{item.product.name}}
                </div>
                <div class="shopPrice">
                    ¥<span>{{item.product.price}}</span>/元
                </div>
                <div class="shopBtn">查看详情</div>
            </div>
        </div>
        </div>
        <div class="companyBox" v-if="tabNum == 2">
          <div class="companyLi" v-for="(item,index) in dataList" :key="index" @click="toUrl('/companyXq', item.company.id)">
              <div class="companyTop">
                  <div class="companyTag">
                      {{ item.company.briefName}}
                  </div>
                  <div class="companyName">{{ item.company.title}}</div>
              </div>
              <div class="companyMsg">
                <div class="companyMsgLi" style="margin-right: 0.74rem;">联系人：{{ item.company.legalName}}</div>
                <div class="companyMsgLi">联系电话：{{ item.company.telephone}}</div>
  
              </div>
              <div class="companyCont">
                {{ item.company.aboutUs}}
              </div>
              <div class="companyZy">主营：{{ item.company.majorBusiness}}</div>
              <div class="companyBtn">
                了解更多信息<span class="el-icon-arrow-right"></span>
              </div>
          </div>
      </div>
      <div style="display: flex;align-items: center;justify-content: flex-end;width: 16.27rem;margin: 0.5rem auto 0;">
        <el-pagination :current-page="listQuery.page" :page-sizes="[10]" :page-size="listQuery.limit" :total="listQuery.total" layout="total, sizes, prev, pager, next, jumper" @size-change="pageSizeChangeHandle" @current-change="pageCurrentChangeHandle"> </el-pagination>
       <!-- <pageIndex v-show="total>0" :total="total" :page.sync="listQuery.page" limit="10" @pagination="getInit" />-->
      </div>
    </div>
</template>

<script>

import {myCollect} from '@/api/user/index.js'

export default {
    data() {
        return {
            tabNum: 1,
            dataNum: 0,
        background: "#ffffff",
        color: "#000000",
        activeColor: "#00943F",
        position: "fixed",
        dataList:[],
      
        listQuery: {
          page: 1,
          limit: 10,
          total:""
        }
        }
    },
    created(){
  this.listQuery.page = 1
  this.dataList = []
  this.getInit()
},
    methods: {
        pageSizeChangeHandle(val){
      this.listQuery.page=1
      this.listQuery.limit = Number(val);
      this.getInit()
    },
    pageCurrentChangeHandle(val){
      this.listQuery.page=Number(val)
      this.getInit()
    },
    getInit(){
        myCollect({type:this.tabNum,page:this.listQuery.page,limit:this.listQuery.limit}).then(res=>{
        if (res.code == 0) {
          this.listQuery.total = Number(res.data.total)
          this.dataList = res.data.records
        }
      })
    },
        shoose(num){
this.tabNum = num
this.listQuery.page = 1
  this.dataList = []
  this.getInit()
        },
        toUrl(urlSrc,id){
            this.$router.push({ path: urlSrc, query: { id: id } })
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    padding-top: 0.4rem;
}

.bgColor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fafafa;
    z-index: -1;
}
.chooseBig{
    width: 100%;
    padding: 0.2rem;

    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fafafa;
    // position: fixed;
    // top:0.8rem;
    // left: 0;
}
.chooseTab {
    width: 16.27rem;
    height: 1.55rem;
    border-radius: 0.26rem;
    opacity: 1;
    border: 0.01rem solid #eaeaea;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 0;
   
    .chooseTabLi {
        color: #3c3c3c;
        font-size: 0.3rem;
        font-weight: 400;
        font-family: "SourceHanSansCN";
        margin-left: 1.2rem;
    }

    .chooseTabLiA {
        color: #1b1b1b;
        font-size: 0.32rem;
        font-weight: 700;
        font-family: "SourceHanSansCN";
        position: relative;
    }

    .chooseTabLiA::after {
        content: "";
        position: absolute;
        left: 35%;
        bottom: -0.2rem;
        right: 35%;
        flex: 1;
        background: #239F59;
        width:  0.48rem;
        height:0.09rem;
        border-radius: 0.08rem;
    }


}
.shopBox{
    width: 16.27rem;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.shopLi{
margin-top: 0.36rem;
    width: 8rem;
height: 2.58rem;
border-radius: 0.32rem;
opacity: 1;
background: #ffffff;
display: flex;
align-items: center;
padding: 0.3rem 0.27rem;
box-sizing: border-box;
    .shopImg{
        width: 2.02rem;
height: 2.02rem;
border-radius: 0.16rem;
opacity: 1;
        img{
width: 100%;
height: 100%;
border-radius: 0.16rem;
object-fit: cover;

        }
    }
    .shopCont{
        margin-left: 0.2rem;
        .shopName{
            color: #1b1b1b;
 font-size: 0.32rem;
 font-weight: 400;
 font-family: "SourceHanSansCN";
 overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

        }
        .shopPrice{
            color: #ff0000;
 font-weight: 500;
 font-size: 0.24rem;
 font-family: "SourceHanSansCN";
            span{
                font-weight: 700;
 font-size: 0.36rem;
            }
        }
        .shopBtn{
            width: 1.5rem;
height: 0.52rem;
border-radius: 0.6rem;
opacity: 1;
border: 0.02rem solid #239f59;
background: #ffffff;
color: #239f59;
 font-size: 0.24rem;
 font-weight: 400;
 font-family: "SourceHanSansCN";
 line-height: 0.52rem;
 text-align: center;
 margin-top: 0.3rem;
        }
    }
}
.companyBox{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0.5rem 1.37rem 0.01rem;
    box-sizing: border-box;
    .companyLi{
        width: 7.79rem;
      height: 4.92rem;
  border-radius: 0.31rem;
  opacity: 1;
  border: 0.01rem solid #eaeaea;
  background: #ffffff;
//   padding: 0.31rem;
//   box-sizing: border-box;
  margin-bottom: 1.02rem;
      .companyTop{
        display: flex;
        align-items: center;
        background: linear-gradient(180deg, #FFFAD9 0%, #ffbe0000 100%);
  border-radius: 0.31rem 0.31rem 0 0;

          padding:0.27rem 0.31rem;
  box-sizing: border-box;
        .companyTag{
          width: 0.39rem;
  height: 0.41rem;
  border-radius: 0.1rem;
  opacity: 1;
//   border: 0.01rem solid #ffbe00;
  background: #ffbe00;
  color: #ffffff;
   font-size: 0.14rem;
   font-weight: 400;
   font-family: "SourceHanSansCN";
   text-align: left;
   line-height: 0.16rem;
   text-align: center;
   display: flex;
   align-items: center;
        }
        .companyName{
          color: #333333;
   font-size: 0.3rem;
   font-weight: 500;
   font-family: "SourceHanSansCN";
   margin-left: 0.14rem;
        }
      }
      .companyMsg{
        display: flex;
        align-items: center;
        margin-top: 0.19rem;
        padding:0 0.31rem;
        box-sizing: border-box;
        .companyMsgLi{
          color: #555555;
   font-size: 0.26rem;
   font-weight: 400;
   font-family: "SourceHanSansCN";
        }
      }
      .companyCont{
        padding:0 0.31rem;
        box-sizing: border-box;
        color: #33333380;
   font-size: 0.28rem;
   font-weight: 400;
   font-family: "SourceHanSansCN";
   display: -webkit-box; /* 显示多行文本容器 */
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /*显示行数*/
    overflow: hidden; /*隐藏多出部分文字*/
    text-overflow: ellipsis; /*用省略号代替多出部分文字*/
   margin-top: 0.41rem;
      }
      .companyZy{
        color: #333333cc;
   font-size: 0.24rem;
   font-weight: 400;
   font-family: "SourceHanSansCN";
   margin-top: 0.24rem;
   padding:0 0.31rem;
   box-sizing: border-box;
   overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
      }
      .companyBtn{
       margin-left:0.31rem;
        box-sizing: border-box;
        width: 1.5rem;
          height: 0.5rem;
          border-radius: 1rem;
          opacity: 1;
          background: linear-gradient(-41.4deg, #00903d 0%, #00b34a 100%);
          box-shadow: 0 0 0.16rem 0 #00b34a66;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          font-size: 0.13rem;
          font-weight: 400;
          font-family: "SourceHanSansCN";
          text-align: center;
          margin-top: 0.79rem;
      }
    }
    .companyLi:nth-child(4n+1){
        .companyTop{
            background: linear-gradient(180deg, #FFFAD9 0%, #ffbe0000 100%);
            .companyTag{
                background: #ffbe00;
                color: #ffffff;
            }
        }
    }
    .companyLi:nth-child(4n+2){
        .companyTop{
            background: linear-gradient(180deg, #E2E8FF 0%, #ffbe0000 100%);
            .companyTag{
                background: #5E7EFE;
            }
        }
    }
    .companyLi:nth-child(4n+3){
        .companyTop{
            background: linear-gradient(180deg, #FFE6D3 0%, #ffbe0000 100%);
            .companyTag{
                background: #FF8025;
            }
        }
    }
    .companyLi:nth-child(4n+4){
        .companyTop{
            background: linear-gradient(180deg, #DDFBFB 0%, #ffbe0000 100%);
            .companyTag{
                background: #5BEBE9;
            }
        }
    }
  }
</style>