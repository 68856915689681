<template>
    <div class="content">
        <div class="bgColor"></div>
        <HeaderTop :parentData="dataNum" :background="background" :color="color" :activeColor="activeColor"
            :position="position"></HeaderTop>
        <div class="header">
            <img src="@/assets/ghsqbdd.png" alt="">
        </div>
        <div class="chooseBig">
            <div class="chooseTab">
                <div class="chooseTabLi" :class="tabNum == 99 ? 'chooseTabLiA' : ''" @click="shoose(99)">全部</div>
                <div class="chooseTabLi" :class="tabNum == 0 ? 'chooseTabLiA' : ''" @click="shoose(0)">待配车</div>
                <div class="chooseTabLi" :class="tabNum == 1 ? 'chooseTabLiA' : ''" @click="shoose(1)">装车中</div>
                <div class="chooseTabLi" :class="tabNum == 2 ? 'chooseTabLiA' : ''" @click="shoose(2)">待结算</div>
                <div class="chooseTabLi" :class="tabNum == 3 ? 'chooseTabLiA' : ''" @click="shoose(3)">已完成</div>
                <div class="chooseTabLi" :class="tabNum == 10 ? 'chooseTabLiA' : ''" @click="shoose(10)">售后</div>
            </div>
        </div>
        <div class="box">
            <div class="boxLi" v-for="(item, index) in dataList" :key="index" @click="toUrl('/driveXq',item.id)">
                <div class="boxTop">
                    <div class="boxOrder">
                        {{ item.orderCode }}
                    </div>
                    <div class="boxStatus" v-if="(item.status>=4&&item.status<=7)&&item.payStateTwo==0"
                        style="border: 0.01rem solid #F0153F;background: #f0153f1a;color: #F0153F;">
                        待结算
                    </div>
                    <div class="boxStatus"  v-if="item.status>=0&&item.status<=1"
                        style="border: 0.01rem solid #FF6633;background: #fff0eb;color: #FF6633;">
                        待配车
                    </div>
                    <div class="boxStatus" v-if="item.status>=2&&item.status<=3"
                        style="border: 0.01rem solid #339DFF;background: #339dff1a;color: #339DFF;">
                        装车中
                    </div>
                    <div class="boxStatus" v-if="(item.status>=4&&item.status<=8)&&item.payStateTwo==1">
                        已完成
                    </div>
                    <div class="boxStatus" v-if="item.status == 10"
                        style="border: 0.01rem solid #2B62FD;background: #e8eeff;color: #2B62FD;">
                        售后
                    </div>
                </div>
                <div class="boxCont">
                    <div class="boxName">
                        {{ item.orderResult.productName }}
                    </div>
                    <div class="boxContLi" v-if="item.loadNetWeight">
                        <div class="boxNum">
                            装车数量：<span>{{ item.loadNetWeight }}kg</span>
                        </div>
                        <!-- <div class="boxPrice">
                            ¥<span>{{item.price}}/斤</span>
                        </div> -->
                    </div>
                    <div class="boxContLi" style="margin-top: 0.5rem;">
                        <div class="boxNum">
                            粮坊(采购商)：<span>{{ item.orderResult.companyA }}</span>
                        </div>
                    </div>
                    <div class="boxContLi">
                        <div style="display: flex;align-items: center;">
                            <div class="boxNum">
                                粮运(司机)：<span>{{ item.driverName }}</span>
                            </div>
                            <div class="boxNum" style="margin-left: 0.5rem;">
                                电话：<span>{{ item.driverMobile }}</span>
                            </div>
                        </div>
                        <div class="boxBtn" v-if="item.status>=0&&item.status<=3">
                            <div class="boxBtnLi" @click.stop="toUrl('/hyzcgbInpt',item.id,item.orderResult.productName,1)">
                                <img src="@/assets/ddhtbtn.png" alt="">
                                核验
                            </div>
                            <div class="boxBtnLi" @click.stop="toUrl('/hyzcgbInpt',item.id,item.orderResult.productName,2)">
                                <img src="@/assets/ddhtbtn.png" alt="">
                                装车
                            </div>
                            <div class="boxBtnLi" @click.stop="toUrl('/hyzcgbInpt',item.id,item.orderResult.productName,3)">
                                <img src="@/assets/ddhtbtn.png" alt="">
                                过磅
                            </div>
                            <div class="boxBtnLi" @click.stop="truckFinish(item.id)">
                                <img src="@/assets/ddhtbtn.png" alt="">
                                装车完成
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="display: flex;align-items: center;justify-content: flex-end;width: 14.29rem;margin: 0.5rem auto;">
            <el-pagination :current-page="listQuery.page" :page-sizes="[10]" :page-size="listQuery.limit"
                :total="listQuery.total" layout="total, sizes, prev, pager, next, jumper"
                @size-change="pageSizeChangeHandle" @current-change="pageCurrentChangeHandle"> </el-pagination>
            <!-- <pageIndex v-show="total>0" :total="total" :page.sync="listQuery.page" limit="10" @pagination="getInit" />-->
        </div>
    </div>
</template>

<script>

import { orderList,delivery } from '@/api/user/index.js'
export default {
    data() {
        return {
            dataNum: 0,
            background: "#ffffff",
            color: "#000000",
            activeColor: "#00943F",
            position: "fixed",
            tabNum: 99,
            listQuery: {
                page: 1,
                limit: 10,
                total: ""
            },
            dataList: [],
            userInfo: JSON.parse(localStorage.getItem('userInfo'))
        }
    },
    created() {
        this.listQuery.page = 1
        this.dataList = []
        this.getInit()
    },
    methods: {
        truckFinish(id){
            delivery({id:id}).then(res=>{
                if(res.code==0){
                    this.$message({
                        message: '发货成功',
                        type: 'success',
                        customClass:'messageBoxTip'
                    })
                    this.listQuery.page = 1
                    this.dataList = []
                    this.getInit()
                }else{
                    this.$message({
                        message: res.msg,
                        type: 'warning',
                        customClass:'messageBoxTip'
                    })
                }
            })
        },
        toUrl(urlSrc, id,name,type) {
            if(urlSrc=='/hyzcgbInpt'){
                this.$router.push({path:urlSrc,query:{id:id,name:name,type:type}})
                return
            }
            if(urlSrc=='/ysSh'||urlSrc=='/payPz'){
                this.$router.push({path:urlSrc,query:{id:id,name:name}})

            }else{
                this.$router.push({path:urlSrc,query:{id:id}})

            }
        },

        pageSizeChangeHandle(val) {
            this.listQuery.page = 1
            this.listQuery.limit = Number(val);
            this.getInit()
        },
        pageCurrentChangeHandle(val) {
            this.listQuery.page = Number(val)
            this.getInit()
        },
        getInit() {
            orderList({ status: this.tabNum, page: this.listQuery.page, limit: this.listQuery.limit }).then(res => {
                if (res.code == 0) {
                    this.listQuery.total = Number(res.data.total)
                    this.dataList = res.data.records
                }
            })
        },
        shoose(num) {
            this.tabNum = num
            this.listQuery.page = 1
            this.dataList = []
            this.getInit()
        }
    }
}
</script>

<style lang="scss" scoped>
.bgColor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fafafa;
    z-index: -1;
}

.header {
    width: 100%;
    height: 5rem;

    img {
        width: 100%;
        height: 100%;
    }
}

.chooseBig {
    width: 100%;
    padding: 0.2rem;

    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fafafa;
    // position: fixed;
    // top:0.8rem;
    // left: 0;
}

.chooseTab {
    width: 14.29rem;

    height: 1.55rem;
    border-radius: 0.26rem;
    opacity: 1;
    border: 0.01rem solid #eaeaea;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 0 auto 0;
    padding: 0 2rem;
    box-sizing: border-box;

    .chooseTabLi {
        color: #3c3c3c;
        font-size: 0.3rem;
        font-weight: 400;
        font-family: "SourceHanSansCN";
    }

    .chooseTabLiA {
        color: #1b1b1b;
        font-size: 0.32rem;
        font-weight: 700;
        font-family: "SourceHanSansCN";
        position: relative;
    }

    .chooseTabLiA::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -0.2rem;
        // right: 10%;
        background: #239F59;
        width: 100%;
        height: 0.09rem;
        border-radius: 0.08rem;

    }


}

.box {
    .boxLi {
        width: 14.29rem;
        height: 3.97rem;
        border-radius: 0.32rem;
        opacity: 1;
        background: #ffffff;
        margin: 0 auto 0.3rem;

        .boxTop {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-right: 0.23rem;
            box-sizing: border-box;

            .boxOrder {
                width: 4.3rem;
                height: 0.7rem;
                border-radius: 0.32rem 0 0 0;
                opacity: 1;
                background: linear-gradient(90deg, rgba(47, 165, 99, 0.2) 0%, #2fa56300 100%);
                color: #343231;
                font-size: 0.32rem;
                font-weight: 700;
                font-family: "SourceHanSansCN";
                display: flex;
                align-items: center;
                padding-left: 0.3rem;
            }

            .boxStatus {
                width: 0.9rem;
                height: 0.38rem;
                border-radius: 0.19rem;
                opacity: 1;
                border: 0.01rem solid #5dc827;
                background: #5ac7251a;
                color: #5dc827;
                font-size: 0.22rem;
                font-weight: 400;
                font-family: "SourceHanSansCN";
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .boxCont {
            padding: 0.27rem 0.23rem 0.5rem 0.3rem;
            box-sizing: border-box;

            .boxName {
                color: #343231;
                font-size: 0.3rem;
                font-weight: 400;
                font-family: "SourceHanSansCN";
            }

            .boxContLi {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .boxNum {
                    color: #34323166;
                    font-size: 0.26rem;
                    font-weight: 400;
                    font-family: "SourceHanSansCN";

                    span {
                        color: #343231;
                    }
                }

                .boxPrice {

                    color: #ff0000;
                    font-weight: 500;
                    text-align: left;
                    font-size: 0.24rem;
                    font-family: "SourceHanSansCN";

                    span {
                        color: #ff0000;
                        font-weight: 700;
                        font-size: 0.36rem;
                        font-family: "SourceHanSansCN";
                    }
                }

                .boxBtn {
                    display: flex;
                    align-items: center;

                    .boxBtnLi {
                        margin-left: 0.2rem;
                        min-width: 1.84rem;
                        padding: 0 0.2rem;
                        box-sizing: border-box;
                        height: 0.7rem;
                        border-radius: 0.16rem;
                        opacity: 1;
                        border: 0.01rem solid #eff8f7;
                        background: #edfffd;
                        color: #2fa563;
                        font-size: 0.26rem;
                        font-weight: 400;
                        font-family: "SourceHanSansCN";
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            width: 0.32rem;
                            height: 0.32rem;
                            margin-right: 0.06rem;
                        }
                    }
                }
            }
        }
    }
}
</style>