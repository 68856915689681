<template>
  <div class="content">
    <div class="footer">
      <div class="footerLeft">
        <div class="footerDisplay">
          <img :src="msgData.company.logo" alt="">
          <!-- <div class="footerName">{{msgData.company.name}}</div> -->
        </div>
        <div class="footerDisplay" style="margin-top: 0.35rem;">
          <div class="footerDisplayLi">
            <div class="footerDisplayLiName">咨询电话</div>
            <div class="footerDisplayLiFu">{{msgData.company.telephone}}</div>
          </div>
          <div class="footerDisplayLi">
            <div class="footerDisplayLiName">客服电话</div>
            <div class="footerDisplayLiFu">{{msgData.company.mobile}}</div>
          </div>
          <div class="footerDisplayLi">
            <div class="footerDisplayLiName">商务合作</div>
            <div class="footerDisplayLiFu">{{msgData.company.email}}</div>
          </div>
          <div class="footerDisplayLi">
            <div class="footerDisplayLiName">联系地址</div>
            <div class="footerDisplayLiFu">{{msgData.company.address}}</div>
          </div>
        </div>
        <div class="bah">
          Copyright © 2020-2023 Contemporary Amperex Technology Co., Limited. All Rights Reserved. <a href="https://beian.miit.gov.cn/" style="color: #ffffff;text-decoration: none;">苏ICP备2024089866号 苏公网安备</a><img src="@/assets/jh.png" alt="">2024089866号
        </div>
      </div>
      <div class="footerRight">
        <img :src="msgData.company.code1" alt="">
        <img :src="msgData.company.code2" alt="">
        <img :src="msgData.company.code3" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import {getWeb} from '@/api/user/index.js'

export default {
data(){
  return{
    msgData:{
      company:{}
    }
  }
},
created(){
this.wznr()
},
methods:{
  wznr(){
      getWeb().then(res=>{
        this.msgData = res.data
        localStorage.setItem('webMsg',JSON.stringify(res.data))

      })

    },
}
}
</script>

<style lang="scss" scoped>
.footer{
  width: 100%;
  height: 2.96rem;
  background: url('@/assets/footer.png') no-repeat center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // position: fixed;
  // bottom: 0;
  // left: 0;
  .footerLeft{
    .footerDisplay{
      display: flex;
      align-items: center;
      img{
//         width: 0.42rem;
// height: 0.4rem;
// width: 0.55rem;
//       height: 0.7rem;
width: 0.65rem;
height: 0.8rem;
opacity: 1;
      }
      .footerName{
        color: #ffffff;
 font-size: 0.36rem;
 font-weight: 500;
    font-family: 'mianfeiziti';
 margin-left: 0.05rem;
      }
      .footerDisplayLi{
        margin-right: 0.8rem;
        .footerDisplayLiName{
          color: #ffffff;
 font-size: 0.16rem;
 font-weight: 400;
 font-family: "SourceHanSansCN";
        }
        .footerDisplayLiFu{
          color: #999999;
 font-size: 0.12rem;
 font-weight: 400;
 font-family: "SourceHanSansCN";
 margin-top: 0.13rem;
        }
      }
    }
    .bah{
      color: #ffffff;
 font-size: 0.14rem;
 font-weight: 400;
 font-family: "SourceHanSansCN";
 margin-top: 0.54rem;
 display: flex;
 align-items: center;
    }
  }
  .footerRight{
    img{
      width: 0.99rem;
      height: 0.99rem;
      margin-left: 0.21rem;
    }
  }
}

</style>