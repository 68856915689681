<template>
  <div class="content">
    <div class="box" :style="{background:background,position:position}" >
      <div class="boxLeft" :style="{color:activeColor}">
        <img :src="msgData.company.logo" alt="" />
        <!-- {{msgData.company.name}} -->
      </div>
      <div style="width: 3rem"></div>
      <div class="boxRight">
        <div class="boxRightNav">
          <div class="boxRightNavLi" :style="{color: parentData==0? activeColor :color}" @click="toUrl('/',0)" >首页</div>
          <div class="boxRightNavLi" :style="{color: parentData==1? activeColor :color}" @click="toUrl('/grainMarket',1)" >粮市</div>
          <div class="boxRightNavLi" :style="{color: parentData==2? activeColor :color}" @click="toUrl('/grainMill',2)">粮坊</div>
          <div class="boxRightNavLi" :style="{color: parentData==3? activeColor :color}" @click="toUrl('/grainElevator',3)">粮仓</div>
          <div class="boxRightNavLi" :style="{color: parentData==4? activeColor :color}" @click="toUrl('/grainFarmer',4)">粮耕</div>
          <div class="boxRightNavLi" :style="{color: parentData==5? activeColor :color}" @click="toUrl('/newPage',5)">粮讯</div>
          <div class="boxRightNavLi" :style="{color: parentData==6? activeColor :color}" @click="toUrl('/joinOus',6)">粮道</div>
        </div>
        <div style="width: 1.4rem"></div>
        <div class="boxMsg" @click="logoGo" :style="{color:activeColor}">
          <img :src="userInfoMsg.headUrl" alt="" v-if="userInfoMsg.headUrl"/>
          <!-- <img src="@/assets/logo.png" alt="" v-if="!userInfoMsg.headUrl"/> -->
          {{userInfoMsg.username?userInfoMsg.username:'去登录'}}
          <div class="el-icon-caret-bottom" v-if="!menusFlag"></div>
          <div class="el-icon-caret-top" v-if="menusFlag"></div>
          <div class="popup" v-if="menusFlag">
            <div class="popupLi" @click="toUrl('/myFavorite',0)">我的收藏</div>
            <div class="popupLi" @click="toUrl('/collectionInformation',0)">收款信息</div>
            <div class="popupLi" @click="toUrl('/purchaserOrderAll',0)">全部订单</div>
            <div class="popupLi" @click="toUrl('/purchaserDb',0)" v-if="userInfoMsg.userType==1||userInfoMsg.userType==2||userInfoMsg.userType==3">待办订单</div>
            <div class="popupLi" @click="toUrl('/orderRelease',0)" v-if="userInfoMsg.userType==1||userInfoMsg.userType==2">发布采购需求</div>
            <div class="popupLi" @click="toUrl('/grainCoupon',0)" v-if="userInfoMsg.userType==1||userInfoMsg.userType==2||userInfoMsg.userType==3">
              <div>我的粮票</div>
              <div v-if="userInfoMsg.isCredit==1">{{userInfoMsg.amount}}</div>
            </div>
            <div class="popupLi" @click="toUrl('/shopList',0, 3)">新品推荐</div>
            <div class="popupLi" @click="goOut">退出登录</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {userInfo,getWeb,logout,index} from '@/api/user/index.js'
// import service from '@/utils/request';

export default {
  data() {
    return {
      menusFlag:false,
      userInfoMsg:{},
      msgData:{
      company:{},
      parentData:localStorage.getItem('indexNum')?localStorage.getItem('indexNum'):0
    }
    };
  },
  props: {
    parentData: {
      type: Number,
      default: 0
    },
    background:{
      type:String,
      default:"rgba($color: #000000, $alpha: 0.7)"
    },
    color:{
       type:String,
      default:"#cdcdcd"
    },
    activeColor:{
       type:String,
      default:"#ffffff"
    },
    position:{
       type:String,
      default:"fixed"
    }

  },
  created(){
    this.getInit()
    this.wznr()
    if(localStorage.getItem('token')){
      this.getUser()
    }
    
  },
  methods:{
    getInit() {
      index({ type: 0, page: 1, keyword: '' }).then(res => {
        localStorage.setItem('lunboList',JSON.stringify(res.data.carouselList.records))
        // this.initData = res.data
        // console.log(this.initData)
      })
    },
    wznr(){
      getWeb().then(res=>{
        this.msgData = res.data
        localStorage.setItem('webMsg',JSON.stringify(res.data))
        this.$emit('childMessage', res.data); 

      })
    },
    getUser(){
      userInfo().then(res=>{
        console.log(res)
        if(res.code==0){
          this.userInfoMsg = res.data
          localStorage.setItem('userInfo',JSON.stringify(res.data))
        }
      })
    },
    logoGo(){
      // this.$router.replace({name:'login'})
      if(localStorage.getItem('token')){
        this.menusFlag = !this.menusFlag
      }else{
        this.$router.replace({name:'login'})
      }
      
    },
    toUrl(num,index,id){
      localStorage.setItem('indexNum',index)
      if(num=='/purchaserOrderAll'){
        if(this.userInfoMsg.userType==3){
          this.$router.push('/supplierOrderAll')
        }else if(this.userInfoMsg.userType==4){
          this.$router.push('/driveList')

        }else{
          this.$router.push(num)
        }
        
        return
      }
      console.log(num)
      console.log(this.userInfoMsg.isCredit)

      if(num=='/grainCoupon'){
        if(this.userInfoMsg.isCredit==1){
          this.$router.push(num)
        }else{
          this.$router.push({path:'/foodCoupon',query:{index:this.userInfoMsg.isCredit}})
        }
        return
      }
      if(num=='/shopList'){
        this.$router.push({ path: num, query: { type: id } })
        return
      }
      this.$router.push(num)
    },
    // goOut(){
      
    // },
    goOut(){
      // localStorage.clear()
      // this.$router.replace('/login')
      logout('logout').then(res=>{
        console.log(res)
        if (res.code == 0) {
						localStorage.clear()
            this.$router.replace('/login')
					} else {
						this.$message({
                message: res.msg,
                type: 'warning'
            })
					}
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 0.8rem;
  background: rgba($color: #000000, $alpha: 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  .boxLeft {
    display: flex;
    align-items: center;
    color: #ffffff;
    font-size: 0.28rem;
    font-weight: 500;
    font-family: 'mianfeiziti';
    img {
      // width: 0.42rem;
      // height: 0.4rem;
      // width: 0.55rem;
      // height: 0.7rem;
      width: 0.65rem;
      height: 0.8rem;
      margin-right: 0.09rem;
    }
  }

  .boxRight {
    display: flex;
    align-items: center;

    .boxRightNav {
      display: flex;
      align-items: center;
      width: 4.7rem;
      justify-content: space-between;

      .boxRightNavLi {
        color: #cdcdcd;
        font-size: 0.18rem;
        font-weight: 700;
        font-family: "SourceHanSansCN";
      }
      .boxRightNavLiA{
        color: #ffffff;
      }
    }

    .boxMsg {
      display: flex;
      align-items: center;
      color: #ffffff;
      font-size: 0.18rem;
      font-weight: 700;
      font-family: "SourceHanSansCN";
      position: relative;

      img {
        width: 0.38rem;
        height: 0.38rem;
        margin-right: 0.1rem;
        border-radius: 50%;
      }

      .el-icon-caret-bottom {
        margin-left: 0.2rem;
        font-size: 0.25rem;
      }
      .el-icon-caret-top{
        margin-left: 0.2rem;
        font-size: 0.25rem;
      }

      .popup {
        position: absolute;
        top: 0.6rem;
        left: 0;
        width: 1.28rem;
        border-radius: 0.08rem;
        opacity: 1;
        background: #ffffff;
        box-shadow: 0 0.1rem 0.3rem 0 #bcc9e380;
        padding: 0.1rem 0;

        .popupLi {
          color: #798cb5;
          font-size: 0.14rem;
          font-weight: 400;
          font-family: "SourceHanSansCN";
          text-align: center;
          width: 1.1034rem;
          min-height: 0.28rem;
          border-radius: 0.04rem;
          line-height: 0.28rem;
          margin: 0 auto 0.08rem;
        }

        .popupLi:hover {
          background: #f3f4fc;
        }
      }
    }
  }
}
</style>
