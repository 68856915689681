<template>
    <div class="content">
        <div class="bgColor"></div>
        <HeaderTop :parentData="dataNum" :background="background" :color="color" :activeColor="activeColor"
            :position="position" @childMessage="handleChildMessage"></HeaderTop>
        <div class="banner">
            <img src="../assets/jrwmBg.png" alt="">
        </div>
        <div class="contBox">
      <div class="contMsg">
        <div class="contImg">
          <img :src="dataMsg.company.pic" alt="">
        </div>
        <div class="contText">
          <div class="contTit">{{ dataMsg.company.title }}</div>
          <div class="contfu">
            {{ dataMsg.company.introduce }}
          </div>
          <div class="contBtn" @click="toUrl('/aboutUs')">集团详情<img src="../assets/righticon.png" alt=""></div>
        </div>
      </div>
      <div class="contDisplay">
        <div class="contDisplayLi">
          <div class="contDisplayNum">{{ dataMsg.navbar.num1 }}<span>{{ dataMsg.navbar.dw1 }}</span></div>
          <div class="contDisplayName">{{ dataMsg.navbar.text1 }}</div>
        </div>
        <div class="contDisplayLine"></div>
        <div class="contDisplayLi">
          <div class="contDisplayNum">{{ dataMsg.navbar.num2 }}<span>{{ dataMsg.navbar.dw2 }}</span></div>
          <div class="contDisplayName">{{ dataMsg.navbar.text2 }}</div>
        </div>
        <div class="contDisplayLine"></div>

        <div class="contDisplayLi">
          <div class="contDisplayNum">{{ dataMsg.navbar.num3 }}<span>{{ dataMsg.navbar.dw3 }}</span></div>
          <div class="contDisplayName">{{ dataMsg.navbar.text3 }}</div>
        </div>
        <div class="contDisplayLine"></div>

        <div class="contDisplayLi">
          <div class="contDisplayNum">{{ dataMsg.navbar.num4 }}<span>{{ dataMsg.navbar.dw4 }}</span></div>
          <div class="contDisplayName">{{ dataMsg.navbar.text4 }}</div>
        </div>
      </div>
    </div>
    <div class="chooseBox">
      <div class="chooseTit">
        为什么选择我们？
      </div>
      <div class="chooseFu">
        独特地利用最佳对齐实现直观的带宽。快速协调电子商务<br />
        通过革命性的催化剂应用。

      </div>
      <div class="chooseCont">
        <div class="chooseContLi">
          <img :src="dataMsg.choose.pic1" alt="">
          <div class="chooseContName">
            {{ dataMsg.choose.title1 }}
          </div>
          <div class="chooseContTip">
            {{ dataMsg.choose.describe1 }}
          </div>
          <div class="chooseContBtn" @click="wenben(dataMsg.choose, 1)">
            了解更多信息<span class="el-icon-arrow-right"></span>
          </div>
        </div>
        <div class="chooseContLi">
          <img :src="dataMsg.choose.pic2" alt="">
          <div class="chooseContName">
            {{ dataMsg.choose.title2 }}
          </div>
          <div class="chooseContTip">
            {{ dataMsg.choose.describe2 }}
          </div>
          <div class="chooseContBtn" @click="wenben(dataMsg.choose, 2)">
            了解更多信息<span class="el-icon-arrow-right"></span>
          </div>
        </div>
        <div class="chooseContLi">
          <img :src="dataMsg.choose.pic3" alt="">
          <div class="chooseContName">
            {{ dataMsg.choose.title3 }}
          </div>
          <div class="chooseContTip">
            {{ dataMsg.choose.describe3 }}
          </div>
          <div class="chooseContBtn" @click="wenben(dataMsg.choose, 3)">
            了解更多信息<span class="el-icon-arrow-right"></span>
          </div>
        </div>
      </div>
    </div>
        <div class="tabBox">
            <div class="tabLi" @click="toUrl('/manuFacturer')">
                <img src="../assets/cpzzs.png" alt="">
                <div class="tabName">成为粮市(成品制造商)</div>
                <div class="tabBtn">欢迎加入</div>
            </div>
            <div class="tabLi" @click="toUrl('/purchasingAgent')">
                <img src="../assets/cgs.png" alt="">
                <div class="tabName">成为粮坊(采购商)</div>
                <div class="tabBtn">欢迎加入</div>
            </div>
            <div class="tabLi" @click="toUrl('/supplierRz')">
                <img src="../assets/gys.png" alt="">
                <div class="tabName">成为粮仓(供应商)</div>
                <div class="tabBtn">欢迎加入</div>
            </div>
            <div class="tabLi" @click="toUrl('/driverRz')">
                <img src="../assets/sj.png" alt="" style="width: 1.44rem;height: 1.61rem;">
                <div class="tabName">成为粮运(司机)</div>
                <div class="tabBtn">欢迎加入</div>
            </div>
            <div class="tabLi" @click="toUrl('/')">
                <img src="../assets/yk.png" alt="">
                <div class="tabName">我是游客继续浏览</div>
                <div class="tabBtn">欢迎加入</div>
            </div>
        </div>

    </div>
</template>

<script>
import {userInfo} from '@/api/user/index.js'
export default {
    data() {
        return {
            dataNum: 6,
            background: "#ffffff",
            color: "#000000",
            activeColor: "#00943F",
            position: "fixed",
            userInfo:{},
            dataMsg:{
                company:{},
                choose:{},
                navbar:{}
            }
        }
    },
    created(){
        if(localStorage.getItem('token')){
      this.getUser()
    }
    },
    methods: {
        wenben(data, index) {
      localStorage.setItem('textCont', JSON.stringify(data))
      this.$router.push({ path: '/adoutXq', query: { type: index } })
    },
        handleChildMessage(msg){
    console.log(msg,651231)
    this.dataMsg = msg
  },
        getUser(){
      userInfo().then(res=>{
        console.log(res)
        if(res.code==0){
          this.userInfo = res.data
          localStorage.setItem('userInfo',JSON.stringify(res.data))

        }
      })
    },
        toUrl(urlSrc) {
            if(urlSrc=='/aboutUs'){
                this.$router.push(urlSrc)
                return
            }
            if(!localStorage.getItem('token')){
                this.$message({
          message: '请登录后操作！',
          type: 'warning'
        });
                return
            }
            // if(urlSrc=='/HelloWorld'){
            //     this.$router.push(urlSrc)
            // }else{
                if (this.userInfo.userType == 1) {
                    this.$message({
          message: '您已是粮市(成品制造商)！',
          type: 'warning'
        });
                return
            }
            if (this.userInfo.userType == 2) {
                this.$message({
          message: '您已是粮坊(采购商)！',
          type: 'warning'
        });
              
                return
            }
            if (this.userInfo.userType == 3) {
                this.$message({
          message: '您已是粮仓(供货商)！',
          type: 'warning'
        });
               
                return
            }

            if (this.userInfo.userType == 4) {
                this.$message({
          message: '您已是粮运(司机)！',
          type: 'warning'
        });
               
                return
            }
            this.$router.push(urlSrc)
            }
            
        }
    // }
}
</script>

<style lang="scss" scoped>
.content {
    padding-top: 0.4rem;
}

.bgColor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fafafa;
    z-index: -1;
}

.banner {
    width: 100%;
    height: 5rem;

    img {
        width: 100%;
        height: 100%;
    }
}

.tabBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 1.5rem;
    box-sizing: border-box;

    .tabLi {
        width: 4.73rem;
        height: 4.92rem;
        border-radius: 0.29rem;
        opacity: 1;
        border: 0.04rem solid #ffffff;
        background: #ffffff;
        box-shadow: 0 0.2rem 0.4rem 0 #19c2d11a;
        margin-bottom: 1.1rem;
        text-align: center;
        padding-top: 0.7rem;

        img {
            width: 1.62rem;
            height: 1.58rem;
        }

        .tabName {
            color: #333333;
            font-size: 0.48rem;
            font-weight: 500;
            font-family: "SourceHanSansCN";
            margin-top: 0.646rem;
            text-align: center;
        }

        .tabBtn {
            width: 1.58rem;
            height: 0.5rem;
            border-radius: 1rem;
            opacity: 1;
            background: linear-gradient(-41.4deg, #00903d 0%, #00b34a 100%);
            box-shadow: 0 0 0.16rem 0 #00b34a66;
            color: #ffffff;
            font-size: 0.18rem;
            font-weight: 400;
            font-family: "SourceHanSansCN";
            line-height: 0.5rem;
            margin: 0.36rem auto;
        }
    }
}

.tabBox::after {
    content: '';
    width: 4.73rem;
}
.contBox {
  width: 100%;
  height: 8.97rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: url('@/assets/jsBg.png') no-repeat center;
  background-size: 100% 100%;

  .contMsg {
    display: flex;
    align-items: center;

    .contImg {
      img {
        width: 5.63rem;
        height: 3.91rem;
        border-radius: 0 0.4rem 0 0.4rem;
        object-fit: cover;
      }
    }

    .contText {
      width: 5.55rem;
      margin-left: 0.56rem;

      .contTit {
        color: #000000;
        font-size: 0.45rem;
        font-weight: 500;
        font-family: "SourceHanSansCN";
      }

      .contfu {
        color: #666666;
        font-size: 0.16rem;
        font-weight: 400;
        font-family: "SourceHanSansCN";
        margin-top: 0.16rem;
      }

      .contBtn {
        width: 1.8rem;
        height: 0.5799rem;
        border-radius: 1.0356rem;
        opacity: 1;
        background: linear-gradient(-41.4deg, #00903d 0%, #00b34a 100%);
        box-shadow: 0 0 0.16rem 0 #00b34a66;
        color: #ffffff;
        font-size: 0.16rem;
        font-weight: 400;
        font-family: "SourceHanSansCN";
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0.5rem;

        img {
          width: 0.2568rem;
          height: 0.0603rem;
          margin-left: 0.08rem;
          object-fit: cover;
        }
      }
    }
  }

  .contDisplay {
    width: 12.09rem;
    height: 1.48rem;
    background: #ffffff;
    margin-top: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .contDisplayLi {
      padding: 0 0.35rem;
      box-sizing: border-box;

      .contDisplayNum {
        color: #00943f;
        font-size: 0.3rem;
        font-weight: 700;
        font-family: "SourceHanSansCN";

        span {
          font-size: 0.16rem;
          font-weight: 400;
          font-family: "SourceHanSansCN";
          margin-left: 0.04rem;
        }

      }

      .contDisplayName {
        color: #282828;
        font-size: 0.16rem;
        font-weight: 400;
        font-family: "SourceHanSansCN";
      }
    }

    .contDisplayLine {
      width: 0.01rem;
      height: 0.39rem;
      background: #F6F7F7;
    }
  }
}

.chooseBox {
  padding: 1.29rem 0 1rem;
  background: #fafafa;

  .chooseTit {
    color: #000000;
    font-size: 0.45rem;
    font-weight: 700;
    font-family: "SourceHanSansCN";
    text-align: center;
  }

  .chooseFu {
    color: #666666;
    font-size: 0.16rem;
    font-weight: 400;
    font-family: "SourceHanSansCN";
    text-align: center;
    margin-top: 0.12rem;
  }

  .chooseCont {
    display: flex;
    align-items: center;
    width: 11.70rem;
    justify-content: space-between;
    margin: 0.9rem auto 0;

    .chooseContLi {
      width: 3.70rem;
      height: 6.16rem;
      opacity: 1;
      background: #ffffff;

      img {
        width: 3.70rem;
        height: 3.30rem;
        object-fit: cover;
      }

      .chooseContName {
        color: #000000;
        font-size: 0.24rem;
        font-weight: 500;
        font-family: "SourceHanSansCN";
        text-align: center;
        margin-top: 0.36rem;
      }

      .chooseContTip {
        color: #aaaaaa;
        font-size: 0.15rem;
        font-weight: 400;
        font-family: "SourceHanSansCN";
        text-align: center;
        width: 2.24rem;
        margin: 0.18rem auto 0;
        display: -webkit-box;
        /* 显示多行文本容器 */
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        /*显示行数*/
        overflow: hidden;
        /*隐藏多出部分文字*/
        text-overflow: ellipsis;
        /*用省略号代替多出部分文字*/

      }

      .chooseContBtn {
        width: 1.5rem;
        height: 0.5rem;
        border-radius: 1rem;
        opacity: 1;
        background: linear-gradient(-41.4deg, #00903d 0%, #00b34a 100%);
        box-shadow: 0 0 0.16rem 0 #00b34a66;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-size: 0.13rem;
        font-weight: 400;
        font-family: "SourceHanSansCN";
        text-align: center;
        margin: 0.57rem auto 0;

      }
    }

    .chooseContLi:hover {
      box-shadow: 0.15rem 0.25rem 0.6rem -0.05rem #0000001a;
    }
  }
}
</style>