import { render, staticRenderFns } from "./purchasingAgent.vue?vue&type=template&id=5481c7a6&scoped=true"
import script from "./purchasingAgent.vue?vue&type=script&lang=js"
export * from "./purchasingAgent.vue?vue&type=script&lang=js"
import style0 from "./purchasingAgent.vue?vue&type=style&index=0&id=5481c7a6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5481c7a6",
  null
  
)

export default component.exports