import Vue from 'vue'
import App from './App.vue'
import VueRouter from "vue-router"
import router from './router'
import ElementUi from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import HeaderTop from './components/headerTop'
import FooterBottom from './components/FooterBottom'
import store from './store/index.js'


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

import './config/rem.js';  
// 在 main.js 中引入
import '@/assets/fontFamily/font.css'
Vue.component('HeaderTop', HeaderTop)
Vue.component('FooterBottom', FooterBottom)

Vue.use(ElementUi)
Vue.use(VueRouter)

Vue.config.productionTip = false

new Vue({
  store,
  render: h => h(App),
  router
}).$mount('#app')
