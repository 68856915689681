<template>
    <div class="content">
        <div class="bgColor"></div>
        <HeaderTop :parentData="dataNum" :background="background" :color="color" :activeColor="activeColor"
            :position="position"></HeaderTop>

        <div class="headerTit">
            申请
        </div>
        <div class="headerBox">
            <div class="headerTop">
                <div class="el-icon-location-outline headerTopIcon">
                </div>
                <div class="headerAdress">
                    {{ userInfo.info.businessAddress }}
                </div>
            </div>
            <div class="headerBottom">
                {{ userInfo.info.legalName }}<span>{{ userInfo.info.mobile }}</span>
            </div>
        </div>
        <div class="headerBox" style="display: flex;" v-if="type==1">
            <div class="headerLeft">
                <img :src="dataMsg.pic" alt="">
            </div>
            <div class="headerRight">
                <div class="headerRightName">
                    {{ dataMsg.title }}
                </div>
                <div class="headerRightTip">
                    {{ dataMsg.paramOne }}
                </div>
                <div class="headerRightTip">
                    {{ dataMsg.paramTwo }}
                </div>
                <div class="headerRightTipName">
                    <el-input-number v-model="num" @change="handleChange" :min="1" label="描述文字"></el-input-number>
                </div>
            </div>
        </div>
        <div class="headerBox" style="display: flex;" v-if="type==2">
            <div class="headerLeft">
                <img :src="dataMsg.pic" alt="">
            </div>
            <div class="headerRight">
                <div class="headerRightName">
                    {{ dataMsg.name }}
                </div>
                <div class="headerRightPrice">
                    ¥ <span>{{ dataMsg.price }}</span>/元
                </div>
                <div class="headerRightTipName">
                    <el-input-number v-model="num" @change="handleChange" :min="1" label="描述文字"></el-input-number>
                </div>
            </div>
        </div>
        <div class="btnBoxBig">
            <button class="btnBox" @click="serve">确认申请</button>

        </div>
    </div>
</template>

<script>
import { goodsInfo, getProductById, askFor } from '@/api/user/index.js'
export default {
    data() {
        return {
            dataNum: 0,
            background: "#ffffff",
            color: "#000000",
            activeColor: "#00943F",
            position: "fixed",
            num: 1,
            goodsId: "",
            type: "",
            dataMsg: {},
            userInfo: JSON.parse(localStorage.getItem('userInfo')),
            btnFlag:false
        }
    },
    created() {
        this.goodsId = this.$route.query.id
        this.type = this.$route.query.type
        if (this.type == 1) {
            this.init()
        } else {
            this.inits()
        }
    },
    methods: {
        init() {
            goodsInfo({ goodsId: this.goodsId }).then(res => {
                console.log(res)
                if (res.code == 0) {
                    // 将你要渲染的数据进行字符串正则匹配
                    this.dataMsg = res.data
                }
            })
        },
        inits() {
            getProductById({ productId: this.goodsId }).then(res => {
                console.log(res)
                if (res.code == 0) {
                    // 将你要渲染的数据进行字符串正则匹配
                    this.dataMsg = res.data
                }
            })
        },
        serve() {
            if (this.btnFlag) {
                return
            }
            this.btnFlag = true
            askFor({goodsId:this.goodsId,num:this.num,type:this.type==1?0:1}).then(res => {
                this.btnFlag = false
                console.log(res)
                if (res.code == 0) {
                    this.$message({
                        message: '提交成功',
                        type: 'success',
                        customClass: 'messageBoxTip'
                    })
                    setTimeout(() => {
                        this.$router.go(-1)
                    }, 1000)
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning',
                        customClass: 'messageBoxTip'
                    })

                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    padding: 0.5rem 0;
}

.bgColor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fafafa;
    z-index: -1;
}

.headerTit {
    color: #000000;
    font-size: 0.5rem;
    font-weight: 700;
    font-family: "SourceHanSansCN";
    width: 15.68rem;
    margin: 0 auto;


}

.headerBox {
    width: 15.68rem;
    border-radius: 0.32rem;
    opacity: 1;
    background: #ffffff;
    margin: 0.3rem auto;
    padding: 0.43rem 0.59rem;
    box-sizing: border-box;

    .headerTop {
        display: flex;
        align-items: center;

        .headerTopIcon {
            font-size: 0.4rem;
        }

        .headerAdress {
            color: #000000;
            font-size: 0.4rem;
            font-weight: 500;
            font-family: "SourceHanSansCN";
            margin-left: 0.2rem;
        }
    }

    .headerBottom {
        color: #000000;
        font-size: 0.4rem;
        font-weight: 500;
        font-family: "SourceHanSansCN";
        margin-top: 0.2rem;
        margin-left: 0.6rem;

        span {
            margin-left: 0.2rem;

        }
    }

    .headerLeft {
        width: 4rem;
        height: 3.5rem;

        img {
            width: 100%;
            height: 100%;
            border-radius: 0.32rem;
            object-fit: cover;
        }
    }

    .headerRight {
        margin-left: 0.2rem;
        flex: 1;

        .headerRightName {
            color: #000000;
            font-size: 0.4rem;
            font-weight: 500;
            font-family: "SourceHanSansCN";
        }
        .headerRightPrice{
            color: #ff0000;
            font-size: 0.3rem;
            font-weight: 500;
            font-family: "SourceHanSansCN";
            span{
                font-weight: 700;
                font-size: 0.4rem;
            }
        }
        .headerRightTip {
            color: #7f7f7f;
            font-size: 0.3rem;
            font-weight: 500;
            font-family: "SourceHanSansCN";
        }

        .headerRightTipName {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 0.3rem;
        }
    }
}

.btnBoxBig {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.88rem;
}

.btnBox {
    width: 6.62rem;
    height: 0.98rem;
    border-radius: 1rem;
    background: linear-gradient(-41.4deg, #00903d 0%, #00b34a 100%);
    color: #ffffff;
    font-size: 0.32rem;
    font-weight: 500;
    font-family: "SourceHanSansCN";
    text-align: center;
}
</style>