<template>
    <div class="content">
      <HeaderTop :parentData="dataNum" :background="background" :color="color" :activeColor="activeColor"
      :position="position"></HeaderTop>
      <div class="header">
          <img :src="dataMsg.pic1" alt="" v-if="index==1">
          <img :src="dataMsg.pic2" alt="" v-if="index==2">
          <img :src="dataMsg.pic3" alt="" v-if="index==3">

      </div>
      <div class="cont">
          <div class="contTit">{{(index==1&&dataMsg.title1)||(index==2&&dataMsg.title2)||(index==3&&dataMsg.title3)}}</div>
          <div class="contTip">{{(index==1&&dataMsg.describe1)||(index==2&&dataMsg.describe2)||(index==3&&dataMsg.describe3)}}</div>
          <div v-html="dataMsg.content1" style="font-size: 0.3rem;" v-if="index==1"></div>
          <div v-html="dataMsg.content2" style="font-size: 0.3rem;" v-if="index==2"></div>
          <div v-html="dataMsg.content3" style="font-size: 0.3rem;" v-if="index==3"></div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
  data(){
      return{
          dataNum: 5,
              background: "#ffffff",
              color: "#000000",
              activeColor: "#00943F",
              position: "static",
              dataMsg:{},
              index:""
      }
  },
  created(){
    this.dataMsg = JSON.parse(localStorage.getItem('textCont'))
    this.dataMsg.content1 = this.dataMsg.content1.replace(/<img/g,"<img style='max-width:100%;height:auto;'");
    this.dataMsg.content2 = this.dataMsg.content2.replace(/<img/g,"<img style='max-width:100%;height:auto;'");
    this.dataMsg.content3 = this.dataMsg.content3.replace(/<img/g,"<img style='max-width:100%;height:auto;'");
    this.index = this.$route.query.type
  },
  methods:{
  
  }
  }
  </script>
  
  <style lang="scss" scoped>
  .header{
      width: 100%;
      height: 5rem;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
  }
  .cont{
      text-align: center;
      width: 14.49rem;
      margin: 0.92rem auto;
      .contTit{
          color: #000000;
   font-size: 0.48rem;
   font-weight: 500;
   font-family: "SourceHanSansCN";
      }
      .contTip{
          color: #54545480;
   font-size: 0.16rem;
   font-weight: 500;
   font-family: "SourceHanSansCN";
   text-align: center;
      }
  }
  </style>