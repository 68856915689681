<template>
  <div class="content">
    <div class="bgColor"></div>
        <HeaderTop :parentData="dataNum" :background="background" :color="color" :activeColor="activeColor"
            :position="position"></HeaderTop>
            <div class="shopBig">
           <div class="shopMsg">
            <div class="shopLeft">
                <img :src="dataMsg.pic" alt="">
            </div>
            <div class="shopCont">
                <div style="display: flex;justify-content: space-between;">
                    <div class="shopName">
                        {{ dataMsg.name }}
                    </div>
                    <div class="aixin" v-if="dataMsg.isCollect==0" @click="isCollectSet">
                        <img src="../assets/aixinNo.png" alt="">
                    </div>
                    <div class="aixin aixinA" v-if="dataMsg.isCollect==1" @click="isCollectSet">
                        <img src="../assets/aixin.png" alt="">
                    </div>
                </div>
               
                <div class="shopPrice">
                    价格：<span>¥</span><span class="shopPriceNum">{{ dataMsg.price }}</span><span>/元</span>
                </div>
                <div class="shopPrice">数量：{{ dataMsg.num }}吨</div>
                <div class="shopPrice">产地：{{dataMsg.source}}</div>

            </div>
           </div>
        </div>
        <div class="js">
             <div class="shopTit">
                <div class="shopDiv"></div>
                <div class="shopName">产品介绍</div>
            </div>
            <div class="fwb">
                <div v-html="dataMsg.content"></div>
            </div>
            <div class="shenq" style="background: #999;" v-if="userInfo.userType==4||userInfo.userType==0">申请</div>
            <div class="shenq" @click="open" v-if="userInfo.userType==1||userInfo.userType==2||userInfo.userType==3">申请</div>

        </div>
        <!-- <FooterBottom></FooterBottom> -->
  </div>
</template>

<script>
import {getProductById,collect} from '@/api/user/index.js'
export default {
data(){
    return{
        dataNum: 0,
        background: "#ffffff",
        color: "#000000",
        activeColor: "#00943F",
        position: "fixed",
        id:"",
        dataMsg:{},
        userInfo: JSON.parse(localStorage.getItem('userInfo'))
    }
},
created(){
    this.id = this.$route.query.id
    this.init()
  },
methods:{
    open() {
            // this.popFlag=true
            this.$router.push({path:'/applyFor',query:{id:this.dataMsg.id,type:2}})
        },
    isCollectSet(){
            if(this.dataMsg.isCollect==0){
					this.dataMsg.isCollect=1
				}else{
					this.dataMsg.isCollect=0
				}
            collect({type:1,id:this.dataMsg.id}).then(res=>{
                console.log(res)
                if (res.code == 0) {
                    this.$message({
                    message: res.msg,
                    type: 'success',
                    customClass:'messageBoxTip'

                })
					} else {
                        this.$message({
                    message: res.msg,
                    type: 'warning',
                    customClass:'messageBoxTip'

                })
					}
            })

        },
    init(){
        getProductById({productId:this.id}).then(res=>{
            console.log(res)
            if (res.code == 0) {
                // 将你要渲染的数据进行字符串正则匹配
                res.data.content = res.data.content.replace(/<img/g,"<img style='max-width:100%;height:auto;'");
                this.dataMsg = res.data

            }
        })
    },
   
}
}
</script>

<style lang="scss" scoped>
.content{
    padding: 0.5rem 0;
}
.bgColor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fafafa;
    z-index: -1;
}
.shopBig {
    width: 16.27rem;
    padding: 0.42rem;
    box-sizing: border-box;
border-radius: 0.31rem;
opacity: 1;
border: 0.01rem solid #eaeaea;
background: #ffffff;
    margin: 0.4rem auto 0;
}

.shopMsg{
    display: flex;
.shopLeft{
    width: 6.75rem;
height: 6.75rem;
    img{
        width: 100%;
        height: 100%;
        // border-radius: 0.31rem;
    }
}
.shopCont{
    flex: 1;
    margin-left: 0.54rem;
    .shopName{
        color: #343231;
 font-size: 0.36rem;
 font-weight: 700;
 font-family: "SourceHanSansCN";
 flex: 1;
    }
    .aixin{
    width: 0.41rem;
height: 0.42rem;
opacity: 1;
display: flex;
align-items: center;
background: #00000066;
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
margin-top: 0.05rem;
    img{
        width: 0.26rem;
        height: 0.25rem;
    }
}
.aixinA{
background: #ffbe0033;
}
    .shopPrice{
        color: #868588;
 font-size: 0.26rem;
 font-weight: 400;
 font-family: "SourceHanSansCN";
 margin-top: 0.36rem;
        span{
            color: #ff0000;
 text-align: left;
 font-weight: 700;

 font-family: "SourceHanSansCN";
        }
        .shopPriceNum{
            font-size: 0.36rem;
            

        }
    }
}
}
.js{
    width: 16.27rem;
border-radius: 0.31rem;
opacity: 1;
border: 0.01rem solid #eaeaea;
background: #ffffff;
margin: 0.4rem auto 0;
padding: 0.62rem;
box-sizing: border-box;
    .shopTit {
    display: flex;
    align-items: center;
margin-bottom: 0.42rem;
    .shopDiv {
        width: 0.08rem;
        height: 0.35rem;
        border-radius: 0 1rem 1rem 0;
        opacity: 1;
        border: 0.01rem solid #979797;
        background: #00903d;
    }

    .shopName {
        color: #00903d;
        font-size: 0.24rem;
        font-weight: 700;
        font-family: "SourceHanSansCN";
        margin-left: 0.16rem;
    }
}
.fwb{
    color: #343231;
 font-size: 0.36rem;
 font-weight: 700;
 font-family: "SourceHanSansCN";
}
.shenq {
        width: 6.71rem;
        height: 0.98rem;
        border-radius: 1rem;
        opacity: 1;
        background: linear-gradient(-41.4deg, #00903d 0%, #00b34a 100%);
        color: #ffffff;
        font-size: 0.32rem;
        font-weight: 500;
        font-family: "SourceHanSansCN";
        line-height: 0.98rem;
        text-align: center;
        margin: 0.5rem auto 0;


    }
}
</style>