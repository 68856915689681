<template>
    <div class="content">
        <div class="bgColor"></div>
        <HeaderTop :parentData="dataNum" :background="background" :color="color" :activeColor="activeColor"
            :position="position"></HeaderTop>
        <div class="box">
            <div class="boxNameTit">
                <div>{{ name }}</div>
                <div>{{ num }}吨</div>
            </div>
            <div class="boxCont">
                <div class="boxTit">
                    物流配车
                </div>
                <div class="boxContBox">
                    <div class="boxContBoxTit">粮运(司机)</div>
                    <div class="boxContLi" v-for="(item,index) in dataList" :key="index">
                        <div class="boxContLeft"></div>
                        <div class="boxContBoxText">
                            <div class="boxContName">
                                {{item.driverDTO.title}}
                            </div>
                            <div class="boxPhone">
                                联系方式：{{item.driverDTO.mobile}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
        </div>
    </div>
</template>

<script>
import { driverList } from '@/api/user/index.js'
export default {
    data() {
        return {
            name: "",
            num:"",
            dataNum: 0,
            background: "#ffffff",
            color: "#000000",
            activeColor: "#00943F",
            position: "fixed",
            id:"",
            dataList:[]
        }
    },
    created() {
        this.id = this.$route.query.id
        this.name = this.$route.query.name
        this.num = this.$route.query.num
        this.init()
    },
    methods: {
        // 详情数据
        init() {
            driverList({ orderId: this.id }).then(res => {
                if (res.code == 0) {
                    this.dataList = res.data
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning',
                        customClass: 'messageBoxTip'
                    })
                }
            })


        },
        
       
    }
}
</script>

<style lang="scss" scoped>
.content {
    padding-top: 0.8rem;
}

.bgColor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fafafa;
    z-index: -1;
}

.box {
    width: 16.27rem;
    margin: 0 auto;

    .boxNameTit {
        color: #000000;
        font-size: 0.36rem;
        font-weight: 700;
        font-family: "SourceHanSansCN";
        padding-left: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .boxCont {
        width: 100%;
        // height: 10.64rem;
        border-radius: 0.32rem;
        opacity: 1;
        border: 0.04rem solid #ffffff;
        background: #ffffff;
        box-shadow: 0 0.2rem 0.4rem 0 #19c2d11a;
        margin-top: 0.2rem;
        padding-bottom: 0.5rem;

        .boxTit {
            width: 16.12rem;
            height: 0.7rem;
            opacity: 1;
            background: linear-gradient(90deg, #00903d 0%, #00903d00 100%);
            color: #ffffff;
            font-size: 0.32rem;
            font-weight: 700;
            font-family: "SourceHanSansCN";
            border-radius: 0.32rem 0 0 0;
            display: flex;
            align-items: center;
            padding-left: 0.26rem;

        }

        .boxContBox {
            padding: 0.5rem;
            box-sizing: border-box;
            .boxContBoxTit{
            font-size: 0.32rem;
            font-weight: 700;
            font-family: "SourceHanSansCN";
            }
            .boxContLi{
                border-radius: 0.35rem;
                background: #ffffff;
                box-shadow: 0 0.1rem 0.4rem 0 #00923e1a;
                margin: 0.26rem auto 0;
                display: flex;
                align-items: center;
                padding: 0.3rem 0;
                box-sizing: border-box;
                .boxContLeft{
                    width: 0.06rem;
                    height: 0.42rem;
                    border-radius: 0.02rem;
                    background: #00923e;
                }
                .boxContBoxText{
                    margin-left: 0.5rem;
                    .boxContName{
                        font-size: 0.32rem;
            font-weight: 400;
            font-family: "SourceHanSansCN";
                    }
                    .boxPhone{
                        font-size: 0.32rem;
            font-weight: 400;
            font-family: "SourceHanSansCN";
                    }
                }
            }
        }
    }
}


</style>