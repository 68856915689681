<template>
  <div class="content">
    <div class="bgColor"></div>
        <HeaderTop :parentData="dataNum" :background="background" :color="color" :activeColor="activeColor"
            :position="position"></HeaderTop>
        <div class="box">
            <div class="boxTit">
                详情
            </div>
            <div class="boxMin">
                <div class="boxTop">
                    需求信息
                </div>
                <div class="boxCont">
                    <div class="boxLi">
                        <div class="boxName">
                            订单编号
                        </div>
                        <div class="boxNum">
                            {{ dataMsg.orderCode || '' }}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            价格
                        </div>
                        <div class="boxNum">
                            {{ dataMsg.price || '' }}/斤
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            采购数量
                        </div>
                        <div class="boxNum">
                            {{ dataMsg.num || '' }}吨
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            可申请数量
                        </div>
                        <div class="boxNum">
                            {{ dataMsg.balance || '' }}吨
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            交易品种
                        </div>
                        <div class="boxNum">
                            {{ dataMsg.productName || '' }}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            交易类型
                        </div>
                        <div class="boxNum">
                            {{ dataMsg.dealType || '' }}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            产地
                        </div>
                        <div class="boxNum">
                            {{ dataMsg.producer || '' }}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            发布时间
                        </div>
                        <div class="boxNum">
                            {{ dataMsg.releaseTime || '' }}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            履约保证方式
                        </div>
                        <div class="boxNum">
                            {{ dataMsg.ensureMethod || '' }}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            信息有效期
                        </div>
                        <div class="boxNum">
                            {{ dataMsg.messageValidity || '' }}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            包装方式
                        </div>
                        <div class="boxNum">
                            {{ dataMsg.packing || '' }}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            交货时间
                        </div>
                        <div class="boxNum">
                            {{ dataMsg.deliveryTime || '' }}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            交货地
                        </div>
                        <div class="boxNum">
                            {{ dataMsg.deliveryPlace || '' }}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            交货方式
                        </div>
                        <div class="boxNum">
                            {{ dataMsg.deliveryMethod || '' }}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            可提供发票类型
                        </div>
                        <div class="boxNum">
                            {{ dataMsg.invoiceType || '' }}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            付款方式
                        </div>
                        <div class="boxNum">
                            {{ dataMsg.paymentMethod || '' }}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            回款时间
                        </div>
                        <div class="boxNum">
                            {{ dataMsg.returnTime || '' }}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            验收方式
                        </div>
                        <div class="boxNum">
                            {{ dataMsg.checkMethod || '' }}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            水分要求
                        </div>
                        <div class="boxNum">
                            {{'≦'+dataMsg.waterRequire+'%'||''}}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            杂质要求
                        </div>
                        <div class="boxNum">
                            {{'≦'+dataMsg.impurityRequire+'%'||''}}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            生霉粒要求
                        </div>
                        <div class="boxNum">
                            {{'≦'+dataMsg.moldyRequire+'%'||''}}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            不完善率要求
                        </div>
                        <div class="boxNum">
                            {{'≦'+dataMsg.unsoundKernel+'%'||''}}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            蛋白质要求
                        </div>
                        <div class="boxNum">
                            {{'≦'+dataMsg.proteinRequire||''}}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            赤毒病率要求
                        </div>
                        <div class="boxNum">
                            {{'≦'+dataMsg.wornRequire+'%'||''}}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            容重要求
                        </div>
                        <div class="boxNum">
                            {{'≧'+dataMsg.weightRequire+'g/L'||''}}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            霉菌要求
                        </div>
                        <div class="boxNum">
                            {{'＜'+dataMsg.mildewRequire+'g'||''}}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            呕吐毒素要求
                        </div>
                        <div class="boxNum">
                            {{'≦'+dataMsg.vomitoxinRequire+'kg'||''}}
                        </div>
                    </div>
                    <!-- <div class="boxLi">
                        <div class="boxName">
                            色泽气味
                        </div>
                        <div class="boxNum">
                            {{dataMsg.colorOdorRequire||''}}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            其它
                        </div>
                        <div class="boxNum">
                            {{dataMsg.other||''}}
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="shenq" @click="popFlag=true" v-if="userInfo.userType==3&&(dataMsg.status==0||dataMsg.status==1)">提交申请</div>
            <div class="shenq" style="background: #999;" v-else>提交申请</div>
        </div>
        <div class="messageBig" v-if="popFlag">
            <div class="messageBox">
                <div class="messageTop">
                    <div class="messageTit">申请数量</div>
                    <div class="el-icon-close" @click="popFlag=false"></div>
                </div>
                <div class="messageFrom">
                    <div class="messageLi">
                        <el-input-number @change="handleChange" v-model="num" :min="1" :max="dataMsg.balance" label="申请数量"></el-input-number><span style="margin-left: 0.1rem;">吨</span>
                    </div>
                    <div class="btnBottom">
                        <div class="btnBottomLi" @click="popFlag=false">
                            取消
                        </div>
                        <div @click="submit" class="btnBottomLi" style="border: none;background: linear-gradient(90deg, #00b34a 0%, #00953f 100%);color: #ffffff;">
                            提交
                        </div>
                    </div>
                </div>
            </div>
        </div>
  </div>
</template>

<script>

import {subscribe} from '@/api/user/index.js'

export default {
data(){
    return{
        dataNum: 0,
            background: "#ffffff",
            color: "#000000",
            activeColor: "#00943F",
            position: "fixed",
            userInfo: JSON.parse(localStorage.getItem('userInfo')),
            dataMsg:JSON.parse(localStorage.getItem('orderMsgXq')),
            popFlag:false,
            num:1
    }
},
methods:{
    handleChange(value) {
        console.log(value);
      },
      submit(){
        this.popFlag=false
        subscribe({orderCode:this.dataMsg.orderCode,number:this.num}).then(res => {
                        console.log(res)
                        if (res.code == 0) {
                            this.$message({
                                message: '提交成功，等待审核!',
                                type: 'success'
                            })
                            setTimeout(() => {
                                this.$router.go(-1)

						}, 1000)
                        }else{
                          this.$message({
                                message: res.msg,
                                type: 'warning',
                        customClass: 'messageBoxTip'

                            })
                        }

                    })
      }
}
}
</script>

<style lang="scss" scoped>
.content {
    padding-top: 0.5rem;
}

.bgColor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fafafa;
    z-index: -1;
}
.box {
    width: 15.68rem;
    border-radius: 0.32rem;
    opacity: 1;
    background: #ffffff;
    margin: 0 auto;
    padding: 0.43rem 0.59rem;
    box-sizing: border-box;

    .boxTit {
        color: #000000;
        font-size: 0.5rem;
        font-weight: 500;
        font-family: "SourceHanSansCN";
    }
    .boxMin {
        width: 14.48rem;
        border-radius: 0.32rem;
        opacity: 1;
        border: 0.04rem solid #ffffff;
        background: #ffffff;
        box-shadow: 0 0.2rem 0.4rem 0 #2fa5631a;
        margin-top: 0.4rem;

        .boxTop {
            width: 10.6rem;
            height: 0.7rem;
            border-radius: 0.3rem 0 0 0;
            opacity: 1;
            background: linear-gradient(90deg, rgba(47, 165, 99, 0.5) 0%, #2fa56300 100%);
            color: #2fa563;
            font-size: 0.32rem;
            font-weight: 500;
            font-family: "SourceHanSansCN";
            display: flex;
            align-items: center;
            padding-left: 0.65rem;
        }

        .boxCont {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            padding: 0.5rem 0.65rem;
            box-sizing: border-box;

            .boxLi {
                width: 50%;
                margin-bottom: 0.5rem;

                .boxName {
                    color: #34323166;
                    font-size: 0.26rem;
                    font-weight: 400;
                    font-family: "SourceHanSansCN";
                }

                .boxNum {
                    color: #343231;
                    font-size: 0.28rem;
                    font-weight: 500;
                    font-family: "SourceHanSansCN";
                }
            }
        }
    }
}
.shenq {
        width: 6.71rem;
        height: 0.98rem;
        border-radius: 1rem;
        opacity: 1;
        background: linear-gradient(-41.4deg, #00903d 0%, #00b34a 100%);
        color: #ffffff;
        font-size: 0.32rem;
        font-weight: 500;
        font-family: "SourceHanSansCN";
        line-height: 0.98rem;
        text-align: center;
        margin: 0.5rem auto 0;


    }
    .messageBig {
    width: 100%;
    height: 100vh;
    background: rgba($color: #000000, $alpha: 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;

    .messageBox {
        width: 6rem;
        background: #ffffff;
        border-radius: 0.2rem;
        margin: 0.88rem auto;

        .messageTop {
            width: 6rem;
            height: 0.7rem;
            // height: 0.52rem;
// 
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 0.2rem 0.2rem 0 0;
            background: url('@/assets/bgbg.png') no-repeat center;
            background-size: 100% 100%;
            padding: 0 0.16rem;
            box-sizing: border-box;

            .messageTit {
                color: #253a70;
                // font-size: 0.18rem;
                font-size: 0.3rem;

                font-weight: 500;
                font-family: "SourceHanSansCN";
            }

            .el-icon-close {
                color: #253a70;
                font-size: 0.25rem;
                font-weight: 500;
                font-family: "SourceHanSansCN";
            }
        }
        .messageFrom{
            padding: 0.18rem;
            box-sizing: border-box;
            font-size: 0.3rem;
 font-weight: 400;
 font-family: "SourceHanSansCN";
            .messageLi{
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 0.25rem;
                .messageName{
                    color: #253a70;
 font-size: 0.16rem;
 font-weight: 400;
 font-family: "SourceHanSansCN";
 width: 1.8rem;
 text-align: right;
 margin-right: 0.1rem;
                    span{
color: #F92800;
                    }
                }
            }
            .btnBottom{
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-top: 0.7rem;
                .btnBottomLi{
                    width: 0.84rem;
height: 0.32rem;
border-radius: 0.08rem;
opacity: 1;
border: 0.01rem solid #b8c2d9;
color: #798cb5;
 font-size: 0.14rem;
 font-weight: 500;
 font-family: "SourceHanSansCN";
 line-height: 0.32rem;
 text-align: center;
 margin-left: 0.16rem;
}
            }
            
        }
    }
}
</style>