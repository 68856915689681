<template>
  <div class="content">
    <HeaderTop @childMessage="handleChildMessage" :parentData="dataNum" :background="background" :color="color" :activeColor="activeColor"
    :position="position"></HeaderTop>
    <div class="header">
      <img :src="dataMsg.company.background" alt="">
        <!-- <div class="headerContText">
        <div class="headerContTextTit">
          关于粮之道
        </div>
        <div class="headerContTextTip">
            ——用实力服务每一位客户
        </div>
      </div> -->
    </div>
    <div class="contBox">
        <div class="title">
        公司简介
    </div>
    <div class="titleEnglie">
        INTRODUCTION
    </div>
    <div class="Cont">
        {{dataMsg.company.aboutUs}} 
    </div>
    <div class="enterprise">
        合作企业
    </div>
    <div class="Cont">
      {{dataMsg.company.partnership}} 
    </div>
    <div class="qiyeBox">
      <div class="qiye" v-for="(item,index) in dataMsg.company.shipLogos" :key="index">
        <img :src="item.url" alt="">
      </div>
    </div>
    
    <!-- <div class="qiye">
      <img src="../assets/qxnm.png" alt="" style="width: 2.43rem;height: 0.81rem;">
      <img src="../assets/bejt.png" alt="" style="width: 2.12rem;height: 0.7rem;">
      <img src="../assets/yxsy.png" alt="" style="width: 4.41rem;height: 1.02rem;">
      <img src="../assets/qy.png" alt="" style="width: 1.3rem;height: 0.93rem;">
    </div>
    <div class="qiye" style="display: block;">
      <img src="../assets/xwjt.png" alt="" style="width: 2.53rem;height: 0.7rem;">
      <img src="../assets/dxsw.png" alt="" style="width: 2.26rem;height: 0.86rem;">
    </div> -->
    </div>
    <!-- <FooterBottom></FooterBottom> -->
  </div>
</template>

<script>
export default {
data(){
    return{
        dataNum: 0,
            background: "#ffffff",
            color: "#000000",
            activeColor: "#00943F",
            position: "static",
            dataMsg:{
              company:{}
            }
    }
},
created(){
    // console.log(this.)
},
methods:{
  handleChildMessage(msg){
    console.log(msg,651231)
    this.dataMsg = msg
  }
}
}
</script>

<style lang="scss" scoped>
.header{
    width: 100%;
    height: 5rem;
    img{
      width: 100%;
      height: 100%;
    }
// background: url('@/assets/lf.png') no-repeat center;
// background-size: 100% 100%;

// background: #134B9F;


  // display: flex;
  // align-items: center;
// padding-left: 3.72rem;
// box-sizing: border-box;
    .headerContText{
    .headerContTextTit{
      color: #ffffff;
 font-size: 0.72rem;
 font-weight: 700;
 font-family: "SourceHanSansCN";
    }
    .headerContTextTip{
      color: #ffffff;
 font-size: 0.2rem;
 font-weight: 400;
 font-family: "SourceHanSansCN";
    }
  }
}
.contBox{
    width: 14.7rem;
    margin: 0 auto 1rem;
}
.title{
    color: #282828;
 font-size: 0.36rem;
 font-weight: 500;
 font-family: "SourceHanSansCN";
 margin-top: 0.89rem;
 text-align: center;
 
}
.titleEnglie{
    color: #282828;
 font-size: 0.18rem;
 font-weight: 500;
 font-family: "SourceHanSansCN";
 text-align: center;
 opacity: 0.6;
}
.Cont{
    color: #333333;
 font-size: 0.28rem;
 font-weight: 400;
 font-family: "SourceHanSansCN";
 line-height: 0.6rem;
 margin-top: 0.52rem;
}

// .qiye{
//   display: flex;
//   align-items: center;
//   // justify-content: space-between;
//   flex-wrap: wrap;
//   margin-top: 0.5rem;
//  img{
//   margin-right: 1.09rem;
//  }
// }
.qiyeBox{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.qiye{
  height: 1rem;
  margin-top: 1.09rem;
   img{
  height: 100%;

    margin-right: 1.09rem;
    object-fit: cover;
  }
}
.enterprise{
    color: #282828;
 font-size: 0.36rem;
 font-weight: 500;
 font-family: "SourceHanSansCN";
 text-align: center;
 margin: 1.16rem auto 0;
 width: 1.8rem;
 position: relative;

}
.enterprise::after{
    content: "";
	position: absolute;
	left: 0.7rem;
	bottom: -0.1rem;
	right: 0;
	flex: 1;
	width: 0.4rem;
height: 0.04rem;
	background: #239F59;
}
</style>