<template>
  <div class="content">
    <div class="bgColor"></div>
    <div class="logo">
      <img src="../../assets/logo.png" alt="" />
    </div>
    <div class="title">
      <div class="titleLi">嗨~现在加入我们吧！</div>
      <div class="titleTip">创建你的个人账号或者登录</div>
    </div>
    <div class="inptBox">
      <div class="inptLi">
        <div class="inptTitle">
          <img src="../../assets/login/zhanghao.png" alt="" />
          姓名
        </div>
        <div class="inptText">
          <input type="text" v-model="nickName" placeholder="请输入您的手机号" />
        </div>
      </div>
      <div class="inptLi" style="margin-top: 30px">
        <div class="inptTitle">
          <img src="../../assets/login/yam.png" alt="" />
          验证码
        </div>
        <div class="inptText">
          <input type="text" v-model="code" placeholder="请输入验证码" />
         <button class="getYzm" @click="getCode">{{timerFont}}</button>
        </div>
      </div>
      <div class="inptLi" style="margin-top: 30px">
        <div class="inptTitle">
          <img src="../../assets/login/mima.png" alt="" />
          密码
        </div>
        <div class="inptText">
          <input :type="passwordType" v-model="password" placeholder="请输入您的密码" />
          <img
            v-if="passwordType == 'text'"
            src="../../assets/login/eye.png"
            alt=""
            @click="togglePassword(1)"
          />
          <img
            v-if="passwordType == 'password'"
            src="../../assets/login/noeye.png"
            alt=""
            @click="togglePassword(1)"
          />
        </div>
      </div>
      <div class="inptLi" style="margin-top: 30px">
        <div class="inptTitle">
          <img src="../../assets/login/mima.png" alt="" />
          确认密码
        </div>
        <div class="inptText">
          <input :type="passwordTypes" v-model="passwords" placeholder="请再次输入您的密码" />
          <img
            v-if="passwordTypes == 'text'"
            src="../../assets/login/eye.png"
            alt=""
            @click="togglePassword(2)"
          />
          <img
            v-if="passwordTypes == 'password'"
            src="../../assets/login/noeye.png"
            alt=""
            @click="togglePassword(2)"
          />
        </div>
      </div>
      <div class="agreeBox" @click="agreeFlag=!agreeFlag">
        <div class="agreeQuan">
            <i class="el-icon-check" v-if="agreeFlag"></i>
        </div>
        <div class="agreeName">
            我已阅读并同意<button @click="toUrl('/xieyiCont','用户协议')">《用户协议》</button>和<button @click="toUrl('/xieyiCont','隐私协议')">《隐私协议》</button>
        </div>
      </div>
      <button class="btn" @click="zuce">注册</button>
      <!-- <router-link class="btnType" to="codeLogin">手机快速登录</router-link> -->
      <button class="btnType" @click="toUrl">已有账号？点击这里登录</button>
    </div>
    <div class="markBg" v-if="agreePop">
        <div class="markCont">
            <div class="markTit">阅读并同意以下协议</div>
            <div class="markTip">
                为了保证您的个人信息安全，使用登录
            </div>
             <div class="markTip">
                功能需要先阅读并同意
                <button @click="toUrl('/xieyiCont','用户协议')">《用户协议》</button>
                和
                <button @click="toUrl('/xieyiCont','隐私协议')">《隐私协议》</button>
            </div>
            <div class="markBtn" @click="confirm">已阅读并同意</div>
        </div>
    </div>
    <div class="markBg" v-if="joinFlag">
      <div class="markCont">
          <!-- <div class="el-icon-close"></div> -->
            <div class="markTit">请选择您的身份，加入粮之道大家庭！</div>
            <div class="markLi" @click="toUrl('/manuFacturer')">
              <div class="markLeft">
                <img src="../../assets/login/rz.png" alt="">
                成为粮市(成品制造商)
              </div>
              <div class="el-icon-arrow-right"></div>
            </div>
            <div class="markLi" @click="toUrl('/purchasingAgent')">
              <div class="markLeft">
                <img src="../../assets/login/rz.png" alt="">
                成为粮坊(采购商)
              </div>
              <div class="el-icon-arrow-right"></div>
            </div>
            <div class="markLi" @click="toUrl('/supplierRz')">
              <div class="markLeft">
                <img src="../../assets/login/rz.png" alt="">
                成为粮仓(供货商)
              </div>
              <div class="el-icon-arrow-right"></div>
            </div>
            <div class="markLi" @click="toUrl('/driverRz')">
              <div class="markLeft">
                <img src="../../assets/login/rz.png" alt="">
                成为粮运(司机)
              </div>
              <div class="el-icon-arrow-right"></div>
            </div>
            <div class="markLi" @click="toUrl('/')">
              <div class="markLeft">
                <img src="../../assets/login/rz.png" alt="">
                我是游客继续浏览
              </div>
              <div class="el-icon-arrow-right"></div>
            </div>
        </div>
    </div>
    <div class="markBg" v-if="txFlag">
      <div class="markCont">
        <div class="el-icon-close" @click="txFlag = false"></div>
        <div class="markTit">图形验证码</div>
        <div class="markBgBox">
          <input type="text" v-model="captchaStr" placeholder="请输入验证码" />
          <img :src="urltxyzm" mode="widthFix" @click="tuxing" />
        </div>
        <div class="btnpop" @click="yzm">确定</div>
      </div>
    </div>
  </div>
</template>

<script>
import { captcha, sendSMS , register  } from '@/api/user/index.js'
export default {
  name: "HelloWorld",
  data() {
    return {
      urltxyzm: "",
      txFlag:false,
      passwordType: "password",
      passwordTypes:"password",
      agreeFlag:false,
      agreePop:false,
      joinFlag:false,
      timerFont: '获取验证码',
      timer: null,
      timers: null,
      count: 0,
      nickName:"",//姓名
				password:"",//密码
				passwords:"",
        code:""
    };
  },
  methods: {
    confirm(){
				this.agreeFlag = true
				this.zuce()
        this.agreePop = false
			},
    togglePassword(num) {
if(num==1){
if (this.passwordType === "password") {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
}else{
    if (this.passwordTypes === "password") {
        this.passwordTypes = "text";
      } else {
        this.passwordTypes = "password";
      }
}
      
    },
    zuce(){
      if(!this.nickName){
          this.$message({
            message: "请输入手机号",
            type: 'warning'
          })
					return
				}
        if(!this.code){
          this.$message({
            message: "请输入验证码",
            type: 'warning'
          })
					return
				}
        if(!this.password){
          this.$message({
            message: "请输入密码",
            type: 'warning'
          })
					return
				}
        if(!this.passwords){
          this.$message({
            message: "请再次输入密码",
            type: 'warning'
          })
					return
				}
        if(this.passwords!=this.password){
          this.$message({
            message: "密码和确认密码输入的不一致",
            type: 'warning'
          })
					return
				}
        if(!this.agreeFlag){
					this.agreePop=true
					return
				}
      register({mobile:this.nickName,password:this.password,code:this.code}).then(res=>{
        if(res.code==0){
            localStorage.setItem('token',res.data.token)
            this.$message({
            message: "登录成功",
            type: 'success'
          })
						if(res.data.userType==0){
							this.joinFlag=true
						}else{
              this.$router.replace('/')
						}
          }
      })
    },
    // 短信验证码获取
    yzm() {
      let _this = this
      if (!this.captchaStr) {
        this.$message({
          message: "请输入图形验证码",
          type: 'warning'
        })
        return
      }
      const TIME_COUNT = 60;
      if (!_this.timer) {
        sendSMS({ mobile: this.nickName, uuid: this.uuid, captcha: this.captchaStr }).then(res => {
          if (res.code == 0) {
            _this.txFlag = false;
            this.$message({
              message: "发送成功",
              type: 'success'
            })
            _this.count = TIME_COUNT;
            _this.timer = setInterval(() => {
              if (_this.count > 0 && this.count <= TIME_COUNT) {
                _this.count--;
                _this.timerFont = this.count + "秒";

              } else {
                _this.timerFont = "获取验证码";
                clearInterval(this.timer);
                _this.timer = null;
              }
            }, 1000)
          }
        })

      }
    },
    toUrl(urlSrc,name) {
      if(urlSrc=='/xieyiCont'){
        this.$router.push({path:urlSrc,query:{name:name}})
        return
      }
      this.$router.push('/')
    },
    
    // 获取验证码
    getUUID() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16)
      })
    },
    getCode() {
      if (!this.nickName) {
        this.$message({
          message: "请输入手机号",
          type: 'warning'
        })
        return
      }
      if (!(/^1[3456789]\d{9}$/.test(this.nickName))) {
        this.$message({
          message: "输入的手机号有误",
          type: 'warning'
        })
        return
      }

      console.log(this.uuid)
      this.txFlag = true
      this.tuxing()
    },
    arrayBufferToBase64(buffer) {
      var binary = ''
      var bytes = new Uint8Array(buffer)
      var len = bytes.byteLength
      for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i])
      }
      return window.btoa(binary)
    },
    tuxing() {
      this.uuid = this.getUUID()
      captcha(this.uuid).then(res => {
        this.urltxyzm = 'data:image/jpeg;base64,' + this.arrayBufferToBase64(res)
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  height: 100vh;
//   background: url("../../assets/bgColor.png") no-repeat center;
//   background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .logo {
    width: 106px;
    height: 106px;
    border-radius: 12px;
    opacity: 1;
    background: linear-gradient(-41.4deg, #ffffff 0%, #f7fffd 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 73.26px;
      height: 68.59px;
    }
  }
  .title {
    margin-top: 14px;
    .titleLi {
      color: #ffffff;
      font-size: 32px;
      font-weight: 700;
      font-family: "SourceHanSansCN";
      text-align: center;
    }
    .titleTip{
         color: #ffffff;
 font-weight: 400;
 text-align: center;
 font-size: 13px;
 font-family: "SourceHanSansCN";
    }
  }
  .inptBox {
    margin-top: 60px;
    .inptLi {
      .inptTitle {
        color: #ffffff;
        font-size: 16px;
        font-weight: 400;
        font-family: "SourceHanSansCN";
        display: flex;
        align-items: center;
        img {
          width: 16px;
          height: 16px;
          margin-right: 4px;
        }
      }
      .inptText {
        margin-top: 10px;
        width: 311px;
        border-bottom: 1px solid #edf0f0;
        display: flex;
        align-items: center;
        .getYzm{
          width: 100px;
height: 32px;
opacity: 1;
background: #ffbe00;
border-radius: 50px;
margin-bottom: 10px;
 color: #ffffff;
 font-size: 14px;
 font-weight: 400;
 font-family: "SourceHanSansCN";
        }
        input {
          flex: 1;
          padding: 5px 0;
          color: #ffffff;
          font-size: 14px;
          font-weight: 400;
          font-family: "SourceHanSansCN";
        }

        input::placeholder {
          color: #ffffff; /* 将颜色代码修改为需要的颜色 */
        }
        img {
          width: 14px;
          height: 14px;
        }
      }
    }
    .display {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
      .displayLi {
        color: #ffffff;
        font-size: 14px;
        font-weight: 400;
        font-family: "SourceHanSansCN";
      }
    }
    .agreeBox{
        display: flex;
        align-items: center;
        margin-top: 20px;
        .agreeQuan{
            margin-right: 8px;
            width: 14px;
height: 14px;
border-radius: 172px;
border: 1px solid #ffffff;
display: flex;
align-items: center;
justify-content: center;
            .el-icon-check{
font-size: 12px;
color: #ffffff;
            }
        }
        .agreeName{
            color: #ffffff;
 font-size: 12px;
 font-weight: 400;
 font-family: "SourceHanSansCN";
            button{
color: #ffffff;
 font-size: 12px;
 font-weight: 500;
 font-family: "SourceHanSansCN";
            }
        }
    }
    .btn {
      width: 311px;
      height: 44px;
      border-radius: 24px;
      opacity: 1;
      background: linear-gradient(-41.4deg, #ffbe00 0%, #ffeb3b 100%);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-size: 18px;
      font-weight: 700;
      font-family: "SourceHanSansCN";
      margin-top: 28px;
    }
    .btnType {
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
      font-family: "SourceHanSansCN";
      margin-top: 16px;
      text-align: center;
      width: 100%;
    }
  }
}
.markBg{
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    .markCont{
       min-width: 4.02rem;
// height: 122px;
background: #ffffff;
border-radius: 0.2rem;
padding: 0.34rem 0.3rem 0.29rem;
box-sizing: border-box;
box-sizing: border-box;
position: relative;
.el-icon-close {
      color: #0a1629;
      font-size: 0.2rem;
      font-weight: 700;
      font-family: "SourceHanSansCN";
      position: absolute;
      right: 0.15rem;
      top: 0.15rem;
    }
        .markTit{
color: #323233;
 font-size: 16px;
 font-weight: 500;
 font-family: "SourceHanSansCN";
 text-align: center;
  margin-bottom: 8px;
        }
        .markBgBox {
      display: flex;
      align-content: center;
      padding: 0.1rem;
      box-sizing: border-box;

      input {
        flex: 1;
        font-size: 0.3rem;
      }

      img {
        width: 2rem;
      }
    }
    
    .btnpop {
      width: 90%;
      height: 0.64rem;
      border-radius: 0.5rem;
      opacity: 1;
      background: linear-gradient(-41.4deg, #ffbe00 0%, #ffeb3b 100%);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-size: 0.18rem;
      font-weight: 700;
      font-family: "SourceHanSansCN";
      margin: 0.28rem auto 0;
    }
        .markLi {
      width: 2.95rem;
      height: 0.78rem;
      border-radius: 0.14rem;
      opacity: 1;
      background: #f4f9fd;
      margin: 0 auto 0.2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0.2rem;
      box-sizing: border-box;

      .markLeft {
        color: #0a1629;
        font-size: 0.18rem;
        font-weight: 400;
        font-family: "SourceHanSansCN";
        display: flex;
        align-items: center;

        img {
          width: 0.24rem;
          height: 0.24rem;
          margin-right: 0.16rem;
        }
      }

      .el-icon-arrow-right {
        font-size: 0.18rem;
      }
    }
        .markTip{
             color: #969799;
 font-size: 14px;
 font-weight: 400;
 font-family: "SourceHanSansCN";
 text-align: center;

            button{
 color: #00903d;
 font-size: 14px;
 font-weight: 400;
 font-family: "SourceHanSansCN";
 text-align: center;
            }
        }
        .markBtn{
width: 100%;
height: 48px;
line-height: 48px;
margin-top: 26px;
text-align: center;
color: #00903d;
 font-size: 16px;
 font-weight: 400;
 font-family: "SourceHanSansCN";
        }
    }
}
</style>>

