<template>
  <div class="content">
    <div class="bgColor"></div>
    <div class="logo">
      <img src="../../assets/logo.png" alt="" />
    </div>
    <div class="title">
      <div class="titleLi">Hello!</div>
      <div class="titleLi">欢迎登录粮之道</div>
    </div>
    <div class="inptBox">
      <div class="inptLi">
        <div class="inptTitle">
          <img src="../../assets/login/zhanghao.png" alt="" />
          姓名
        </div>
        <div class="inptText">
          <input type="text" v-model="mobile" placeholder="请输入您的手机号" />
        </div>
      </div>
      <div class="inptLi" style="margin-top: 30px">
        <div class="inptTitle">
          <img src="../../assets/login/mima.png" alt="" />
          密码
        </div>
        <div class="inptText">
          <input :type="passwordType" v-model="password" placeholder="请输入您的密码" />
          <img
            v-if="passwordType == 'text'"
            src="../../assets/login/eye.png"
            alt=""
            @click="togglePassword()"
          />
          <img
            v-if="passwordType == 'password'"
            src="../../assets/login/noeye.png"
            alt=""
            @click="togglePassword()"
          />
        </div>
      </div>
      <div class="display">
        <div class="displayLi" @click="toUrl('/register')">还没有账号？立即注册</div>
        <!-- <div class="displayLi">忘记密码？</div> -->
      </div>
      <button class="btn" @click="login">立即登录</button>
      <!-- <router-link class="btnType" to="codeLogin">手机快速登录</router-link> -->
      <button class="btnType" @click="toUrl('/codeLogin')">手机快速登录</button>
    </div>
    <div class="markBg" v-if="joinFlag">
        <div class="markCont">
          <!-- <div class="el-icon-close"></div> -->
            <div class="markTit">请选择您的身份，加入粮之道大家庭！</div>
            <div class="markLi" @click="toUrl('/manuFacturer')">
              <div class="markLeft">
                <img src="../../assets/login/rz.png" alt="">
                成为粮市(成品制造商)
              </div>
              <div class="el-icon-arrow-right"></div>
            </div>
            <div class="markLi" @click="toUrl('/purchasingAgent')">
              <div class="markLeft">
                <img src="../../assets/login/rz.png" alt="">
                成为粮坊(采购商)
              </div>
              <div class="el-icon-arrow-right"></div>
            </div>
            <div class="markLi" @click="toUrl('/supplierRz')">
              <div class="markLeft">
                <img src="../../assets/login/rz.png" alt="">
                成为粮仓(供货商)
              </div>
              <div class="el-icon-arrow-right"></div>
            </div>
            <div class="markLi" @click="toUrl('/driverRz')">
              <div class="markLeft">
                <img src="../../assets/login/rz.png" alt="">
                成为粮运(司机)
              </div>
              <div class="el-icon-arrow-right"></div>
            </div>
            <div class="markLi" @click="toUrl('/')">
              <div class="markLeft">
                <img src="../../assets/login/rz.png" alt="">
                我是游客继续浏览
              </div>
              <div class="el-icon-arrow-right"></div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import {login} from '@/api/user/index.js'
// import service from '@/utils/request';
export default {
  name: "HelloWorld",
  data() {
    return {
      passwordType: "password",
      joinFlag:false,
      mobile:"",
      password:""
    };
  },
  methods: {

    togglePassword() {
      if (this.passwordType === "password") {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    },
    toUrl(urlSrc){
      this.$router.push(urlSrc)
    },
    // 登录
    login(){
      if(!this.mobile){
        this.$message({
          message: '请输入账号',
          type: 'warning'
        });
					return
				}
				if(!this.password){
          this.$message({
            message: '请输入密码',
            type: 'warning'
          });
					
					return
				}
        login({ mobile:this.mobile,password:this.password }).then(response=>{
          console.log(response)
          if(response.code==0){
            localStorage.setItem('token',response.data.token)
                this.$message({
                message: '登录成功',
                type: 'success'
              });
						if(response.data.userType==0){
							this.joinFlag=true
						}else{
							this.$router.replace('/')
						}
          }
              

        })
      // service.post('login', { mobile:this.mobile,password:this.password })
      //   .then(response => {
      //     // 处理响应数据

      //     localStorage.setItem('token',response.data.token)
      //     this.$message({
      //     message: '登录成功',
      //     type: 'success'
      //   });
			// 			if(response.data.userType==0){
			// 				this.joinFlag=true
			// 			}else{
			// 				this.$router.replace('/HelloWorld')
			// 			}
      //   })
      //   .catch(error => {
      //     console.log(error)
      //     // 处理请求错误
      //   });
        // let token = 123456
        // localStorage.setItem('token',token)
      //  this.$router.replace({name:'HelloWorld'})
    }
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  height: 100vh;
  background: url("../../assets/bgColor.png") no-repeat center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .logo {
    width: 106px;
    height: 106px;
    border-radius: 12px;
    opacity: 1;
    background: linear-gradient(-41.4deg, #ffffff 0%, #f7fffd 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 73.26px;
      height: 68.59px;
    }
  }
  .title {
    margin-top: 14px;
    .titleLi {
      color: #ffffff;
      font-size: 36px;
      font-weight: 700;
      font-family: "SourceHanSansCN";
      text-align: center;
    }
  }
  .inptBox {
    margin-top: 60px;
    .inptLi {
      .inptTitle {
        color: #ffffff;
        font-size: 16px;
        font-weight: 400;
        font-family: "SourceHanSansCN";
        display: flex;
        align-items: center;
        img {
          width: 16px;
          height: 16px;
          margin-right: 4px;
        }
      }
      .inptText {
        margin-top: 10px;
        width: 311px;
        border-bottom: 1px solid #edf0f0;
        display: flex;
        align-items: center;
        input {
          width: 100%;
          padding: 5px 0;
          color: #ffffff;
          font-size: 14px;
          font-weight: 400;
          font-family: "SourceHanSansCN";
        }

        input::placeholder {
          color: #ffffff; /* 将颜色代码修改为需要的颜色 */
        }
        img {
          width: 14px;
          height: 14px;
        }
      }
    }
    .display {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
      .displayLi {
        color: #ffffff;
        font-size: 14px;
        font-weight: 400;
        font-family: "SourceHanSansCN";
      }
    }
    .btn {
      width: 311px;
      height: 44px;
      border-radius: 24px;
      opacity: 1;
      background: linear-gradient(-41.4deg, #ffbe00 0%, #ffeb3b 100%);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-size: 18px;
      font-weight: 700;
      font-family: "SourceHanSansCN";
      margin-top: 28px;
    }
    .btnType {
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
      font-family: "SourceHanSansCN";
      margin-top: 16px;
      text-align: center;
      width: 100%;
    }
  }
}
.markBg{
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    .markCont{
      width: 320px;
border-radius: 24px;
opacity: 1;
border: 1px solid #979797;
background: #ffffff;
box-shadow: 0 6px 58px 0 #7991ad33;
padding: 34px 0 29px;
box-sizing: border-box;
position: relative;
.el-icon-close{
   color: #0a1629;
 font-size: 20px;
 font-weight: 700;
 font-family: "SourceHanSansCN";
 position: absolute;
 right: 15px;
 top: 15px;
}
      .markTit{
color: #0a1629;
 font-size: 15px;
 font-weight: 500;
 font-family: "SourceHanSansCN";
 text-align: center;
 margin-bottom: 15px;
      }
      .markLi{
        width: 295px;
height: 78px;
border-radius: 14px;
opacity: 1;
background: #f4f9fd;
 margin: 0 auto 20px;
 display: flex;
 align-items: center;
 justify-content: space-between;
 padding: 0 20px;
 box-sizing: border-box;

        .markLeft{
           color: #0a1629;
 font-size: 18px;
 font-weight: 400;
 font-family: "SourceHanSansCN";
  display: flex;
 align-items: center;
          img{
width: 24px;
height: 24px;
margin-right: 16px;
          }
        }
        .el-icon-arrow-right{
font-size: 18px;
        }
      }
    }
  }
</style>

